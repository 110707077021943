
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


import React, { useState, useEffect } from 'react';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';

// import AWS from '../../assets/images/AWS.png';
// import Azure from '../../assets/images/Azure.png';
// import GCP from '../../assets/images/GCP.png';
import { Checkbox } from '../../components/ui/checkbox';
// import { Separator } from '../../components/ui/separator';
import withUser from '../../redux/Hoc/withUser';
import { getarchitectureInfo } from '../../utils/util';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator
} from '../../components/ui/dropdown-menu';
import { DropDownIcon } from '../../assets/icons';
import { useNavigate, useParams } from 'react-router-dom';
import withLoader from '../../redux/Hoc/withLoader';
import { Download } from 'lucide-react';

// import { useParams } from 'react-router-dom';

const cloudTypes = ['AWS', 'GCP', 'AZURE'];



const CloudArchitectureComponent = ({ user,setLoader }) => {
  console.log("user",user)
  const [selectedCloud, setSelectedCloud] = useState('GCP' || "AWS" || "AZURE");
  const [totalPrice, setTotalPrice] = useState(0);
  const [image, setImage] = useState("");
  const [compareMode, setCompareMode] = useState(null);
  const [noOfUsersDropdownOpen, setNoOfUsersDropdownOpen] = useState(false);
  const navigate = useNavigate();
 const [architectureInfoData,setArchitectureInfoData]=useState([])
// const [architectureInfoData, setArchitectureInfoData] = useState([
//   {
//     type: 'AWS',
//     services: [
//       { name: 'EC2', monthlyCost: '$200' },
//       { name: 'S3', monthlyCost: '$50' },
//     ],
//     link: 'aws-architecture-image-url',
//   },
//   {
//     type: 'GCP',
//     services: [
//       { name: 'Compute Engine', monthlyCost: '$100' },
//       { name: 'Cloud Storage', monthlyCost: '$30' },
//     ],
//     link: 'gcp-architecture-image-url',
//   },
  // Uncomment this to test Azure without services
  // {
  //   type: 'AZURE',
  //   services: [],
  //   link: 'azure-architecture-image-url',
  // },
// ]);


  const { id, name } = useParams();
  const [serviceDetails, setServiceDetails] = useState({
    AWS:[] ,
    GCP: [],
    Azure:[] 
  });
  const handleCloudChange = (value) => {
    const [cloud1, cloud2] = value.split(' vs ');
    setCompareMode(value);
    
    navigate(`/architecture/comparison?cloud1=${cloud1}&cloud2=${cloud2}&id=${id}&name=${name}`, {
      state: { cloud1, cloud2,id,name }
    });
  };
  
  const handleUsersDropdown = (isOpen) => {
    setNoOfUsersDropdownOpen(isOpen);
  };
  
  useEffect(() => {
    const fetchData = async () => {

      setLoader(true)

      try {

        const data = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id,
          architecture_id: id,
          architecture_name: name,
          object_id: user.object_id
        };
        const response = await getarchitectureInfo(data);
        setLoader(false)
        console.log("response arch info",response)
        setArchitectureInfoData(response?.data.data.cloud_type);
        
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  
  // Filter and map AWS services dynamically
 
  useEffect(()=>{

  setLoader(true)
    
  

    const AWS1 = architectureInfoData
    ?.filter((curElem) => curElem.type === 'AWS')
    .flatMap((curElem) =>

      curElem.services.map((service) => 
      {
        // the price of data is string like this $15 so i remove the $ and make the remaining no's from str to num
        const price = !Number(service.monthlyCost) ?parseFloat(service.monthlyCost.replace('$', '')):service.monthlyCost;
        return {
        name: service.name,
        price: price
      }
      })
    );

    // Filter and map AWS services dynamically

    const GCP1 = architectureInfoData
    ?.filter((curElem) => curElem.type === 'GCP')
    .flatMap((curElem) =>
      curElem.services.map((service) => 
        {
          // the price of data is string like this $15 so i remove the $ and make the remaining no's from str to num
          // const price = parseFloat(service.monthlyCost.replace('$', ''));
          const price = !Number(service.monthlyCost) ?parseFloat(service.monthlyCost.replace('$', '')):service.monthlyCost;
          return {
          name: service.name,
          price: price
        }
        })
    );

    const Azure1 = architectureInfoData
    ?.filter((curElem) => curElem.type === 'AZURE')
    .flatMap((curElem) =>
      curElem.services.map((service) => 
        {
          // the price of data is string like this $15 so i remove the $ and make the remaining no's from str to num
          // const price = parseFloat(service.monthlyCost.replace('$', ''));
          const price = !Number(service.monthlyCost) ?parseFloat(service.monthlyCost.replace('$', '')):service.monthlyCost;
          return {
          name: service.name,
          price: price
        }
        })
    );
  
  // Log results to verify
  console.log('Service details: ', architectureInfoData);
  console.log('Filtered AWS services: ', AWS1);
  // console.log(Azure1[0].price)

 setServiceDetails({
  AWS: AWS1.length > 0 ? AWS1 : [
    { name: 'S3 + CloudFront', price: 0 },
    { name: 'EKS', price: 73 },
    { name: 'Elastic Load Balancer', price: 17.45 },
    { name: 'EC2 Instance (T3.Xlarge)', price: 133 },
    { name: 'Data Sharing / VPC / Network', price: 30 },
    { name: 'Additional Taxes', price: 60 }
  ],
  GCP: GCP1.length > 0 ? GCP1 : [
    { name: 'Cloud Storage + CDN', price: 0 },
    { name: 'GKE', price: 70 },
    { name: 'Load Balancer', price: 20 },
    { name: 'VM Instance (n1-standard-4)', price: 140 },
    { name: 'Data Sharing / VPC / Network', price: 35 },
    { name: 'Additional Taxes', price: 55 }
  ],
  AZURE: Azure1.length > 0 ? Azure1 :[
    { name: 'Azure Blob Storage', price: 0 },
    { name: 'AKS', price: 80 },
    { name: 'Azure Load Balancer', price: 15 },
    { name: 'VM Instance (D2_v3)', price: 120 },
    { name: 'Data Sharing / VNet / Network', price: 25 },
    { name: 'Additional Taxes', price: 50 }
  ]
})

setLoader(false)

console.log(serviceDetails)

  },[architectureInfoData])

 
  console.log('Filtered AWS services: ', serviceDetails.GCP);
  useEffect(() => {
    setLoader(true)
    const total = serviceDetails[selectedCloud].reduce((sum, service) => sum + Number(service.price), 0);
    setTotalPrice(total);
    setLoader(false)
    console.log("total price",totalPrice)
  }, [selectedCloud,architectureInfoData]);
  
  useEffect(() => {
setLoader(true)
    if (architectureInfoData?.length > 0){
      switch (selectedCloud) {
        case 'AWS':
          // setImage(AWS);
          console.log("link of image",architectureInfoData[0].link)
          setImage(architectureInfoData.find(curElem => curElem.type === 'AWS')?.link || '')
          break;
        case 'AZURE':
          // setImage(GCP);
          setImage(architectureInfoData.find(curElem => curElem.type === 'AZURE')?.link || '')
          break;
        case 'GCP':
          // setImage(Azure);
          setImage(architectureInfoData.find(curElem => curElem.type === 'GCP')?.link || '')
          break;
        default:
          setImage("");
      }
    }
    setInterval(() => {
      setLoader(false)
    }, 1000);

    
  }, [selectedCloud,architectureInfoData]);
  

  const availableCloudTypes = ['AWS', 'GCP', 'AZURE'].filter((cloudType) =>
    architectureInfoData.some((cloud) => cloud.type === cloudType && cloud.services.length > 0)
  );

  useEffect(()=>{
    console.log("architecture    yrytrytrty",architectureInfoData)
    console.log("architectural ",availableCloudTypes)
  },[])

  useEffect(() => {
    setLoader(true)
    if (architectureInfoData.length > 0) {
      // Find the first available cloud type with services
      const firstAvailableCloud = architectureInfoData.find(
        (cloud) => cloud.services.length > 0
      );
      if (firstAvailableCloud) {
        setSelectedCloud(firstAvailableCloud.type);
      }
    }
    setLoader(false)
  }, [architectureInfoData]);
  
// download img in pdf

const handleExportAsPDF = async () => {
  const input = document.getElementById('image-to-pdf'); // Capture the image container

  // Use html2canvas to capture the image with a higher resolution
  const canvas = await html2canvas(input, {
    useCORS: true, // Ensure it captures cross-origin images
    logging: true,
    scale: 2, // Increase the scale for higher resolution
  });

  const imgData = canvas.toDataURL('image/png');

  // Create a PDF document
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
  });

  // Get PDF dimensions
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  // Calculate the dimensions of the image to fit within the PDF
  const imgWidth = canvas.width;
  const imgHeight = canvas.height;

  // Scale the image larger than before
  const scaleFactor = 1.4; // Adjust this to increase or decrease image size
  const finalWidth = Math.min(imgWidth * scaleFactor, pdfWidth);
  const finalHeight = (finalWidth / imgWidth) * imgHeight; // Maintain aspect ratio

  // Center the image in the PDF
  const xOffset = (pdfWidth - finalWidth) / 2;
  const yOffset = (pdfHeight - finalHeight) / 2;

  // Add the image to the PDF
  pdf.addImage(imgData, 'PNG', xOffset, yOffset, finalWidth, finalHeight);

  // Save the PDF
  pdf.save(`${selectedCloud}-architecture.pdf`);
};

  



  return (
    <main className="grid flex-1 w-full items-start gap-4 md:gap-8 ">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
        <div className="bg-[#11141F] text-white p-6 min-h-screen">
          <div className="mx-auto">
            <div className="flex flex-row justify-between mb-5">
              <h2 className="text-lg font-bold">Cloud Type</h2>
              <div className="flex justify-end">
                {availableCloudTypes.length>1 && <DropdownMenu className="col-span-1" onOpenChange={handleUsersDropdown}>
                  <DropdownMenuTrigger className="h-9 w-80 bg-[#2A2E3F] text-white border-[#444444] rounded-lg mt-2">
                    <div className="px-[13px] flex items-center justify-between">
                      {/* Compare Cloud Type */}
                      {compareMode ? compareMode : 'Compare Cloud Types'}{' '}
                      <div
                        className={`transition-transform ${noOfUsersDropdownOpen ? 'rotate-180' : ''}`}>
                        <DropDownIcon />
                      </div>
                    </div>
                  </DropdownMenuTrigger>
                  {/* <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#2A2E3F] mt-4">
                    <DropdownMenuItem
                      onClick={() => handleCloudChange('AWS vs GCP')}
                      className={compareMode === 'AWS vs GCP' ? 'bg-accent' : ''}>
                      <Checkbox id="terms" />
                      <label
                        htmlFor="aws-gcp"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        <div className="w-full text-center text-[16px] font-semibold ml-2">
                          AWS vs GCP
                        </div>
                      </label>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator className="" />
                    <DropdownMenuItem
                      onClick={() => handleCloudChange('GCP vs AZURE')}
                      className={compareMode === 'GCP vs AZURE' ? 'bg-accent' : ''}>
                      <Checkbox id="gcp-azure" />
                      <label
                        htmlFor="terms"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        <div className="w-full text-center text-[16px] font-semibold ml-2">
                          GCP vs AZURE
                        </div>
                      </label>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator className="" />
                    <DropdownMenuItem
                      onClick={() => handleCloudChange('AWS vs AZURE')}
                      className={compareMode === 'AWS vs AZURE' ? 'bg-accent' : ''}>
                      <Checkbox id="aws-azure" />
                      <label
                        htmlFor="terms"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        <div className="w-full text-center text-[16px] font-semibold ml-2">
                          AWS vs AZURE
                        </div>
                      </label>
                    </DropdownMenuItem>
                  </DropdownMenuContent> */}

<DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#2A2E3F] mt-4">
  {availableCloudTypes.includes('AWS') && availableCloudTypes.includes('GCP') && (
    <DropdownMenuItem onClick={() => handleCloudChange('AWS vs GCP')}>
      AWS vs GCP
    </DropdownMenuItem>
  )}
  {availableCloudTypes.includes('GCP') && availableCloudTypes.includes('AZURE') && (
    <DropdownMenuItem onClick={() => handleCloudChange('GCP vs AZURE')}>
      GCP vs AZURE
    </DropdownMenuItem>
  )}
  {availableCloudTypes.includes('AWS') && availableCloudTypes.includes('AZURE') && (
    <DropdownMenuItem onClick={() => handleCloudChange('AWS vs AZURE')}>
      AWS vs AZURE
    </DropdownMenuItem>
  )}
</DropdownMenuContent>


                </DropdownMenu>}
              </div>
            </div>

            {/* <div className="flex space-x-4 mb-6">
              {cloudTypes.map((cloudType) => (
                <Button
                  key={cloudType}
                  onClick={() => setSelectedCloud(cloudType)}
                  className={`w-[90%] h-9  px-5 py-1 rounded-lg border-[#747474] border-2 ${
                    selectedCloud === cloudType
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-800 text-gray-300'
                  }`}>
                  {cloudType}
                </Button>
              ))}
            </div> */}

<div className="flex space-x-4 mb-6">
  {availableCloudTypes.map((cloudType) => (
    <Button
      key={cloudType}
      onClick={() => setSelectedCloud(cloudType)}
      className={`w-full h-9 px-5 py-1 rounded-lg border-[#747474] border-2 ${
        selectedCloud === cloudType ? 'bg-purple-600 text-white' : 'bg-gray-800 text-gray-300'
      }`}>
      {cloudType}
    </Button>
  ))}
</div>


            <div className="grid grid-cols-1 gap-2">

            {/* Export button on the image  */}
            <div className="grid grid-cols-1 gap-2 relative">
  <div>
    <Card className="w-full h-80 bg-white p-6 flex justify-center">
      <div  className="h-72 flex items-center justify-center w-[90%] relative z-0">
        <img 
        id="image-to-pdf"
          src={image}
          alt={`${selectedCloud} Architecture`}
          className="max-w-full max-h-full object-contain"
          crossOrigin="anonymous"
          onLoad={() => console.log('Image loaded')}
        />
      </div>
    </Card>
  </div>
  {/* Added relative positioning to the parent and adjusted the icon's position */}
  <div className="absolute top-2 right-2 m-2 z-10">
    <Download size={30} className="text-black cursor-pointer" onClick={handleExportAsPDF} />
  </div>
</div>




              <div className="grid grid-cols-[auto,1fr] items-center gap-4 text-white py-2 px-0">
                <h3 className="text-xl font-semibold whitespace-nowrap ">Overall Bill Summary</h3>
                {/* <Separator className="w-full" /> */}
                <hr className="w-full border-[#747474] border-dashed" />
              </div>
              <div>
                <p className="text-lg font-bold mb-4">
                  Grand Total: <span className="text-3xl">${totalPrice.toFixed(2)}</span>
                </p>
                <Card className="bg-[#161A27] py-3 border-2 border-[#002C74]">
                  <h4 className="text-xl font-semibold mb-4 px-6">Services</h4>
                  <table className="w-full">
                    <tbody>
                      {serviceDetails[selectedCloud].map((service, index) => (
                        <tr
                          key={index}
                          className={`border-t border-[#747474] ${index === -1 ? 'border-t-0' : ''}`}>
                          <td className="py-1.5 text-sm px-6">{service.name}</td>
                          <td className="py-1.5 text-sm text-right px-6">
                            {service.price === 0 ? 'Free' : `$${Number(service.price).toFixed(2)}`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>

            {/* <div className="mt-8 flex justify-end">
              <Button className="bg-purple-600 text-white px-8 py-2">Okay, Understood</Button>
            </div> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default withLoader(withUser(CloudArchitectureComponent));




// import React, { useState, useEffect } from 'react';
// import { Button } from '../../components/ui/button';
// import { Card } from '../../components/ui/card';

// // import AWS from '../../assets/images/AWS.png';
// // import Azure from '../../assets/images/Azure.png';
// // import GCP from '../../assets/images/GCP.png';
// import { Checkbox } from '../../components/ui/checkbox';
// // import { Separator } from '../../components/ui/separator';
// import withUser from '../../redux/Hoc/withUser';
// import { getarchitectureInfo } from '../../utils/util';
// import {
//   DropdownMenu,
//   DropdownMenuTrigger,
//   DropdownMenuContent,
//   DropdownMenuItem,
//   DropdownMenuSeparator
// } from '../../components/ui/dropdown-menu';
// import { DropDownIcon } from '../../assets/icons';
// import { useNavigate, useParams } from 'react-router-dom';
// import withLoader from '../../redux/Hoc/withLoader';

// // import { useParams } from 'react-router-dom';

// const cloudTypes = ['AWS', 'GCP', 'AZURE'];
// const CloudArchitectureComponent = ({ user, setLoader }) => {
//   const [selectedCloud, setSelectedCloud] = useState('AWS');
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [image, setImage] = useState('');
//   const [compareMode, setCompareMode] = useState(null);
//   const [noOfUsersDropdownOpen, setNoOfUsersDropdownOpen] = useState(false);
//   // const [architectureInfoData, setArchitectureInfoData] = useState([]);

//   const [architectureInfoData, setArchitectureInfoData] = useState([
//     {
//       type: 'AWS',
//       services: [
//         { name: 'EC2', monthlyCost: '$200' },
//         { name: 'S3', monthlyCost: '$50' },
//       ],
//       link: 'aws-architecture-image-url',
//     },
//     {
//       type: 'GCP',
//       services: [
//         { name: 'Compute Engine', monthlyCost: '$100' },
//         { name: 'Cloud Storage', monthlyCost: '$30' },
//       ],
//       link: 'gcp-architecture-image-url',
//     },
//     // Uncomment to test Azure being empty
//     // {
//     //   type: 'AZURE',
//     //   services: [],
//     //   link: 'azure-architecture-image-url',
//     // },
//   ]);

//   const { id, name } = useParams();
//   const [serviceDetails, setServiceDetails] = useState({
//     AWS: [],
//     GCP: [],
//     Azure: []
//   });
  
//   const navigate = useNavigate();

//   // Dynamically determine which cloud types have data
//   const availableCloudTypes = cloudTypes.filter((cloudType) =>
//     architectureInfoData.some((cloud) => cloud.type === cloudType)
//   );

//   const handleCloudChange = (value) => {
//     const [cloud1, cloud2] = value.split(' vs ');
//     setCompareMode(value);
//     navigate(`/architecture/comparison?cloud1=${cloud1}&cloud2=${cloud2}&id=${id}&name=${name}`, {
//       state: { cloud1, cloud2, id, name },
//     });
//   };

//   const handleUsersDropdown = (isOpen) => {
//     setNoOfUsersDropdownOpen(isOpen);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoader(true);
//       try {
//         const data = {
//           user_id: user.user_id,
//           enterprise_id: user.enterprise_id,
//           architecture_id: id,
//           architecture_name: name,
//           object_id: user.object_id,
//         };
//         const response = await getarchitectureInfo(data);
//         setLoader(false);
//         setArchitectureInfoData(response?.data.data.cloud_type);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const AWS1 = architectureInfoData
//       ?.filter((curElem) => curElem.type === 'AWS')
//       .flatMap((curElem) =>
//         curElem.services.map((service) => {
//           const price = !Number(service.monthlyCost)
//             ? parseFloat(service.monthlyCost.replace('$', ''))
//             : service.monthlyCost;
//           return {
//             name: service.name,
//             price: price,
//           };
//         })
//       );

//     const GCP1 = architectureInfoData
//       ?.filter((curElem) => curElem.type === 'GCP')
//       .flatMap((curElem) =>
//         curElem.services.map((service) => {
//           const price = !Number(service.monthlyCost)
//             ? parseFloat(service.monthlyCost.replace('$', ''))
//             : service.monthlyCost;
//           return {
//             name: service.name,
//             price: price,
//           };
//         })
//       );

//     const Azure1 = architectureInfoData
//       ?.filter((curElem) => curElem.type === 'AZURE')
//       .flatMap((curElem) =>
//         curElem.services.map((service) => {
//           const price = !Number(service.monthlyCost)
//             ? parseFloat(service.monthlyCost.replace('$', ''))
//             : service.monthlyCost;
//           return {
//             name: service.name,
//             price: price,
//           };
//         })
//       );

//       // setServiceDetails({
//       //   AWS: AWS1.length > 0 ? AWS1 : [
//       //     { name: 'S3 + CloudFront', price: 0 },
//       //     { name: 'EKS', price: 73 },
//       //     { name: 'Elastic Load Balancer', price: 17.45 },
//       //     { name: 'EC2 Instance (T3.Xlarge)', price: 133 },
//       //     { name: 'Data Sharing / VPC / Network', price: 30 },
//       //     { name: 'Additional Taxes', price: 60 }
//       //   ],
//       //   GCP: GCP1.length > 0 ? GCP1 : [
//       //     { name: 'Cloud Storage + CDN', price: 0 },
//       //     { name: 'GKE', price: 70 },
//       //     { name: 'Load Balancer', price: 20 },
//       //     { name: 'VM Instance (n1-standard-4)', price: 140 },
//       //     { name: 'Data Sharing / VPC / Network', price: 35 },
//       //     { name: 'Additional Taxes', price: 55 }
//       //   ],
//       //   AZURE: Azure1.length > 0 ? Azure1 :[
//       //     { name: 'Azure Blob Storage', price: 0 },
//       //     { name: 'AKS', price: 80 },
//       //     { name: 'Azure Load Balancer', price: 15 },
//       //     { name: 'VM Instance (D2_v3)', price: 120 },
//       //     { name: 'Data Sharing / VNet / Network', price: 25 },
//       //     { name: 'Additional Taxes', price: 50 }
//       //   ]
//       // })
      

//     setServiceDetails({
//       AWS: AWS1.length > 0 ? AWS1 : [],
//       GCP: GCP1.length > 0 ? GCP1 : [],
//       AZURE: Azure1.length > 0 ? Azure1 : [],
//     });
//     setLoader(false);
//   }, [architectureInfoData]);

  

//   useEffect(() => {
//     setLoader(true);
//     const total = serviceDetails[selectedCloud].reduce((sum, service) => sum + Number(service.price), 0);
//     setTotalPrice(total);
//     setLoader(false);
//   }, [selectedCloud, architectureInfoData]);

//   useEffect(() => {
//     if (architectureInfoData?.length > 0) {
//       const cloud = architectureInfoData.find((curElem) => curElem.type === selectedCloud);
//       setImage(cloud?.link || '');
//     }
//   }, [selectedCloud, architectureInfoData]);

//   return (
//     <main className="grid flex-1 w-full items-start gap-4 md:gap-8 ">
//       <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
//         <div className="bg-[#11141F] text-white p-6 min-h-screen">
//           <div className="mx-auto">
//             <div className="flex flex-row justify-between mb-5">
//               <h2 className="text-lg font-bold">Cloud Type</h2>
//               <div className="flex justify-end">
//                 <DropdownMenu className="col-span-1" onOpenChange={handleUsersDropdown}>
//                   <DropdownMenuTrigger className="h-9 w-80 bg-[#2A2E3F] text-white border-[#444444] rounded-lg mt-2">
//                     <div className="px-[13px] flex items-center justify-between">
//                       {compareMode ? compareMode : 'Compare Cloud Types'}{' '}
//                       <div
//                         className={`transition-transform ${noOfUsersDropdownOpen ? 'rotate-180' : ''}`}>
//                         <DropDownIcon />
//                       </div>
//                     </div>
//                   </DropdownMenuTrigger>
//                   <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#2A2E3F] mt-4">
//                     {availableCloudTypes.includes('AWS') && (
//                       <DropdownMenuItem onClick={() => handleCloudChange('AWS vs GCP')}>
//                         AWS vs GCP
//                       </DropdownMenuItem>
//                     )}
//                     {availableCloudTypes.includes('GCP') && (
//                       <DropdownMenuItem onClick={() => handleCloudChange('GCP vs AZURE')}>
//                         GCP vs AZURE
//                       </DropdownMenuItem>
//                     )}
//                     {availableCloudTypes.includes('AZURE') && (
//                       <DropdownMenuItem onClick={() => handleCloudChange('AWS vs AZURE')}>
//                         AWS vs AZURE
//                       </DropdownMenuItem>
//                     )}
//                   </DropdownMenuContent>
//                 </DropdownMenu>
//               </div>
//             </div>

//             <div className="flex space-x-4 mb-6">
//               {availableCloudTypes.map((cloudType) => (
//                 <Button
//                   key={cloudType}
//                   onClick={() => setSelectedCloud(cloudType)}
//                   className={`w-[90%] h-9  px-5 py-1 rounded-lg border-[#747474] border-2 ${
//                     selectedCloud === cloudType
//                       ? 'bg-purple-600 text-white'
//                       : 'bg-gray-800 text-gray-300'
//                   }`}>
//                   {cloudType}
//                 </Button>
//               ))}
//             </div>

//             {/* The rest of your component remains the same */}
//           </div>
//         </div>
//       </div>
//     </main>
//   );
// };

// export default withLoader(withUser(CloudArchitectureComponent));


