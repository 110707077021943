import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const deepClone = (object = {}) => {
  let newObj = {}
  try {
    newObj = typeof object === 'object' ? JSON.parse(JSON.stringify(object)) : newObj
  } catch (error) {
    console.log("Error while deep cloning the object: ", error)
  }
  return newObj
}

export const isValidJson = (str) => {
  try {
      JSON.parse(str);
      return true;
  } catch (e) {
      return false;
  }
}

export const parseJSON = (str) => {
  if (isValidJson(str)) {
    return JSON.parse(str)
  } else return str
}

export const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    // case 'started':
    //   return 'border-[#9747FF] bg-[#9747FF] text-[#9747FF] bg-opacity-25';
    case 'started':
      return 'border-[#FEB101] bg-[#FEB101] text-[#FEB101] bg-opacity-25';
    case 'success':
    case 'passed':
    case 'completed':
      return 'border-[#22C55E] bg-[#22C55E] text-[#22C55E] bg-opacity-25';
    case 'not started':
      return 'border-[#A9A9A9] bg-[#A9A9A9] text-[#A9A9A9] bg-opacity-25';
    case 'paused':
    case 'pending':
    case 'in progress':
      return 'border-[#FEB101] bg-[#FEB101] text-[#FEB101] bg-opacity-25';
    case 'failed':
      return 'border-[#EF4444] bg-[#EF4444] text-[#EF4444] bg-opacity-25';
    default:
      return 'border-[#A9A9A9] bg-[#A9A9A9] text-[#A9A9A9] bg-opacity-25';
  }
};

export const getStatusColorForDot = (status) => {
  switch (status?.toLowerCase()) {
    // case 'started':
    //   return 'bg-[#9747FF]';
    case 'started':
      return 'bg-[#FEB101]';
    case 'success':
    case 'passed':
    case 'completed':
      return 'bg-[#22C55E]';
    case 'not started':
      return 'bg-[#A9A9A9]';
    case 'paused':
    case 'pending':
    case 'in progress':
      return 'bg-[#FEB101]';
    case 'failed':
      return 'bg-[#EF4444]';
    default:
      return 'bg-[#A9A9A9]';
  }
};

export const setDataToLocalStorage = (keyName, value) => {
  localStorage.setItem(keyName, value)
}

export const getDataFromLocalStorage = (keyName) => {
  return localStorage.getItem(keyName)
}

export const getParamStringFromURL = (baseUrl, url) => {
  return url.substring(baseUrl.length);
}

export const unFlattenJSON = (flattened) => {
  const result = {};

  for (const key in flattened) {
      const value = flattened[key];
      const keys = key.split('.').slice(1); // Remove initial "$" part

      let current = result;

      for (let i = 0; i < keys.length; i++) {
          const part = keys[i];

          // If this is the last part, assign the value
          if (i === keys.length - 1) {
              current[part] = value;
          } else {
              // If the key doesn't exist, create an empty object
              if (!current[part]) {
                  current[part] = {};
              }
              current = current[part];
          }
      }
  }

  return result;
}