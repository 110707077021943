import React from 'react';
import { Spinner } from 'react-activity';

const ComponentLoader = (props) => {
 
  const { message, className } = props
  const loadingMessage = message || "Loading..."
  const classes = className ? className : ""

  return (
    <div className={`flex items-center justify-center ${classes}`}>
      <Spinner size={20} color="white" />
      <p className="ml-2">{ loadingMessage }</p>
    </div>
  )
}

export default ComponentLoader;
