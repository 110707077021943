import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Svg, Line,Link } from '@react-pdf/renderer';
import rightImage from '../../assets/images/PdfImage1.png';
import topImage from '../../assets/images/PdfImage2.png';
import leftImage from '../../assets/images/PdfImage3.png';
import convertPToText from '../../utils/convertHtmlToReactPdfElements';
import RiskImage from '../../utils/RiskImage';

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 30,
    display: 'flex',
    color: '#333333',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '90%',
    marginTop: 80,
    marginLeft: 20,
  },
  header: {
    fontSize: 16,
    color: 'grey',
    textAlign: 'left',
    marginTop: 200,
  },
  title: {
    fontSize: 36,
  },
  report: {
    fontSize: 36,
    color: '#9747FF',
  },
  subtitle: {
    fontSize: 24,
  },
  preparedBy: {
    fontSize: 16,
    color: 'grey',
    marginTop: 50,
  },
  footer: {
    fontSize: 16,
    marginTop: 350,
  },
  overviewPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  overviewHeader: {
    fontSize: 18,
    textAlign: 'right',
    marginBottom: 10,
  },
  overviewTitle: {
    marginTop: 30,
    marginLeft: 20,
    fontSize: 30,
  },
  overviewList: {
    marginTop: 30,
    marginLeft: 30,
    fontSize: 16,
    lineHeight: 1.5,
    width: '350px',
  },
  overviewListItem: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  overviewFooterImage: {
    width: 50,
    height: 50,
    alignSelf: 'flex-end',
  },
  footerText: {
    fontSize: 12,
  },
  split: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  rightImage: {
    width: '600px',
    height: '600px',
    marginLeft: -150,
    marginBottom: -150,
  },
  rightContainer: {
    marginBottom: 600,
    marginTop: -30,
    marginLeft: -150,
    height: '100%',
  },
  overviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  topImage: {
    top: 0,
    position: 'fixed',
    height: '50px',
    width: '900px',
    marginLeft: -30,
    marginTop: -30,
  },
  leftImage: {
    left: 0,
    width: '100%',
    marginLeft: -100,
  },
  bottomImage: {
    width: '900px',
    height: '50px',
    marginLeft: -30,
    marginBottom: -30,
  },
  page3: {
    backgroundColor: '#ffffff',
    padding: 30,
    display: 'flex',
    color: '#333333',
  },

    listContainer: {
      backgroundColor: '#FFFFFF',
      padding: 20,
      borderRadius: 10,
      marginBottom: 20,
      borderWidth: 1,          // Set the border width
      borderColor: '#000000', // Set the border color
      borderStyle: 'solid',   // Set the border style (solid, dashed, etc.)
    },
  
  
  listItem: {
    marginBottom: 10,
  },
  listItemContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    borderBottomStyle: 'solid', // Explicitly setting the border style
  }
  ,
  listItemText: {
    fontSize: 12,
    color: '#000000',
  },
  listItemLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000000',
  },
});

const LoadResultPdf = ({ data, type }) => {
  // Mock data for demonstration
  const metrics = [
    {
      metric_name: 'Metric 1',
      otherDetails: [
        ['detail_1', 'Value 1'],
        ['detail_2', 'Value 2'],
      ],
    },
    {
      metric_name: 'Metric 2',
      otherDetails: [
        ['detail_3', 'Value 3'],
        ['detail_4', 'Value 4'],
      ],
    },
  ];

  const itemsPerPage = 2

  const result = data?.result || [];

  const pages = [];
  for (let i = 0; i < result.length; i += itemsPerPage) {
    pages.push(result.slice(i, i + itemsPerPage));
  }



  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.split}>
          <View style={styles.container}>
            <View>
              <Text style={styles.header} fontWeight={'bold'}>
               
              </Text>
              <Text style={styles.title}>Load Test </Text>
              <Text style={styles.report}>Report</Text>
            </View>
            <View>
            {/* Add the link here */}
            <Link src="https://scalesecure.io" style={styles.footer}>
              <Text>scalesecure.io</Text>
            </Link>
          </View>
          </View>
          <View style={styles.rightContainer}>
            <Image style={styles.rightImage} src={rightImage} />
            {/* <Text style={styles.report}>{data.environment}</Text> */}
          </View>
        </View>
      </Page>

      {/* Page 2 with list design */}

      <Page size="A4" style={styles.page}>


      <View  style={styles.listContainer}>
            <Text style={{ fontSize: 16, color: '#000000', marginBottom: 10 }}>
              API Details
            </Text>
            <View>
              {data.test_name && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Test Name</Text>
                  <Text style={styles.listItemText}>{data.test_name}</Text>
                </View>
              )}
              {data.number_of_virtual_users && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Number Of Virtual :</Text>
                  <Text style={styles.listItemText}>{data.number_of_virtual_users}</Text>
                </View>
              )}
              {data.created_at && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Created At :</Text>
                  <Text style={styles.listItemText}>{new Date(data.created_at).toLocaleString()}</Text>
                </View>
              )}
              {data.duration && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Duration:</Text>
                  <Text style={styles.listItemText}>{data.duration}</Text>
                </View>
              )}
              {data.status && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Status:</Text>
                  <Text style={styles.listItemText}>{data.status}</Text>
                </View>
              )}
              {data.rest_api_url && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>API URL:</Text>
                  <Text style={styles.listItemText}>{data.rest_api_url}</Text>
                </View>
              )}
              {data.method && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Method:</Text>
                  <Text style={styles.listItemText}>{data.method}</Text>
                </View>
              )}
              {data.database_name && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Database Name:</Text>
                  <Text style={styles.listItemText}>{data.database_name}</Text>
                </View>
              )}
              {data.measurement && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Measurement:</Text>
                  <Text style={styles.listItemText}>{data.measurement}</Text>
                </View>
              )}
            </View>
          </View>

        {/* <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableHeader]}>Test Name</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Number of Virtual Users</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Run At</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableCellLast]}>{data.test_name}</Text>
            <Text style={[styles.tableCell, styles.tableCellLast]}>{data.number_of_virtual_users}</Text>
            <Text style={[styles.tableCell, styles.tableCellLast]}>
              {new Date(data.created_at).toLocaleString()}
            </Text>
          </View>
        </View> */}
      </Page>

      
      

      {/* Page 3 with previous table */}

      {/* <Page size="A4" style={styles.page}>
        {data.result.map((each, index) => (
          <View key={index} style={styles.listContainer}>
            <Text style={{ fontSize: 16, color: '#000000', marginBottom: 10 }}>
              {each.metric_name}
            </Text>
            <View>
              {each.count && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Count:</Text>
                  <Text style={styles.listItemText}>{each.count}</Text>
                </View>
              )}
              {each.passed && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Passed:</Text>
                  <Text style={styles.listItemText}>{each.passed}</Text>
                </View>
              )}
              {each.failed && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Failed:</Text>
                  <Text style={styles.listItemText}>{each.failed}</Text>
                </View>
              )}
              {each.percentage && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Percentage:</Text>
                  <Text style={styles.listItemText}>{each.percentage}</Text>
                </View>
              )}
              {each.min && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Min:</Text>
                  <Text style={styles.listItemText}>{each.min}</Text>
                </View>
              )}
              {each.max && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Max:</Text>
                  <Text style={styles.listItemText}>{each.max}</Text>
                </View>
              )}
              {each.avg && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Avg:</Text>
                  <Text style={styles.listItemText}>{each.avg}</Text>
                </View>
              )}
              {each.total && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Total:</Text>
                  <Text style={styles.listItemText}>{each.total}</Text>
                </View>
              )}
              {each.measurement && (
                <View style={styles.listItemContent}>
                  <Text style={styles.listItemLabel}>Measurement:</Text>
                  <Text style={styles.listItemText}>{each.measurement}</Text>
                </View>
              )}
            </View>
          </View>
        ))}
      </Page> */}
     

     {pages.map((pageData, pageIndex) => (
        <Page key={pageIndex} size="A4" style={styles.page}>
          {pageData.map((each, index) => (
            <View key={index} style={styles.listContainer}>
              <Text style={{ fontSize: 16, color: '#000000', marginBottom: 10 }}>
                {each.metric_name}
              </Text>
              <View>
                {each.count && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Count:</Text>
                    <Text style={styles.listItemText}>{each.count}</Text>
                  </View>
                )}
                {each.passed && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Passed:</Text>
                    <Text style={styles.listItemText}>{each.passed}</Text>
                  </View>
                )}
                {each.failed && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Failed:</Text>
                    <Text style={styles.listItemText}>{each.failed}</Text>
                  </View>
                )}
                {each.percentage && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Percentage:</Text>
                    <Text style={styles.listItemText}>{each.percentage}</Text>
                  </View>
                )}
                {each.min && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Min:</Text>
                    <Text style={styles.listItemText}>{each.min}</Text>
                  </View>
                )}
                {each.max && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Max:</Text>
                    <Text style={styles.listItemText}>{each.max}</Text>
                  </View>
                )}
                {each.avg && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Avg:</Text>
                    <Text style={styles.listItemText}>{each.avg}</Text>
                  </View>
                )}
                {each.total && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Total:</Text>
                    <Text style={styles.listItemText}>{each.total}</Text>
                  </View>
                )}
                {each.measurement && (
                  <View style={styles.listItemContent}>
                    <Text style={styles.listItemLabel}>Measurement:</Text>
                    <Text style={styles.listItemText}>{each.measurement}</Text>
                  </View>
                )}
              </View>
            </View>
          ))}
        </Page>
      ))}

    </Document>
  );
};

export default LoadResultPdf;
