// Library imports
import * as React from 'react';

// Util imports
import { cn } from '../../utils/helper';

const TextArea = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        'flex h-9 w-full rounded-md border border-[#747474] bg-[#161A27] px-3 py-1 text-sm shadow-sm file:border-0 file:text-sm file:font-medium placeholder:text-[#A9A9A9] focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      {...props}
    >
    </textarea>
  );
});
TextArea.displayName = 'TextArea';

export { TextArea };
