// Library imports
import arrayMutators from "final-form-arrays";
import { Download } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router-dom";

// Component imports
import BackButton from "../../components/BackButton";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { Checkbox } from "../../components/form/Checkbox";
import { Label } from "../../components/form/Label";
import Loader from "../../components/Loader";
import SettingsDropdown from "../../components/Settings";
import { useToast } from "../../components/UseToast";
import Headers from "./Headers";
import TestCase from "./TestCase";

// Util imports
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { renderField } from "../../utils/formValidations";
import { deepClone, getDataFromLocalStorage, getParamStringFromURL, unFlattenJSON } from "../../utils/helper";
import { JSONBuilder } from "../../utils/jsonBuilder";
// Constants imports
import { API_TYPES, AUTH_TYPES, HTTP_METHODS } from "../../utils/constants";

// API imports
import { runTests, saveAsyncTest, getTestById } from "../../api/app";

const TestForm = () => {
	// React Hooks
	const { toast } = useToast();
	let { type, testId } = useParams();

	useEffect(() => {
		setAPIType(type)
		if (testId) {
			loadAPIData(testId)
		}
	}, []);

	const LOAD_TEST_AUTH_TYPE = AUTH_TYPES;

	const ALGORITHM_OPTIONS = [
		{ value: "md5", label: "MD5" },
		{ value: "sha256", label: "SHA256" },
		{ value: "sha756", label: "SHA756" },
	];

	const defaultHeaderObj = {
		key: "",
		value: "",
	};

	const defaultTestObj = {
		name: "",
		requestBody: "",
		responseBody: "",
		isBodyDynamic: false,
		showParamsField: false,
		paramsString: "",
	};

	// Component States
	const [triggerInMillSeconds, setTrigger] = useState(5);
	const [authType, setAuthType] = useState("");
	const [backButtonRouteName] = useState("/");
	const [apiType, setAPIType] = useState(API_TYPES.SYNC);
	// State to hold the response from API
	const [apiResponse, setApiResponse] = useState("");
	const [resultData, setResultData] = useState([]);
	const [asyncResultData, setAsyncResultData] = useState([]);
	const [defaultFormData] = useState({
		name: "",
		url: "",
		headerValues: [{ ...defaultHeaderObj }],
		httpMethod: "",
		authType: "",
		tests: [{ ...defaultTestObj }],
		asyncAPIName: "",
		asyncAPIHttpMethod: "get",
		asyncAPIEndPointURL: "",
		asyncAPITests: [{ ...defaultTestObj }],
	})
	const [formData, setFormData] = useState(deepClone(defaultFormData));
	const [loadingMessage, setLoadingMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	// Methods
	const required = (value) => (value ? undefined : "Required");

	const composeValidators =
		(...validators) =>
		(value) =>
			validators.reduce((error, validator) => error || validator(value), undefined);

	const handleMethodChange = (newMethod) => {
		setFormData((prevState) => ({
			...prevState,
			httpMethod: newMethod,
		}));
	};

	const loadAPIData = async (testId) => {
		setLoadingMessage("Loading test data...");
		setIsLoading(true);
		try {
			const response = await getTestById(testId)
			let json = deepClone(defaultFormData)
			if (response.data.data?.sync) {
				json.name = response.data.data.sync?.workflow.name
				json.url = response.data.data.sync?.workflow.env.host
				json.httpMethod = response.data.data.sync?.workflow.env.method

				// Bind the headers if present
				const headers = response.data.data.sync?.workflow.tests?.Test1?.steps?.[0]?.http?.headers
				if (headers) {
					const result = Object.entries(headers).map(([key, value]) => {

							if (key === "Authorization" && value) {
								// Check for Bearer Auth
								if (value.includes("Bearer ")) {
									json.authType = "bearerToken"
									setAuthType("bearerToken")
									// reverse the array after splitting to avoid optional chaining
									json.bearerToken = value.split("Bearer ").reverse()[0] // get the token value
								}

								// Check for Basic Auth
								if (value.includes("Basic ")) {
									json.authType = "basicAuth"
									setAuthType("basicAuth")
									// reverse the array after splitting to avoid optional chaining
									const token = value.split("Basic ").reverse()[0] // get the token value
									if (token) {
										// Since it is a basic token, we need to decrypt it
										const decryptedData = atob(token).split(":")
										json.username = decryptedData?.[0]
										json.password = decryptedData?.[1]
									}
								}
							} else {
								return { key, value }
							}
					})
					json.headerValues = result.filter(obj => obj)
				}
				setResultData(response.data.data.sync?.result?.tests);
				mapFormData(json, response.data.data.sync, API_TYPES.SYNC.toLowerCase())
			}
			if (response.data.data?.async) {
				json.asyncAPIName = response.data.data?.async?.workflow.name
				json.asyncAPIEndPointURL = response.data.data?.async?.workflow.env.host
				json.asyncAPIHttpMethod = response.data.data?.async?.workflow.env.method
				setAsyncResultData(response.data.data.async?.result?.tests);
				mapFormData(json, response.data.data.async, API_TYPES.ASYNC.toLowerCase())
			}
			setFormData(json)
		} catch (error) {
			console.log("Error while fetching api detail for api: ", error)
		} finally {
			resetLoaders()
		}
	}

	const mapFormData = (json, data, type) => {
		const testKeyName = type.toUpperCase() === API_TYPES.SYNC ? "tests" : "asyncAPITests"
		json[testKeyName] = []
		for (const [key, value] of Object.entries(data?.workflow?.tests)) {
			const testData = value?.steps[0]
			const paramStr = getParamStringFromURL(data.workflow.env.host, testData?.http?.url)
			json[testKeyName].push({
				name: testData?.name,
				requestBody: testData?.http?.json ? JSON.stringify(testData?.http?.json, null, 2) : "",
				responseBody: testData?.http?.check?.jsonpath ? JSON.stringify(unFlattenJSON(testData?.http?.check?.jsonpath), null, 2) : "",
				isBodyDynamic: false,
				showParamsField: paramStr ? true : false,
				paramsString: paramStr,
			})
		}
		return json
	}

	const handleAsyncMethodChange = (newMethod) => {
		setFormData((prevState) => ({
			...prevState,
			asyncAPIHttpMethod: newMethod,
		}));
	};

	const handleKeyValueBlurOrChange = (fields, index, values) => {
		const currentPair = values.headerValues[index];
		if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
			fields.push({ ...defaultHeaderObj });
		}
	};

	const onSubmit = async (formData) => {
		const payloadBuilder = new JSONBuilder(formData);
		let payload = payloadBuilder.createPayload();
		let apiId = ""
		try {
			payload = {
				id: "",
				sync: payload,
			};
			setLoadingMessage("Running Tests...");
			setIsLoading(true);
			const response = await runTests(payload);
			setApiResponse(JSON.stringify(response.data?.data, null, 2));
			setResultData(response.data?.data?.result?.tests);
			apiId = response.data.id
		} catch (error) {
			console.log("Error: ", error);
			setApiResponse(`Error: ${error.message}`);
		} finally {
			resetLoaders();
			if (apiType === API_TYPES.ASYNC) {
				initAsyncAPICall(apiId, formData);
			}
		}
	};

	const resetLoaders = () => {
		setLoadingMessage("");
		setIsLoading(false);
	};

	const initAsyncAPICall = async (apiId, formData) => {
		let asyncTimer = 5;
		// let timeInSeconds = asyncTimer / 1000
		const timer = getDataFromLocalStorage(LOCAL_STORAGE_KEYS.ASYNC_TIMER_INFO);

		if (timer) {
			const time = JSON.parse(timer);
			asyncTimer = Number(time.timer);
			if (isNaN(asyncTimer)) asyncTimer = 5
			setTrigger(asyncTimer);
			// timeInSeconds = asyncTimer / 1000
		}

		const asyncFormData = {
			name: formData.asyncAPIName,
			url: formData.asyncAPIEndPointURL,
			headerValues: formData.headerValues,
			httpMethod: formData.asyncAPIHttpMethod,
			authType: "",
			tests: formData.asyncAPITests,
		}
		const payloadBuilder = new JSONBuilder(asyncFormData);
		let payload = payloadBuilder.createPayload();

		payload = {
			id: apiId,
			async: payload,
		};

		try {
			const asyncResponse = await saveAsyncTest(apiId, payload)
		} catch (error) {
			console.log("Error: ", error);
			setApiResponse(`Error: ${error.message}`);
		}

		toast({
			variant: "success",
			title: "Async API",
			description: `Async API will trigger in ${asyncTimer} seconds`,
		});
		setTimeout(async () => {
			try {
				const response = await runTests(payload);
				setAsyncResultData(response?.data?.data?.result?.tests);
			} catch (error) {
				console.log("Error: ", error);
				toast({
					id: 1,
					variant: "destructive",
					title: "Async API",
					description: `Async API failed`,
				});
			}
		}, asyncTimer * 1000);
	};

	const addMoreTest = (fields, values) => {
		fields.push(deepClone(defaultTestObj));
	};

	const addMoreAsyncTest = (fields, values) => {
		fields.push(deepClone(defaultTestObj));
	};

	// UI Rendering
	return (
		<main className="gap-4 p-4 mt-8 sm:py-0 md:gap-8 w-full">
			{isLoading && <Loader loadingMessage={loadingMessage} />}
			<div className="mb-4 flex justify-between">
				<BackButton routeName={backButtonRouteName} />
				<div className="flex justify-end items-center">
					{/* <Button
            onClick={() => {}}
            size="lg"
            variant="default"
            className="h-8 gap-1 text-sm"
          >
            <Save className="h-3.5 w-3.5" stroke="white" />
            <span className="sr-only sm:not-sr-only text-white">Save Test</span>
          </Button> */}
					{apiType === API_TYPES.ASYNC && <SettingsDropdown />}
					<Button onClick={() => {}} size="lg" variant="green" className="h-8 gap-1 text-sm ml-2">
						<Download className="h-3.5 w-3.5" stroke="white" />
						<span className="sr-only sm:not-sr-only text-white">Report</span>
					</Button>
				</div>
			</div>
			<hr className="mb-4" />
			<div className="">
				<Form
					onSubmit={onSubmit}
					initialValues={formData}
					keepDirtyOnReinitialize
					mutators={{
						...arrayMutators,
					}}
					render={({ handleSubmit, values }) => (
						<React.Fragment>
							<form onSubmit={handleSubmit}>
								<div className="grid grid-cols-12 gap-4 mb-4">
									<div className="col-span-3 font-semibold">
										<Label htmlFor="name" className={`mb-2 text-sm block text-left`}>
											API Name *
										</Label>
										<Field
											className="bg-[#161A27]"
											id="name"
											type="text"
											name="name"
											placeholder="Enter api name like “Load Test 1”, etc. "
											validate={composeValidators(required)}>
											{renderField}
										</Field>
									</div>
									<div className="col-span-2 font-semibold">
										<Label htmlFor="httpMethod" className={`mb-2 text-sm block text-left`}>
											HTTP Method *
										</Label>
										<Field
											name="httpMethod"
											component="select"
											onChange={(event) => handleMethodChange(event.target.value)}
											className="bg-[#161A27] w-full block p-1 rounded-md border border-gray-600 h-9">
											<option value="">Select Method</option>
											{HTTP_METHODS.map((methodObj) => (
												<option key={methodObj.method} value={methodObj.method}>
													{methodObj.label}
												</option>
											))}
										</Field>
									</div>
									<div className="col-span-7 font-semibold">
										<Label htmlFor="url" className={`mb-2 text-sm block text-left`}>
											URL *
										</Label>
										<Field
											className="bg-[#161A27]"
											id="url"
											type="text"
											name="url"
											placeholder="Enter rest api end point"
											validate={composeValidators(required)}>
											{renderField}
										</Field>
									</div>
								</div>

								{apiType === API_TYPES.ASYNC && (
									<div className="grid grid-cols-12 gap-4 mb-4">
										<div className="col-span-3 font-semibold">
											<Label htmlFor="asyncAPIName" className={`mb-2 text-sm block text-left`}>
												API Name *
											</Label>
											<Field
												className="bg-[#161A27]"
												id="asyncAPIName"
												type="text"
												name="asyncAPIName"
												placeholder="Enter api name like “Load Test 1”, etc. "
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>
										<div className="col-span-2 font-semibold">
											<Label htmlFor="asyncAPIHttpMethod" className={`mb-2 text-sm block text-left`}>
												HTTP Method *
											</Label>
											<Field
												name="asyncAPIHttpMethod"
												component="select"
												onChange={(event) => handleAsyncMethodChange(event.target.value)}
												className="bg-[#161A27] w-full block p-1 rounded-md border border-gray-600 h-9">
												<option value="">Select Method</option>
												{HTTP_METHODS.map((methodObj) => (
													<option key={methodObj.method} value={methodObj.method}>
														{methodObj.label}
													</option>
												))}
											</Field>
										</div>
										<div className="col-span-7 font-semibold">
											<Label htmlFor="asyncAPIEndPointURL" className={`mb-2 text-sm block text-left`}>
												URL *
											</Label>
											<Field
												className="bg-[#161A27]"
												id="asyncAPIEndPointURL"
												type="text"
												name="asyncAPIEndPointURL"
												placeholder="Enter rest api end point"
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>
									</div>
								)}
								<div className="flex items-center space-x-4 mb-4">
									<Label htmlFor="headers" className="text-lg font-bold">
										Authorization Type
									</Label>
									<hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
								</div>
								<div className="mb-4">
									<div className="grid grid-cols-12 gap-4 mb-4">
										<div className="col-span-3 font-semibold">
											<Field name="authType" component="select" value={authType}>
												{({ input }) => (
													<div className="w-full flex flex-col mb-2">
														<select
															{...input}
															value={authType} // Set the value of the select element to authType state
															onChange={(e) => {
																input.onChange(e);
																setAuthType(e.target.value); // Update authType state when selection changes
															}}
															className="border border-[#444444] bg-[#161A27] rounded-sm p-1 h-9">
															<option key="select" value="" disabled>
																Select authorization type
															</option>
															{AUTH_TYPES.map((type, index) => (
																<option key={index} value={type.value}>
																	{type.label}
																</option>
															))}
														</select>
													</div>
												)}
											</Field>

											<p className="text-left text-base text-[#A1A1AA] font-normal manrope-fontCss">
												{authType === "noAuth" && (
													<>
														The authorization header will be automatically generated when you send the request. Learn more about{" "}
														<span className="text-[#9747FF] manrope-fontCss">API Key</span> authorization.
													</>
												)}
												{authType === "bearerToken" && (
													<>
														Bearer Token requires you to provide an access token for authorization. Learn more about{" "}
														<span className="text-[#9747FF] manrope-fontCss">API Key</span> authorization.
													</>
												)}
												{authType === "basicAuth" && (
													<>
														Basic Authentication requires a username and password. Learn more about{" "}
														<span className="text-[#9747FF] manrope-fontCss">API Key</span> authorization.
													</>
												)}
												{authType === "jwtbearer" && (
													<div className="flex flex-col space-y-4 w-full">
														<div className="">
															Basic Authentication requires a username and password. Learn more about{" "}
															<span className="text-[#9747FF] manrope-fontCss">JWT Bearer Token</span> authorization.
														</div>
														<div className="flex flex-row space-x-2 w-full">
															<p className="w-[65%]">Add JWT Token to </p>

															<span className="-mt-1">
																<Field name="algorithm" component="select">
																	{({ input }) => (
																		<select {...input} className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
																			<option value="" disabled></option>
																			{ALGORITHM_OPTIONS.map((option) => (
																				<option key={option.value} value={option.value}>
																					{option.label}
																				</option>
																			))}
																		</select>
																	)}
																</Field>
															</span>
														</div>
													</div>
												)}
												{authType === "digitalauth" && (
													<>
														<div className="text-base flex flex-col space-y-4">
															<div>
																Basic Authentication requires a username and password. Learn more about{" "}
																<span className="text-[#9747FF] manrope-fontCss">JWT Bearer</span> authorization.
															</div>
															<div>
																By default, Scale Secure will extract values from the received response, add it to the request, and retry it. Do you
																want to disable this?
															</div>

															<div className="flex items-center space-x-4">
																<Checkbox />
																<p>Yes, disable retrying the request</p>
															</div>
														</div>
													</>
												)}

												{authType === "oauth1" && (
													<div className="flex flex-col space-y-4">
														<div>
															Basic Authentication requires a username and password. Learn more about{" "}
															<span className="text-[#9747FF] manrope-fontCss">OAuth 1.0</span> authorization.
														</div>
														<div className="flex flex-row space-x-2">
															<p className="w-[65%]">Add Auth Data to</p>

															<span className="-mt-1 flex flex-col w-[55%] gap-2">
																<Field name="algorithm" component="select">
																	{({ input }) => (
																		<select {...input} className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
																			<option value="" disabled></option>
																			{ALGORITHM_OPTIONS.map((option) => (
																				<option key={option.value} value={option.value}>
																					{option.label}
																				</option>
																			))}
																		</select>
																	)}
																</Field>
																<p>Postman will automatically choose between body and URL based on the request method.</p>
															</span>
														</div>
													</div>
												)}
												{!authType && (
													<>
														The authorization header will be automatically generated when you send the request. Select an authorization type to see more
														details.
													</>
												)}
											</p>
										</div>

										<div className="flex flex-col col-span-9 font-semibold">
											<Card className="w-full h-auto p-3 border border-[#747474]">
												{authType === LOAD_TEST_AUTH_TYPE[0].value && (
													<div className="flex flex-col gap-4 items-center">
														<p className="text-sm text-[#A1A1AA]">This request does not use any authorization. Learn more about authorization.</p>
													</div>
												)}
												{authType === LOAD_TEST_AUTH_TYPE[1].value && (
													<div className="flex flex-col gap-4">
														<div className="grid grid-cols-1 gap-4">
															<div className="grid gap-2">
																<div className="relative">
																	<Field id="username" type="text" name="username" validate={composeValidators(required)}>
																		{({ input }) => (
																			<>
																				<input
																					{...input}
																					type="text"
																					className="block w-full px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
																					placeholder=" "
																					value={values.username}

																					// onChange={(e) =>
																					//   setValues({ ...values, username: e.target.value })
																					// }
																				/>
																				<label
																					htmlFor="username"
																					className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
																					Username *
																				</label>
																			</>
																		)}
																	</Field>
																</div>
															</div>
														</div>
														<div className="grid gap-2">
															<div className="relative">
																<Field id="password" type="password" name="password" validate={composeValidators(required)}>
																	{({ input }) => (
																		<>
																			<input
																				{...input}
																				type="password"
																				className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
																				placeholder=" "
																				value={values.password}
																				// onChange={(e) =>
																				//   setValues({ ...values, password: e.target.value })
																				// }
																			/>
																			<label
																				htmlFor="password"
																				className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
																				Password *
																			</label>
																		</>
																	)}
																</Field>
															</div>
														</div>
													</div>
												)}
												{authType === LOAD_TEST_AUTH_TYPE[2].value && (
													<div className="grid grid-cols-1 pt-2 gap-2">
														<div className="grid gap-4 ">
															<div className="relative ">
																<Field id="bearerToken" type="textarea" name="bearerToken" validate={composeValidators(required)}>
																	{({ input }) => (
																		<>
																			<textarea
																				{...input}
																				className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
																				placeholder=" "
																				value={values.bearerToken}
																				// onChange={(e) =>
																				//   setValues({ ...values, bearerToken: e.target.value })
																				// }
																			/>
																			<label
																				htmlFor="bearerToken"
																				className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
																				Bearer Token *
																			</label>
																		</>
																	)}
																</Field>
															</div>
														</div>
													</div>
												)}
											</Card>
										</div>
									</div>
								</div>

								<Headers values={values} handleKeyValueBlurOrChange={handleKeyValueBlurOrChange} />

								<TestCase
									key="tests"
									apiName={values.name}
									fieldName="tests"
									values={values}
									resultData={resultData}
									addMoreTest={addMoreTest}
									composeValidators={composeValidators}
									handleKeyValueBlurOrChange={handleKeyValueBlurOrChange}
								/>

								{apiType === API_TYPES.ASYNC && (
									<TestCase
										key="asyncAPITests"
										apiName={values.asyncAPIName}
										fieldName="asyncAPITests"
										values={values}
										resultData={asyncResultData}
										addMoreTest={addMoreAsyncTest}
										composeValidators={composeValidators}
										handleKeyValueBlurOrChange={handleKeyValueBlurOrChange}
									/>
								)}
							</form>
						</React.Fragment>
					)}
				/>
			</div>
		</main>
	);
};

export default TestForm;
