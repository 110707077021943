import React, { useState, useEffect } from 'react';
import { useToast } from '../../components/ui/use-toast';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import html2pdf from 'html2pdf.js'
// import DashTable from '../../components/Admin_UserManagement/DashTable';
// import { deleteIcon, download } from '../../assets/icons2/index';
import { Button } from '../../components/ui/button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { getLoadTestResults, loadTest, deleteLoadTest, generateLoadCertificate, getLoadTestDetail, downloadPDF } from '../../utils/util'; // Import API functions
import NormalCard from '../../components/Cards/normal-card';
// import ButtonCard from '../../components/Cards/button-card';
import LoadButtonCard from '../../components/Cards/LoadButtonCard';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../../components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuSeparator,


 

  DropdownMenuLabel
} from '../../components/ui/dropdown-menu';
import { EllipsisVertical, FilterIcon } from 'lucide-react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/ui/accordion';
import { Checkbox } from '../../components/ui/checkbox';
import { Badge } from '../../components/ui/badge';
import DashTable from '../../components/Admin_UserManagement/DashTable';
import { Input } from '../../components/ui/input';
import { deleteIcon, download } from '../../assets/icons2';
import { PDFDownloadLink } from '@react-pdf/renderer';
import LoadResultPdf from '../../components/PDF/LoadResultPdf';
import { result } from 'lodash';
import downloadCertificate from '../../components/PDF/DownloadCertificate';
import loadDownloadCertificate from '../../components/PDF/LoadDownloadCertificate';
const LoadIndex = ({ user, setLoader }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState({ load: [] });
  // const [selectedTest, setSelectedTest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openSort, setOpenSort] = useState(false);

  // const tabsConfig = [{ value: 'load', label: 'Load' }];
  // const columns = [
  //   { id: 'test_name', header: 'Test Details' },
  //   { id: 'status', header: 'Status' },
  //   { id: 'virtual_users', header: 'Virtual Users' },
  //   { id: 'duration', header: 'Duration' },
  //   { id: 'startTime', header: 'Start Date' }
  // ];

  // const buttonOptions = [
  //   {
  //     icon: download,
  //     alt: 'Send Icon',
  //     text: <span className="text-base font-semibold">Download Certificate</span>,
  //     iconHeight: 4,
  //     iconWidth: 18,
  //     iconColor: 'text-[#FFFFFF]',
  //     name: 'Download',
  //     onClick: () => handleDownload(selectedTest)
  //   },
  //   {
  //     icon: deleteIcon,
  //     alt: '',
  //     text: <span className="text-base font-semibold">Delete</span>,
  //     iconHeight: 17,
  //     iconWidth: 17,
  //     iconColor: 'text-[#FFFFFF]',
  //     name: 'Delete',
  //     onClick: () => handleDelete(selectedTest)
  //   }
  // ];

  // eslint-disable-next-line no-unused-vars
  const [countOfLoadTest,setCountOfLoadTest]=useState( )
  const tabsConfig = [{ value: 'load', label: 'Load' }];
  const [totalPage,setTotalPage]=useState(1)
  const columns = [
    { id: 'testDetails', header: 'Test Details' },
    { id: 'type', header: 'Type' },
    { id: 'status', header: 'Status' },
    { id: 'virtual_users', header: 'Virtual Users' },
    { id: 'duration', header: 'Duration' },
    { id: 'startTime', header: 'Start Time' }
  ];
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    fetchLoadTestResults();
    const interval = setInterval(() => {
        fetchLoadTestResults(false);
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, []);




  const fetchLoadTestResults = async (isLoader) => {
    try {
      if (isLoader) { setLoader(true) };
      const response = await getLoadTestResults({
        user_id: user.user_id,
        enterprise_id: user.enterprise_id,
        object_id: user.object_id,
        page_number: 1,
        per_page: 10,
      });
      console.log('response', response);
      setLoader(false);
      setLoading(false)
      if (response.data?.message == "Data not found") {
        setLoadData({
          load: []
        });
      } else if (response.data?.data.length > 0) {
        setCountOfLoadTest(response?.data.count)
        setLoadData({
          load: response.data.data
        });
        setTotalPage(response?.data.total_pages)
      }
      console.log("Hii from load ",loadData)
    } catch (error) {
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch load test results'
      });
    }
  };

  const deleteLoad = async (item) => {
    setLoader(true)
    console.log(item);
    try {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        id: item.test_id,
        object_id: user.object_id
      };
      setLoader(true)
      const result = await deleteLoadTest(payload);
      const response = result.data
      setInterval(() => {
         setLoader(false)
         fetchLoadTestResults(false)
      }, 1000);
     
      console.log(response);
      if (response.status === 'success' && response.code === 200) {
        toast({
          title: "Success",
          description: response.message,
          variant: "success",
        });
      } else if (response.status === 'error') {
        switch (response.code) {
          case 400:
            console.error(response.message);
            toast({
              title: "Error",
              description: response.message,
              variant: "error",
            });
            break;
          case 404:
            console.error(response.message);
            toast({
              title: "Error",
              description: response.message,
              variant: "error",
            });
            break;
          case 500:
            console.error(response.message);
            toast({
              title: "Error",
              description: "Internal server error. Please try again later.",
              variant: "error",
            });
            break;
          default:
            console.error("Unexpected error occurred.");
            toast({
              title: "Error",
              description: "An unexpected error occurred.",
              variant: "error",
            });
            break;
        }
      }

    } catch (error) {
      console.error("API call failed:", error);
      toast({
        title: "Error",
        description: "Failed to delete the load test. Please check your connection and try again.",
        variant: "error",
      });
    }

  }



  const [reportData, setReportData] = useState(null);

const handleDownloadLoadReport = async (item) => {
  try {
    const payload = {
      user_id: user.user_id,
      enterprise_id: user.enterprise_id ? user.enterprise_id : null,
      test_name: item.test_name,
      object_id: user.object_id,
    };

    // Fetch the load test report (HTML content)
    const response = await getLoadTestDetail(payload);
    console.log("Download reports", response);

    const reportUrl = response?.data?.data?.file_path[0]?.output_path;
    console.log("Report URL:", reportUrl);

    if (reportUrl) {
      // Assuming response.data.data contains all the necessary report details
      setReportData(response.data.data);
      if(reportData?.result && reportData){
        console.log("(reportData?.result",reportData?.result)
        LoadResultPdf(reportData)
      }
 
      // You can remove the old download logic as you're using PDFDownloadLink now

    } else {
      throw new Error("Report URL not found in response");
    }
  } catch (error) {
    console.error("API call failed:", error);
    toast({
      title: "Error",
      description: "Failed to download the load test report. Please check your connection and try again.",
      variant: "error",
    });
  }
};

  const handleDownloadLoadCertificate = async (item) => {
    console.log("load test list : ", item.status);
   
   if (item?.status !== 'success') {
    console.log("hiiiiiiiiiiiiii",item.status)
        return toast({
          variant: 'destructive',
          title: 'Please wait',
          description: 'Test is not successfully complted'
        });
      }

    try {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        test_name: item.test_name,
        object_id: user.object_id,
      };
  
      // const response = await getLoadTestDetail({
      //   user_id: user.user_id,
      //   enterprise_id: user.enterprise_id,
      //   test_name: test_name,
      //   object_id: user.object_id
      // });
      const response = await getLoadTestDetail(payload);
      console.log("HELLLO DOWNLOPAD CERTIFICATE",response?.data.data)
      // Call the API to generate the certificate (assuming it returns a URL)
      // const response = await generateLoadCertificate(payload);
      // console.log("generateLoadCertificate(payload);",response)
      const generateCertificateNumber=()=> {
        const randomNum = Math.floor(1000 + Math.random() * 9000); // Random 4-digit number
        const timestamp = Date.now(); // Timestamp to make it unique
        return `CERT-${randomNum}-${timestamp}`;
    }
    const certificateNo=generateCertificateNumber()
     
        // Download the certificate with the specified details
        loadDownloadCertificate({
            testName:item?.test_name,
            virtualUser:response?.data.data.number_of_virtual_users,
            certificateNumber: certificateNo,
            issueDate: moment().format('DD/MM/YYYY'),
        });
   
     
    
     

      // Check if the response contains the URL
   

 

      // if (certificateUrl) {

      //   const type="certificate"
      //   console.log("Download PDF",certificateUrl)
      //   await downloadPDF(certificateUrl,item.test_id,type)

      //   // // Create a hidden link element
      //   // const link = document.createElement("a");
      //   // link.href = certificateUrl;
  
      //   // // Set the download attribute (optional if you want a specific file name)
      //   // link.download = "load_test_certificate.pdf";
  
      //   // // Programmatically click the link to trigger the download
      //   // document.body.appendChild(link); // Append the link to the document body
      //   // link.click(); // Trigger the download
      //   // document.body.removeChild(link); // Remove the link after triggering the download
      // } else {
      //   throw new Error("Certificate URL not found in response");
      // }
  
    } catch (error) {
      console.error("API call failed:", error);
      toast({
        title: "Error",
        description: "Failed to download the load test certificate. Please check your connection and try again.",
        variant: "error",
      });
    }
  };
  
  const handleStartTest = async (testData) => {
    try {
      setLoader(true);
      const response = await loadTest({
        user_id: user.user_id,
        client_id: user.client_id,
        ...testData
      });
      setLoader(false);
      // console.log("load test count",response?.data.count)
      if (response.data) {
        toast({
          title: 'Success',
          description: 'Load test started successfully'
        });
        fetchLoadTestResults();
      }
    } catch (error) {
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to start load test'
      });
    }
  };

  const loadItemType = (type) => {
    switch (type) {
      case 'loadtest-nonrelationalDb':
        return 'Database';
      case 'loadtest-relationalDb':
        return 'Database';
      case 'loadtest-Website':
        return 'Website';
      case 'loadtest-soapapi':
        return 'SOAP';
      case 'loadtest-kafka':
        return 'Message Broker';
      case 'loadtest-rest-api':
        return 'Rest API';
      case 'loadtest-graphQL':
        return 'graphQL';
      default:
        return type
    }
  }

  // const handleTestSelect = async (test) => {
  //   console.log("check");

  //   try {
  //     setLoader(true);
  //     const response = await getLoadTestDetail({
  //       user_id: user.user_id,
  //       client_id: user.client_id,
  //       testName: test.testDetails
  //     });
  //     setLoader(false);
  //     if (response.data) {
  //       setSelectedTest(response.data);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     toast({
  //       variant: 'destructive',
  //       title: 'Error',
  //       description: 'Failed to fetch test details'
  //     });
  //   }
  // };

  // const handleDownload = (test) => {
  //   // Implement download logic here
  //   console.log('Downloading certificate for', test);
  // };

  const handleNavigate = (id) => {
    navigate(`/load/test-results/${id}`);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'started':
        return 'border-[#9747FF] bg-[#9747FF] text-[#9747FF] bg-opacity-25';
      case 'success':
      case 'passed':
      case 'completed':
        return 'border-[#22C55E] bg-[#22C55E] text-[#22C55E] bg-opacity-25';
      case 'not started':
        return 'border-[#A9A9A9] bg-[#A9A9A9] text-[#A9A9A9] bg-opacity-25';
      case 'paused':
      case 'pending':
      case 'in progress':
        return 'border-[#FEB101] bg-[#FEB101] text-[#FEB101] bg-opacity-25';
      case 'failed':
        return 'border-[#EF4444] bg-[#EF4444] text-[#EF4444] bg-opacity-25';
      default:
        return 'border-[#A9A9A9] bg-[#A9A9A9] text-[#A9A9A9] bg-opacity-25';
    }
  };

  const getStatusColorForDot = (status) => {
    switch (status?.toLowerCase()) {
      case 'started':
        return 'bg-[#9747FF]';
      case 'success':
      case 'passed':
      case 'completed':
        return 'bg-[#22C55E]';
      case 'not started':
        return 'bg-[#A9A9A9]';
      case 'paused':
      case 'pending':
      case 'in progress':
        return 'bg-[#FEB101]';
      case 'failed':
        return 'bg-[#EF4444]';
      default:
        return 'bg-[#A9A9A9]';
    }
  };

  // const handleDelete = async (test) => {
  //   // Implement delete logic here
  //   console.log('Deleting test', test);
  //   // After successful deletion, refresh the list
  //   await fetchLoadTestResults();
  // };
  const url = '/load/test';
  const buttonName = 'Quick start';
  const buttonTitle = 'Start Testing';
  const cardData = [
    { title: 'Test', numberOfTest: countOfLoadTest || 0},
    // { title: 'Virtual Users', numberOfTest: 32, denominator: 50 },
    // { title: 'Minutes Used', numberOfTest: 32, denominator: 50 }
  ];
 

  const renderTableRows = ({ load }) => {
    return Array.isArray(load) && load.length > 0 ? (
      load.map((item, index) => (
        <TableRow
          key={index}
          className="border-b border-[#333333] cursor-pointer"
          onClick={() => {
            if (item.status === "success") {
              handleNavigate(item.test_name);
            }
          }}
        >
          <TableCell className="text-center">
            <div className="text-lg font-semibold text-white">{item.test_name}</div>
          </TableCell>
          <TableCell className="text-center">
            <Badge
              className={`${getStatusColor(item.status)} text-base font-semibold w-32 h-8 inline-flex items-center justify-center`}
            >
              <div className="flex flex-row justify-center items-center">
                <div className={`${getStatusColorForDot(item.status)} w-2 h-2 rounded-full mr-2`} />
                {item.status}
              </div>
            </Badge>
          </TableCell>
          <TableCell className="text-center">
            <div className="text-xs font-medium text-white">{item.virtual_users}</div>
          </TableCell>
          <TableCell className="text-center">
            <div className="text-xs font-medium text-white">{item.duration}</div>
          </TableCell>
          <TableCell className="text-center">
            <div className="text-xs font-medium text-white">{loadItemType(item.type)}</div>
          </TableCell>
          <TableCell className="text-center">
            <div className="flex flex-col items-center">
              <div className="text-xs text-white font-medium">
                {moment(item.created_at).format('MM/DD/YYYY')}
              </div>
              <div className="text-xs font-medium text-muted-foreground">
                {moment(item.created_at).format('hh:mm:ss A')}
              </div>
            </div>
          </TableCell>
          <TableCell className="text-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <EllipsisVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem>
                  <Button
                    variant="none"
                    className="flex items-center px-2 gap-4 h-6 text-sm"
                    onClick={() => handleDownloadLoadCertificate(item)}
                  >
                    Download Certificate
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Button
                    variant="none"
                    className="flex items-center px-2 gap-4 h-6 text-sm"
                    onClick={() => handleDownloadLoadReport(item)}
                  >
                    Download Reports
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Button
                    variant="none"
                    className="flex items-center px-2 gap-4 h-6 text-sm"
                    onClick={() => {
                      if (item.status === "success") {
                        handleNavigate(item.test_name);
                      }
                    }}
                  >
                    View Results
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <Button
                    variant="none"
                    className="flex items-center px-2 gap-4 h-6 text-sm"
                    onClick={() => deleteLoad(item)}
                  >
                    Delete
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </TableCell>
        </TableRow>
      ))
    ) : (
      <div className="text-center text-white"></div>
    );
    
  };

 // filter component 
 const [openFilter, setOpenFilter] = useState(false);

  // const FilterComponent = () => (
  //   <DropdownMenu open={openFilter} onOpenChange={setOpenFilter}>
  //     <DropdownMenuTrigger asChild>
  //       <Button variant="outline" className="bg-[#222938] h-9 px-4 gap-2 border border-[#444444]">
  //         <FilterIcon className="h-4 w-4" />
  //         <span>More Filters</span>
  //       </Button>
  //     </DropdownMenuTrigger>
  //     <DropdownMenuContent
  //       className="w-[200px] bg-[#222938] text-white"
  //       onClick={(e) => e.stopPropagation()}>
  //       <DropdownMenuLabel>Apply Filters</DropdownMenuLabel>
  //       <DropdownMenuSeparator />
  //       <Accordion type="single" collapsible className="w-full">
  //         {/* {columns.map((column) => (
  //           column.id!=="startTime" && <AccordionItem value={column.id} key={column.id}>
  //             <AccordionTrigger className="text-base font-medium manrope-fontCss">
  //               {column.header}
  //             </AccordionTrigger>
             
  //             <AccordionContent>
  //               {column.id === 'status' ? (
  //                 getUniqueValues('status').map((status) => (
  //                   <div key={status} className="flex items-center space-x-2">
  //                     <Checkbox
  //                       id={status}
  //                       checked={filters.status.includes(status)}
  //                       onCheckedChange={(checked) => handleStatusChange(status, checked)}
  //                     />
  //                     <label
  //                       htmlFor={status}
  //                       onClick={(e) => {
  //                         e.preventDefault();
  //                         e.stopPropagation();
  //                       }}>
  //                       {status}
  //                       {console.log("helllllllllllllllllloooooooooo",status)}
  //                     </label>
  //                   </div>
  //                 ))
  //               )
  //               //  : column.id === 'date' ? (
  //               //   <Calendar
  //               //     mode="single"
  //               //     selected={filters.date}
  //               //     onSelect={handleDateChange}
  //               //     className="rounded-md border"
  //               //     onClick={(e) => e.stopPropagation()}
  //               //   />
  //               // )
  //                : (
  //                 <Input
  //                   placeholder={`Filter by ${column.header}`}
  //                   value={filters[column.id]}
  //                   onChange={(e) => handleFilterChange(column.id, e.target.value)}
  //                   className="bg-[#222938] border-gray-600"
  //                   onClick={(e) => e.stopPropagation()}
  //                 />
  //               )}
  //             </AccordionContent>
  //             <DropdownMenuSeparator />
  //           </AccordionItem>
  //         ))} */}
  //       </Accordion>
  //       <DropdownMenuItem className="items-center flex flex-col justify-center" onSelect={(e) => e.preventDefault()}>
  //         <Button >Clear Filters</Button>
  //       </DropdownMenuItem>
  //     </DropdownMenuContent>
  //   </DropdownMenu>
  // );

  const handleTestSelect=(item) => {
    if (item.status === "success") {
      handleNavigate(item.test_name);
    }
  }

  const buttonOptions = [
    {
      icon: download,
      name: 'Download Certificate',
      alt: 'Send Icon',
      text: <span className="text-base font-semibold">Download Certificate</span>,
      iconHeight: 4,
      iconWidth: 18,
      iconColor: 'text-[#FFFFFF]',
      actionLoadDownloadCertificate:handleDownloadLoadCertificate
    },
    // {
    //   icon: download,
    //   alt: '',
    //   name: 'Download Reports',
    //   text: <span className="text-base font-semibold">Download Report</span>,
    //   iconHeight: 17,
    //   iconWidth: 17,
    //   iconColor: 'text-[#FFFFFF]',
    //   actionLoadDownloadReports:handleDownloadLoadReport
    // },
    {
      icon: deleteIcon,
      alt: '',
      name: 'Delete',
      text: <span className="text-base font-semibold">Delete</span>,
      iconHeight: 17,
      iconWidth: 17,
      iconColor: 'text-[#FFFFFF]',
      actionLoadDelete:deleteLoad
    },
    {
      icon: deleteIcon,
      alt: '',
      name: 'View Results',
      text: <span className="text-base font-semibold">View Results</span>,
      iconHeight: 17,
      iconWidth: 17,
      iconColor: 'text-[#FFFFFF]',
      actionViewLoadResults:handleTestSelect
    },
    
  ];

  const renderTableContent = () => (
   <>
    {/* <FilterComponent/> */}
    <Table>
      <TableHeader className="bg-[#11141F] rounded-lg">
        <TableRow className="border-b border-[#333333]">
          <TableHead className="text-white text-base font-medium p-4 text-center">Test Name</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Status</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Virtual Users</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Duration</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Type</TableHead>
          <TableHead className="text-white text-base font-medium p-4 text-center">Created At</TableHead>
          <TableHead className="text-white text-xl font-medium text-muted-foreground p-4"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody className="bg-[#11141F]">
        {renderTableRows(loadData)}
      </TableBody>
    </Table>
   </>
  );

  useEffect(()=>{
    console.log("load data11111111111 ",loadData)
      },[])

  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:py-0 md:gap-8 w-full">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
        <div className={`flex h-full w-full gap-4`}>
          {cardData.map((card, index) => (
            <NormalCard
              onStartTest={handleStartTest}
              key={index}
              title={card.title}
              numberOfTest={card.numberOfTest}
              denominator={card.denominator}
            />
          ))}
          <LoadButtonCard title={buttonTitle} buttonName={buttonName} url={url} />
        </div>
        {/* <DashTable
        updatedData={handleUpdateData}
          tabsConfig={tabsConfig}
          columns={columns}
          data={load != 'None' ? (data ? data : {}) : { security: null }}
          buttonType={'Download'}
          buttonOptions={buttonOptions}
          accordionTab={accordionTab}
          page={page}
          name={'security'}
        /> */}
        {/* {loading ? <div>Loading...</div> : renderTableContent()} */}
        {/* {renderTableContent()} */}
        <DashTable
          tabsConfig={tabsConfig}
          columns={columns}
          data={loadData}
          buttonType={'Download'}
          buttonOptions={buttonOptions}
          onRowClick={handleTestSelect}
          count={countOfLoadTest}
          totalPage={totalPage}
         
          openSort={openSort} setOpenSort={setOpenSort}
        />
      </div>
    </main>
  );
};

export default withLoader(withUser(LoadIndex));

