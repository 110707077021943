// Library imports
import React, { useMemo, useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { CircleX } from "lucide-react"

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'rgba(0,0,0,0.5)',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export const FileUploader = ({onValueChange}) => {

  const [jsonData, setJsonData] = useState(null);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState(null);

  // Callback to handle file drop
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]; // Get the first file dropped

    if (file && file.type === "application/json") {  // Ensure it's a JSON file
      const reader = new FileReader();

      // Handle file load event
      reader.onload = (event) => {
        try {
          const parsedJson = JSON.parse(event.target.result);
          setJsonData(parsedJson);  // Store the parsed JSON in the state
          setFileName(file.name); 
          setError(null);  // Clear any previous errors
          onValueChange(event.target.result)
        } catch (e) {
          setError("Failed to parse JSON file.");
        }
      };

      reader.readAsText(file);  // Read the file as text
    } else {
      setError("Please upload a valid JSON file.");  // Error if not a JSON file
    }
  }, []);

  const removeFile = () => {
    setJsonData(null)
    setFileName(null); 
    setError(null); 
  }

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
      accept: {'application/json': ['.json']},
      onDrop: onDrop
    })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div className="">
      {!jsonData &&
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <p>Drag &#39 drop json file here, or click to select a json file</p>
        </div>
      }
      {jsonData &&
        <div className="flex justify-start items-center">
          <p className="text-base">Uploaded File: <u>{fileName}</u></p>
          <CircleX onClick={removeFile} className="h-5.5 w-5.5 ml-2 cursor-pointer" stroke="red" />
        </div>
      }
    </div>
  )
}