import React from 'react';
import { Spinner } from 'react-activity';

const Loader = ({loadingMessage}) => {

  const message = loadingMessage || ""

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden bg-black opacity-75 flex flex-col items-center justify-center z-50">
      <Spinner size={30} color="white" />
      { message }
    </div>
  )
}

export default Loader;
