import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Plus, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { LOAD_TEST_METHODS } from '../../constants';
import arrayMutators from 'final-form-arrays';
import { Label } from '../../components/ui/label';
import { renderField } from '../../helpers/form-validations';
import { Input } from '../../components/ui/input';
// import { Slider } from '../../components/ui/slider';
// import { Switch } from '../../components/ui/switch';
import { Checkbox } from '../../components/ui/checkbox';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '../../components/ui/button';
import Box from '@mui/material/Box';  // MUI Box
import Typography from '@mui/material/Typography';  // MUI Typography
import Modal from '@mui/material/Modal';  // MUI Modal

import { Card } from '../../components/ui/card';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { Switch } from '../../components/ui/switch';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { loadTestGraphQL, getEnvironmentNames } from '../../utils/util';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',  // Make the modal wider to accommodate more content
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto'
};
const bodyStyle = {
  maxHeight: '60vh',  // Limit the body height
  overflowY: 'scroll',  // Make the body scrollable with always visible scrollbar
  scrollbarWidth: 'thin',  // For Firefox, ensures the scrollbar is visible but thin
  '&::-webkit-scrollbar': {
    width: '8px',  // For Chrome, Safari, and Edge
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',  // Custom color for the scrollbar thumb
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',  // Darker thumb on hover
  },
};

const LoadGraphql = ({ user, setLoader }) => {


  const { toast } = useToast();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);  // Modal open state
  const [apiResponse, setApiResponse] = useState(null);  // API response state
  const [payloadData, setPayloadData] = useState(null)
  const [envNames, setEnvNames] = useState([]);

  const handleOpen = () => setOpen(true);  // Open modal
  const handleClose = () => setOpen(false);  // Close modal

  const onSubmit = async (formData) => {

    try {
      console.log(formData, user);

      let headers = formData.headerValues.reduce((acc, header) => {
        if (header.key && header.value) {
          acc[header.key] = header.value;
        }
        return acc;
      }, {});
      headers["content-type"] = "application/json";

      const payload = {
        user_id: user?.user_id,
        graphql_url: formData?.url,
        virtual_user: formData?.virtualUsers,
        method: formData.httpMethod || "post",
        authorization: [
          {
            authorizationType: formData?.authType,
            userName: formData?.authType === LOAD_TEST_AUTH_TYPE[1].type ? formData?.username : '',
            password: formData?.authType === LOAD_TEST_AUTH_TYPE[1].type ? formData?.password : '',
            Token: formData?.authType === LOAD_TEST_AUTH_TYPE[2].type ? formData?.bearerToken : ''
          }
        ],
        test_name: formData?.testName,
        enterprise_id: user?.enterprise_id,
        // headers: formData?.headerValues,
        headers: formData?.headerValues
          .filter(({ key, value }) => key && value) // Filter out empty key-value pairs
          .map(({ key, value }) => ({ key, value })), // Map valid headers to payload
        
        query: formData?.query,
        variables: formData?.variables || {},
        
        
        restart: false,
        subscription_id: user?.activated_subscription_id,
        object_id: user?.object_id,
        environment: formData?.environment || "dev"
      };

      setPayloadData(payload)

      // const response = await fetch(formData.url, {
      //   method: 'POST',
      //   // method: formData.httpMethod,
      //   headers: headers,
      //   body: JSON.stringify({
      //     query:formData?.query,
      //     variables: formData?.variables && JSON.parse(formData.variables) || {}
      //   })
      // });

      let response;

if(formData?.httpMethod==="get"){
  const query = encodeURIComponent(formData?.query);

 response = await fetch(`${formData?.url}?query=${query}`, {
  method: formData?.httpMethod,  // Should be 'GET' here
  headers: {
    'Content-Type': 'application/json'
  },
});
}
if(formData?.httpMethod==="post"){
  response = await fetch(formData?.url, {
  method: formData?.httpMethod,
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({ query:formData?.query })
});
}


const data = await response.json();
console.log("GraphQL response:", data);

      
      // console.log("respomse.json",data,response,data.errors)
      if (data.errors) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Some Error occurred calling this endpoint. Please try again'
        });
        console.error('GraphQL errors:', data.errors);
      } else {
        setApiResponse(data.data);
        handleOpen();
        console.log('GraphQL data:', data.data);
      }
    } catch (e) {
      console.log('Error', e);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: e.message
      });
    }
  };

  const handleApiResponse = (response) => {
    console.log(response);
    switch (response.status) {
      case 'success':
        toast({ description: response.message, variant: 'success' });
        break;
      case 'error':
        switch (response.code) {
          case 404:
            toast({ title:"Warning",description: "You don't have any activated or valid subscription", variant: 'destructive' });
            break;
          case 400:
            toast({ description: response.message, variant: 'error' });
            break;
          case 500:
            toast({ description: response.message, variant: 'error' });
            break;
          default:
            toast({ title:"Warning",description: 'An unknown error occurred.', variant: 'error' });
        }
        break;
      default:
        toast({ description: 'Unexpected response from the server.', variant: 'warning' });
    }
  };

  const ALGORITHM_OPTIONS = [
    { value: 'md5', label: 'MD5' },
    { value: 'sha256', label: 'SHA256' },
    { value: 'sha756', label: 'SHA756' }
  ];

  const handleKeyValueBlurOrChange = (fields, index, values) => {
    const currentPair = values.headerValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  const handleEnvironmentChange = (newEnv) => {
    setFormState((prevState) => ({
      ...prevState,
      environment: newEnv,
    }));
  };

  useEffect(() => {
    fetchEnvNames();
  }, []);

  const fetchEnvNames = async () => {
    try {
      setLoader(true);
      const response = await getEnvironmentNames({
        user_id: user.user_id,
        enterprise_id: user.enterprise_id
      });
      setEnvNames(response.data.data.Environment_list.filter(i => i))
      setLoader(false);

    } catch (error) {
      setEnvNames([])
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch environment names'
      });
    }
  };

  const runTest = async () => {
    try {
      handleClose()
      setLoader(true);
      const loadTestRes = await loadTestGraphQL(payloadData);
      setLoader(false);
      handleApiResponse(loadTestRes.data);
      navigate('/load')
    } catch (error) {
      setLoader(false);
      console.error('runTest API call  failed:', error);
      toast({ description: 'Some Error Occurred.', status: 'error' });
    }
  }

  const AUTHORIZATION_TYPES = [
    { value: 'noAuth', label: 'No Auth' },
    { value: 'basicAuth', label: 'Basic Auth' },
    { value: 'bearerToken', label: 'Bearer Token' },
    { value: 'jwtbearer', label: 'JWT BearerToken' },
    { value: 'digitalauth', label: 'Digital Auth' },
    { value: 'oauth1', label: 'OAuth 1.0' },
    { value: 'oauth2', label: 'OAuth 2.0' },
    { value: 'hawk', label: 'Hawk Authentication' },
    { value: 'awssign', label: 'AWS Signature' },
    { value: 'nltm', label: 'NLTM Authentication' }
  ];

  const [authType, setAuthType] = useState('');

  const LOAD_TEST_AUTH_TYPE = AUTHORIZATION_TYPES;

  const required = (value) => (value ? undefined : 'Required');

  const composeValidators =
    (...validators) =>
      (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

  const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(false);
  const [formState, setFormState] = useState({
    httpMethod: LOAD_TEST_METHODS[0].method,
    headerValues: [{ key: '', value: '' }],
    environment: ''

  })

  const handleMethodChange = (newMethod) => {
    setFormState((prevState) => ({
      ...prevState,
      httpMethod: newMethod,
    }));
  };


  return (
    <main className="grid flex-1 items-start gap-4 p-4  sm:py-0 md:gap-8 w-full ">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
        <Form
          onSubmit={onSubmit}
          initialValues={formState}
          keepDirtyOnReinitialize
          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit, values,form }) => (
            <>

              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 w-full">
                {envNames.length > 0 && (
  <div className="flex w-full pt-2 gap-2">
    <div className="flex-1 gap-4 font-semibold text-lg">
      <label htmlFor="httpMethods" className="text-base">
        Select Environment
      </label>
      <Field
        name="environment"
        component="select"
        onChange={(event) => handleEnvironmentChange(event.target.value)} // Remove event.preventDefault()
        className="ml-4 bg-gray-700 border border-gray-600 text-white p-1 rounded-md flex-1"
        required
      >
        <option value="">Choose Environment</option>
        {envNames.map((envObj) => (
          <option key={envObj} value={envObj}>
            {envObj}
          </option>
        ))}
      </Field>
    </div>
  </div>
)}

                  <div className="flex w-full pt-2 gap-2">
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="testName" className={`mb-4`}>
                        Test Name
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="testName"
                        type="text"
                        name="testName"
                        placeholder="Write test name like “Load Test 1”, etc. "
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="virtualUsers" className={`mb-4`}>
                        Virtual Users
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="virtualUsers"
                        type="text"
                        name="virtualUsers"
                        placeholder="Enter number of Virtual Users here "
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>

                    

                    {/* <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                        <Label htmlFor="graphQLNo" className={`mb-4`}>
                          GraphQL No
                        </Label>
                        <Field
                          className="w-full bg-[#161A27] mt-2"
                          id="graphQLNo"
                          type="text"
                          name="graphQLNo"
                          placeholder="Enter GraphQL No"
                          validate={composeValidators(required)}>
                          {renderField}
                        </Field>
                      </div> */}
                  </div>
                 <div className='flex w-full pt-2 gap-2 flex-col ' >
                 <div >
                      <label htmlFor="httpMethods" className="text-base">
                        HTTP Methods
                      </label>
                      <Field
                        name="httpMethod"
                        component="select"
                        onChange={(event) => handleMethodChange(event.target.value)}
                        className="ml-4 bg-gray-700 border border-gray-600 text-white p-1 rounded-md flex-1"
                      >
                        <option value="">Select Method</option>
                        {LOAD_TEST_METHODS.map((methodObj) => (
                          <option key={methodObj.method} value={methodObj.method}>
                            {methodObj.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className=" gap-y-2 font-semibold text-lg">
                      <Label htmlFor="url" className={`mb-4`}>
                        URL
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="url"
                        type="text"
                        name="url"
                        placeholder="Add URL here"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                 </div>
                  <div className="flex w-full pt-2 gap-2">
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="query" className={`mb-4`}>
                        Query
                      </Label>
                      <Field
  className="w-full bg-[#161A27] min-h-[40px] rounded-md mt-2 border border-[#747474] placeholder:text-sm focus:outline-none  placeholder:text-left pl-4 pt-2"
  id="query"
  type="textarea"
  name="query"
  placeholder="Enter Query Here"
  validate={composeValidators(required)}
>
  {renderField}
</Field>

                    </div>

                    <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="variables" className={`mb-4`}>
                        Variables
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="variables"
                        type="text"
                        name="variables"
                        placeholder="Enter Variables here"
                      >
                        {renderField}
                      </Field>
                    </div>
                  </div>
                  {/* <div className="flex w-full pt-2 gap-2">
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="virtualUsers" className={`mb-4`}>
                        Virtual Users
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="virtualUsers"
                        type="text"
                        name="virtualUsers"
                        placeholder="Enter number of Virtual Users here "
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>

                    <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="url" className={`mb-4`}>
                        URL
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="url"
                        type="text"
                        name="url"
                        placeholder="Add URL here"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                  </div> */}

                  {/* Headers  */}

                  <div className="flex items-center space-x-4 pt-4">
                    <Label htmlFor="headerValues" className="text-lg font-bold">
                      Headers
                    </Label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>

                  <div className="">
                    <FieldArray name="headerValues">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-2 items-center space-x-4 pb-2">
                              <div className={`flex flex-col col-span-1`}>
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Key</label>
                                )}
                                <Field
                                  name={`${name}.key`}
                                  render={({ input }) => (
                                    <Input
                                      {...input}
                                      placeholder="Key"
                                      className="border p-2 rounded bg-[#161A27]"
                                      onBlur={() =>
                                        handleKeyValueBlurOrChange(fields, index, values)
                                      }
                                      onChange={(e) => {
                                        input.onChange(e);
                                        handleKeyValueBlurOrChange(fields, index, values);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="flex flex-col col-span-1">
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Value</label>
                                )}
                                <div className="flex items-center space-x-4">
                                  <Field
                                    name={`${name}.value`}
                                    render={({ input }) => (
                                      <Input
                                        {...input}
                                        placeholder="Value"
                                        className="border p-2 rounded bg-[#161A27]"
                                        onBlur={() =>
                                          handleKeyValueBlurOrChange(fields, index, values)
                                        }
                                        onChange={(e) => {
                                          input.onChange(e);
                                          handleKeyValueBlurOrChange(fields, index, values);
                                        }}
                                      />
                                    )}
                                  />
                                  {fields.length > 1 && (
                                    <Trash2
                                      type="button"
                                      onClick={() => fields.remove(index)}
                                      className="focus:outline-none cursor-pointer"
                                      size={18}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>

                  {/* Api Auth Section  */}
                  <div className="flex items-center space-x-4 pt-4 text-lg font-bold">
                    <label htmlFor="testName" className="text-lg font-bold">
                      API Authorization
                    </label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>
                  <label htmlFor="authType" className="text-base font-semibold ">
                    Authorization Type
                  </label>

                  <div className="flex flex-row w-full space-x-4 ">
                    <div className="flex flex-col space-y-4 w-[25%]">
                      <Field name="authType" component="select" value={authType}>
                        {({ input }) => (
                          <div className="w-full flex flex-col">
                            <select
                              {...input}
                              value={authType} // Set the value of the select element to authType state
                              onChange={(e) => {
                                input.onChange(e);
                                setAuthType(e.target.value); // Update authType state when selection changes
                              }}
                              className="w-[95%] border border-[#444444] bg-[#161A27] rounded-sm p-1">
                              <option value="" disabled>
                                Select authorization type
                              </option>
                              {AUTHORIZATION_TYPES.map((type) => (
                                <option key={type.value} value={type.value}>
                                  {type.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </Field>

                      <p className="text-base text-[#A1A1AA] font-normal manrope-fontCss">
                        {authType === 'noAuth' && (
                          <>
                            The authorization header will be automatically generated when you send
                            the request. Learn more about{' '}
                            <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                            authorization.
                          </>
                        )}
                        {authType === 'bearerToken' && (
                          <>
                            OAuth 2.0 requires you to provide an access token for authorization.
                            Learn more about{' '}
                            <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                            authorization.
                          </>
                        )}
                        {authType === 'basicAuth' && (
                          <>
                            Basic Authentication requires a username and password. Learn more about{' '}
                            <span className="text-[#9747FF] manrope-fontCss">API Key</span>{' '}
                            authorization.
                          </>
                        )}
                        {authType === 'jwtbearer' && (
                          <div className="flex flex-col space-y-4 w-full">
                            <div className="">
                              Basic Authentication requires a username and password. Learn more
                              about{' '}
                              <span className="text-[#9747FF] manrope-fontCss">
                                JWT Bearer Token
                              </span>{' '}
                              authorization.
                            </div>
                            <div className="flex flex-row space-x-2 w-full">
                              <p className="w-[65%]">Add JWT Token to </p>

                              <span className="-mt-1">
                                <Field name="algorithm" component="select">
                                  {({ input }) => (
                                    <select
                                      {...input}
                                      className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                      <option value="" disabled></option>
                                      {ALGORITHM_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                              </span>
                            </div>
                          </div>
                        )}
                        {authType === 'digitalauth' && (
                          <>
                            <div className="text-base flex flex-col space-y-4">
                              <div>
                                Basic Authentication requires a username and password. Learn more
                                about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">JWT Bearer</span>{' '}
                                authorization.
                              </div>
                              <div>
                                By default, Scale Secure will extract values from the received
                                response, add it to the request, and retry it. Do you want to
                                disable this?
                              </div>

                              <div className="flex items-center space-x-4">
                                <Checkbox />
                                <p>Yes, disable retrying the request</p>
                              </div>
                            </div>
                          </>
                        )}

                        {authType === 'oauth1' && (
                          <div className="flex flex-col space-y-4">
                            <div>
                              Basic Authentication requires a username and password. Learn more
                              about{' '}
                              <span className="text-[#9747FF] manrope-fontCss">OAuth 1.0</span>{' '}
                              authorization.
                            </div>
                            <div className="flex flex-row space-x-2">
                              <p className="w-[65%]">Add Auth Data to</p>

                              <span className="-mt-1 flex flex-col w-[55%] gap-2">
                                <Field name="algorithm" component="select">
                                  {({ input }) => (
                                    <select
                                      {...input}
                                      className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                      <option value="" disabled></option>
                                      {ALGORITHM_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <p>
                                  Postman will automatically choose between body and URL based on
                                  the request method.
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                        {authType === 'oauth2' && (
                          <div className="flex flex-col space-y-4">
                            <div>
                              Basic Authentication requires a username and password. Learn more
                              about{' '}
                              <span className="text-[#9747FF] manrope-fontCss">OAuth 2.0</span>{' '}
                              authorization.
                            </div>
                            <div className="flex flex-row space-x-4">
                              <p>Add Auth Data to</p>

                              <span className="-mt-1 flex flex-col w-[55%] gap-2">
                                <Field name="algorithm" component="select">
                                  {({ input }) => (
                                    <select
                                      {...input}
                                      className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                      <option value="" disabled></option>
                                      {ALGORITHM_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <p>
                                  Postman will automatically choose between body and URL based on
                                  the request method.
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                        {authType === 'hawk' && (
                          <>
                            Basic Authentication requires a username and password. Learn more about{' '}
                            <span className="text-[#9747FF] manrope-fontCss">OAuth 1.0</span>{' '}
                            authorization.
                          </>
                        )}
                        {authType === 'awssign' && (
                          <div className="flex flex-col space-y-4">
                            <div>
                              Basic Authentication requires a username and password. Learn more
                              about{' '}
                              <span className="text-[#9747FF] manrope-fontCss">OAuth 1.0</span>{' '}
                              authorization.
                            </div>
                            <div className="flex flex-row space-x-4">
                              <p>Add Auth Data to</p>

                              <span className="-mt-1 flex flex-col w-[55%] gap-2">
                                <Field name="algorithm" component="select">
                                  {({ input }) => (
                                    <select
                                      {...input}
                                      className="w-full border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss">
                                      <option value="" disabled></option>
                                      {ALGORITHM_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <p>
                                  Postman will automatically choose between body and URL based on
                                  the request method.
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                        {authType === 'nltm' && (
                          <>
                            <div className="text-base flex flex-col space-y-4">
                              <div>
                                Basic Authentication requires a username and password. Learn more
                                about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">JWT Bearer</span>{' '}
                                authorization.
                              </div>
                              <div>
                                By default, Scale Secure will extract values from the received
                                response, add it to the request, and retry it. Do you want to
                                disable this?
                              </div>

                              <div className="flex items-center space-x-4">
                                <Checkbox />
                                <p>Yes, disable retrying the request</p>
                              </div>
                            </div>
                          </>
                        )}

                        {!authType && (
                          <>
                            The authorization header will be automatically generated when you send
                            the request. Select an authorization type to see more details.
                          </>
                        )}
                      </p>
                    </div>

                    <div className="h-full w-[1px] bg-[#6F6F6F] inline-block"></div>

                    <div className="flex flex-col w-[80%]">
                      <Card className="w-full h-auto p-3 border border-[#747474]">
                        {authType === LOAD_TEST_AUTH_TYPE[0].value && (
                          <div className="flex flex-col gap-4 items-center">
                            <p className="text-sm text-[#A1A1AA]">
                              This request does not use any authorization. Learn more about
                              authorization.
                            </p>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[1].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                              <div className="grid gap-2">
                                <div className="relative">
                                  <Field
                                    id="username"
                                    type="text"
                                    name="username"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-full px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.username}

                                        // onChange={(e) =>
                                        //   setValues({ ...values, username: e.target.value })
                                        // }
                                        />
                                        <label
                                          htmlFor="username"
                                          className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                          Username *
                                        </label>
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className="grid gap-2">
                              <div className="relative">
                                <Field
                                  id="password"
                                  type="password"
                                  name="password"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="password"
                                        className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                        placeholder=" "
                                        value={values.password}
                                      // onChange={(e) =>
                                      //   setValues({ ...values, password: e.target.value })
                                      // }
                                      />
                                      <label
                                        htmlFor="password"
                                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                        Password *
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[2].value && (
                          <div className="grid grid-cols-1 pt-2 gap-2">
                            <div className="grid gap-4 ">
                              <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                                <p className="flex justify-center">
                                  Note: These parameters hold sensitive data. To keep this data
                                  secure while working in a collaborative environment, we recommend
                                  using variables. Learn more about{' '}
                                  <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                  here.
                                </p>
                              </div>
                              <div className="relative ">
                                <Field
                                  id="bearerToken"
                                  type="textarea"
                                  name="bearerToken"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <textarea
                                        {...input}
                                        className="block w-full px-4 py-2 text-white bg-[#161A27] border border-[#747474] rounded-md appearance-none focus:outline-none peer h-10"
                                        placeholder=" "
                                        value={values.bearerToken}
                                      // onChange={(e) =>
                                      //   setValues({ ...values, bearerToken: e.target.value })
                                      // }
                                      />
                                      <label
                                        htmlFor="bearerToken"
                                        className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#161A27] px-2 peer-focus:px-2 peer-focus:text-white-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                        Bearer Token *
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[3].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="algorithm" className="flex items-center gap-1">
                                    <p className="text-base">Algorithm</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>

                                  <Field
                                    id="algorithm"
                                    type="text"
                                    name="algorithm"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.algorithm}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className="grid gap-2">
                              <div className="flex flex-row gap-4 justify-between items-center">
                                <Label htmlFor="secret" className="flex items-center gap-1">
                                  <p className="text-base">Secret</p>
                                  <div className="flex items-center">
                                    <QuestionMarkCircledIcon
                                      color="#616161"
                                      className=""
                                      width={19}
                                      height={19}
                                    />
                                  </div>
                                </Label>

                                <Field
                                  id="secret"
                                  type="text"
                                  name="secret"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="text"
                                        className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                        placeholder=" "
                                        value={values.secret}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="flex items-center ml-[30%]">
                                <Checkbox className="mr-2" />
                                <p>Secret Base64 encoded</p>
                              </div>
                            </div>
                            <div className="grid gap-2">
                              <div className="flex flex-row gap-4 justify-between items-center">
                                <Label htmlFor="payload" className="flex items-center gap-1">
                                  <p className="text-base">Payload</p>
                                  <div className="flex items-center">
                                    <QuestionMarkCircledIcon
                                      color="#616161"
                                      className=""
                                      width={19}
                                      height={19}
                                    />
                                  </div>
                                </Label>
                                <Field
                                  id="payload"
                                  component="textarea"
                                  name="payload"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <textarea
                                        {...input}
                                        className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-32"
                                        placeholder=" "
                                        value={values.payload}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                              <p className="text-xl text-white ">Advanced Configrations</p>
                              <p className="text-sm text-[#A1A1AA]">
                                Scale Secure auto-generates default values for some of these fields
                                unless a value is specified.
                              </p>
                            </div>
                            <div className="grid gap-2">
                              <div className="flex flex-row gap-4 justify-between items-center">
                                <Label htmlFor="headerprefix" className="flex items-center gap-1">
                                  <p className="text-base">Request Header Prefix</p>
                                  <div className="flex items-center">
                                    <QuestionMarkCircledIcon
                                      color="#616161"
                                      className=""
                                      width={19}
                                      height={19}
                                    />
                                  </div>
                                </Label>
                                <Field
                                  id="headerprefix"
                                  type="text"
                                  name="headerprefix"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <input
                                        {...input}
                                        type="text"
                                        className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] "
                                        placeholder=" "
                                        value={values.headerprefix}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="grid gap-2">
                              <div className="flex flex-row gap-4 justify-between items-center">
                                <Label htmlFor="jwtheader" className="flex items-center gap-1">
                                  <p className="text-base">JWT Headers</p>
                                  <div className="flex items-center">
                                    <QuestionMarkCircledIcon
                                      color="#616161"
                                      className=""
                                      width={19}
                                      height={19}
                                    />
                                  </div>
                                </Label>
                                <Field
                                  id="jwtheaders"
                                  component="textarea"
                                  name="jwtheaders"
                                  validate={composeValidators(required)}>
                                  {({ input }) => (
                                    <>
                                      <textarea
                                        {...input}
                                        className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-32"
                                        placeholder=" "
                                        value={values.jwtheaders}
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                              <p className="text-[#A1A1AA] text-base ml-[30%]">
                                Headers specific to the algorithm are added automatically.
                              </p>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[4].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="username" className="flex items-center gap-1">
                                    <p className="text-base">Username</p>
                                  </Label>
                                  <Field
                                    id="username"
                                    type="text"
                                    name="username"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.username}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="password" className="flex items-center gap-1">
                                    <p className="text-base">Password</p>
                                  </Label>
                                  <Field
                                    id="password"
                                    type="password"
                                    name="password"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="password"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.password}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="password" className="flex items-center gap-1">
                                    <p className="text-base">Realm</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="realm"
                                    type="text"
                                    name="realm"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="testrealm@example.com "
                                          value={values.realm}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="nonce" className="flex items-center gap-1">
                                    <p className="text-base">Nonce</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="nonce"
                                    type="text"
                                    name="nonce"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Nonce"
                                          value={values.nonce}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="algorithm" className="flex items-center gap-1">
                                    <p className="text-base">Algorithm:</p>
                                  </Label>
                                  <Field name="algorithm" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Select an algorithm
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="qop" className="flex items-center gap-1">
                                    <p className="text-base">QOP</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="qop"
                                    type="text"
                                    name="qop"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="For Example: auth-int"
                                          value={values.qop}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="nouncecount" className="flex items-center gap-1">
                                    <p className="text-base">Nounce Count</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="nouncecount"
                                    type="text"
                                    name="nouncecount"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="For Example: 0000000001"
                                          value={values.nouncecount}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="clientnounce" className="flex items-center gap-1">
                                    <p className="text-base">Client Nounce </p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="clientnounce"
                                    type="text"
                                    name="clientnounce"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="For Example: 0a4f12a3d"
                                          value={values.clientnounce}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="opaque" className="flex items-center gap-1">
                                    <p className="text-base">OPaque</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="opaque"
                                    type="text"
                                    name="opaque"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Opaque"
                                          value={values.opaque}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[5].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label
                                    htmlFor="signature method"
                                    className="flex items-center gap-1">
                                    <p className="text-base">Signature Method:</p>
                                  </Label>
                                  <Field name="algorithm" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Signature Method:
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="consumerkey" className="flex items-center gap-1">
                                    <p className="text-base">Consumer Key</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="consumerkey"
                                    type="text"
                                    name="consumerkey"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="consumer key "
                                          value={values.consumerkey}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="accesstoken" className="flex items-center gap-1">
                                    <p className="text-base">Access Token</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="accesstoken"
                                    type="text"
                                    name="accesstoken"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="access token "
                                          value={values.accesstoken}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="tokensecret" className="flex items-center gap-1">
                                    <p className="text-base">Token Secret</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="tokensecret"
                                    type="text"
                                    name="tokensecret"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Secret Token "
                                          value={values.tokensecret}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="callbackurl" className="flex items-center gap-1">
                                    <p className="text-base">CallBack Url</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>

                                  <Field name="callbackurl" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          CallBack Url Methods
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="verifier" className="flex items-center gap-1">
                                    <p className="text-base">Verifer</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="verifier"
                                    type="text"
                                    name="verifier"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Verifier"
                                          value={values.verifier}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="timestamp" className="flex items-center gap-1">
                                    <p className="text-base">TimeStamp</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="timestamp"
                                    type="text"
                                    name="timestamp"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Timestamp"
                                          value={values.timestamp}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="nounce" className="flex items-center gap-1">
                                    <p className="text-base">Nounce</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="nounce"
                                    type="text"
                                    name="nounce"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Nounce"
                                          value={values.nounce}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="version" className="flex items-center gap-1">
                                    <p className="text-base">Version</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="version"
                                    type="text"
                                    name="version"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="version"
                                          value={values.version}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="realm" className="flex items-center gap-1">
                                    <p className="text-base">Realm</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="realm"
                                    type="text"
                                    name="realm"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="realm"
                                          value={values.realm}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <hr className="h-px bg-[#6F6F6F] border-0 " />
                              <div className="flex items-center text-muted-foreground text-base manrope-fontCss">
                                <Checkbox className="mr-2" />
                                <p>Include Body Hash</p>
                              </div>
                              <div className="flex items-center text-muted-foreground text-base manrope-fontCss">
                                <Checkbox className="mr-2" />
                                <p>Add Empty Parameters to Signature</p>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[6].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div>
                                <p className="text-xl manrope-fontCss font-semibold">
                                  Current Token
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="token1" className="flex items-center gap-1">
                                    <p className="text-base font-medium">Token</p>
                                  </Label>
                                  <Field name="token1" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Available Token
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                                <div className="flex ml-[30%] w-full">
                                  <Field
                                    id="token"
                                    type="text"
                                    name="token"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Token"
                                          value={values.token}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="headerprefix" className="flex items-center gap-1">
                                    <p className="text-base">Header Prefix</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="headerprefix"
                                    type="text"
                                    name="headerprefix"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Bearer"
                                          value={values.headerprefix}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="flex flex-row justify-between w-full">
                                <div className="flex flex-col space-y-1">
                                  <p className="text-base font-semibold">Auto-Refresh Token</p>
                                  <p className="text-sm font-normal text-muted-foreground">
                                    Your expired token will be auto-refreshed before sending a
                                    request.
                                  </p>
                                </div>
                                <div className="p-2">
                                  <Switch
                                    id="auto-refresh-token"
                                    className=" w-12 h-6"
                                    value={isAutoRefreshEnabled}
                                    onCheckedChange={() =>
                                      setIsAutoRefreshEnabled(!isAutoRefreshEnabled)
                                    }
                                  />
                                </div>
                              </div>
                              <hr className="h-px bg-[#6F6F6F] border-0 w-full" />

                              <div className="flex flex-row justify-between w-full">
                                <div className="flex flex-col space-y-1">
                                  <p className="text-base font-semibold">Share Token</p>
                                  <p className="text-sm font-normal text-muted-foreground">
                                    This will allow anyone with access to this request to view and
                                    use it.
                                  </p>
                                </div>
                                <div className="p-2">
                                  <Switch
                                    id="auto-refresh-token"
                                    className=" w-12 h-6"
                                    value={isAutoRefreshEnabled}
                                    onCheckedChange={() =>
                                      setIsAutoRefreshEnabled(!isAutoRefreshEnabled)
                                    }
                                  />
                                </div>
                              </div>
                              <hr className="h-px bg-[#6F6F6F] border-0 w-full" />
                              <div>
                                <p className="text-xl font-semibold">Configure New Token</p>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="tokenname" className="flex items-center gap-1">
                                    <p className="text-base">Token Name</p>
                                  </Label>
                                  <Field
                                    id="tokenname"
                                    type="text"
                                    name="tokenname"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Token Name"
                                          value={values.tokenname}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="granttype" className="flex items-center gap-1">
                                    <p className="text-base">Grant Type</p>
                                  </Label>
                                  <Field name="granttype" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] text-muted-foreground rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Authorization code
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="callbackurl" className="flex items-center gap-1">
                                    <p className="text-base">CallBack URL</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="callbackurl"
                                    type="text"
                                    name="callbackurl"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Callbackurl"
                                          value={values.callbackurl}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="authurl" className="flex items-center gap-1">
                                    <p className="text-base">Auth URL</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="authurl"
                                    type="text"
                                    name="authurl"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="oauth,.pstmn.io/v1/browser-callback"
                                          value={values.authurl}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label
                                    htmlFor="accesstokenurl"
                                    className="flex items-center gap-1">
                                    <p className="text-base">Access Token URL</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="accesstokenurl"
                                    type="text"
                                    name="accesstokenurl"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="oauth,.pstmn.io/v1/browser-callback"
                                          value={values.accesstokenurl}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="clientid" className="flex items-center gap-1">
                                    <p className="text-base">Client ID</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="clientid"
                                    type="text"
                                    name="clientid"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Client ID"
                                          value={values.clientid}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="clientsecret" className="flex items-center gap-1">
                                    <p className="text-base">Client Secret</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="clientsecret"
                                    type="text"
                                    name="clientsecret"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Client Secret"
                                          value={values.clientsecret}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="scope" className="flex items-center gap-1">
                                    <p className="text-base">Scope</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="scope"
                                    type="text"
                                    name="scope"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="scope"
                                          value={values.scope}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>

                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="state" className="flex items-center gap-1">
                                    <p className="text-base">State</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="state"
                                    type="text"
                                    name="state"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="State"
                                          value={values.state}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="clientauth" className="flex items-center gap-1">
                                    <p className="text-base">Client Authentication</p>
                                  </Label>
                                  <Field name="clientauth" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] text-muted-foreground rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Send as Basic Auth Header
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label
                                    htmlFor="refreshtokenurl"
                                    className="flex items-center gap-1">
                                    <p className="text-base">Refresh Token Url</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="refreshtokenurl"
                                    type="text"
                                    name="refreshtokenurl"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="oauth,.pstmn.io/v1/browser-callback"
                                          value={values.refreshtokenurl}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="manrope-fontCss text-white bg-[#161A27] w-full space-y-4">
                                <div className="space-y-4 space-x-4 justify-between flex flex-row">
                                  <div>
                                    <h2 className="text-base font-semibold flex items-center gap-2">
                                      Auth Request
                                      <div className="flex items-center">
                                        <QuestionMarkCircledIcon
                                          color="#616161"
                                          className=""
                                          width={19}
                                          height={19}
                                        />
                                      </div>
                                    </h2>
                                  </div>
                                  <div className="border border-[#333333] rounded-md overflow-hidden w-[70%]">
                                    <table className="w-full border-solid">
                                      <thead>
                                        <tr className="bg-[#161A27] border border-[#333333] text-left">
                                          <th className="p-3 text-base font-medium border-r border-[#333333]"></th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Key
                                          </th>
                                          <th className="p-3 text-base font-medium">Value</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]"></td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Key Parameter"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Value"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="space-y-4 space-x-4 justify-between flex flex-row">
                                  <div>
                                    <h2 className="text-base font-semibold flex items-center gap-2">
                                      Token Request
                                      <div className="flex items-center">
                                        <QuestionMarkCircledIcon
                                          color="#616161"
                                          className=""
                                          width={19}
                                          height={19}
                                        />
                                      </div>
                                    </h2>
                                  </div>
                                  <div className="border border-[#333333] rounded-md overflow-hidden w-[70%]">
                                    <table className="w-full border-solid">
                                      <thead>
                                        <tr className="bg-[#161A27] border border-[#333333] text-left">
                                          <th className="p-3 text-base font-medium border-r border-[#333333]"></th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Key
                                          </th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Value
                                          </th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Send In
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]"></td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Key Parameter"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Value"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="sendIn"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="space-y-4 space-x-4 justify-between flex flex-row">
                                  <div>
                                    <h2 className="text-base font-semibold flex items-center gap-2">
                                      Token Request
                                      <div className="flex items-center">
                                        <QuestionMarkCircledIcon
                                          color="#616161"
                                          className=""
                                          width={19}
                                          height={19}
                                        />
                                      </div>
                                    </h2>
                                  </div>
                                  <div className="border border-[#333333] rounded-md overflow-hidden w-[70%]">
                                    <table className="w-full border-solid">
                                      <thead>
                                        <tr className="bg-[#161A27] border border-[#333333] text-left">
                                          <th className="p-3 text-base font-medium border-r border-[#333333]"></th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Key
                                          </th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Value
                                          </th>
                                          <th className="p-3 text-base font-medium border-r border-[#333333]">
                                            Send In
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]"></td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Key Parameter"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="Value"
                                            />
                                          </td>
                                          <td className="p-3 text-sm font-normal border-r border-[#333333]">
                                            <Input
                                              className="border-none"
                                              type="text"
                                              placeholder="sendIn"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <button className="bg-[#9747FF] text-white py-2 px-4 rounded-md hover:bg-[#8A3DFF] transition duration-300">
                                  Get New Access Token
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[7].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="hawkauthid" className="flex items-center gap-1">
                                    <p className="text-base">HAWK Auth ID:</p>
                                  </Label>
                                  <Field
                                    id="hawkauthid"
                                    type="text"
                                    name="hawkauthid"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="HMAC HSA1"
                                          value={values.hawkauthid}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="hawkauthkey" className="flex items-center gap-1">
                                    <p className="text-base">HAWK Auth Key:</p>
                                  </Label>
                                  <Field
                                    id="hawkauthkey"
                                    type="text"
                                    name="hawkauthkey"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Hawk Auth Key"
                                          value={values.hawkauthkey}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="algorithm" className="flex items-center gap-1">
                                    <p className="text-base">Algorithm:</p>
                                  </Label>
                                  <Field name="algorithm" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Select an algorithm
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="user" className="flex items-center gap-1">
                                    <p className="text-base">User</p>
                                  </Label>
                                  <Field
                                    id="user"
                                    type="text"
                                    name="user"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="User"
                                          value={values.user}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="nounce" className="flex items-center gap-1">
                                    <p className="text-base">Nounce</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="nounce"
                                    type="text"
                                    name="nounce"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="nounce"
                                          value={values.nounce}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="ext" className="flex items-center gap-1">
                                    <p className="text-base">Ext</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="ext"
                                    type="text"
                                    name="ext"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="ext"
                                          value={values.ext}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="app" className="flex items-center gap-1">
                                    <p className="text-base">App</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="app"
                                    type="text"
                                    name="app"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="app"
                                          value={values.app}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="dlg" className="flex items-center gap-1">
                                    <p className="text-base">Dlg</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="dlg"
                                    type="text"
                                    name="dlg"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="1.0"
                                          value={values.dlg}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="timestamp" className="flex items-center gap-1">
                                    <p className="text-base">Timestamp</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="timestamp"
                                    type="text"
                                    name="timestamp"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="Timestamp"
                                          value={values.timestamp}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[8].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="accesskey" className="flex items-center gap-1">
                                    <p className="text-base">Access Key</p>
                                  </Label>
                                  <Field
                                    id="accesskey"
                                    type="text"
                                    name="accesskey"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.accesskey}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="secretkey" className="flex items-center gap-1">
                                    <p className="text-base">Secret Key</p>
                                  </Label>
                                  <Field
                                    id="secretkey"
                                    type="text"
                                    name="secretkey"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.secretkey}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="awsregion" className="flex items-center gap-1">
                                    <p className="text-base">AWS Region</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="awsregion"
                                    type="text"
                                    name="awsregion"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="testrealm@example.com"
                                          value={values.awsregion}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="servicename" className="flex items-center gap-1">
                                    <p className="text-base">Service Name</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="servicename"
                                    type="text"
                                    name="servicename"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="nonce"
                                          value={values.servicename}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="sessiontoken" className="flex items-center gap-1">
                                    <p className="text-base">Session Token</p>
                                  </Label>
                                  <Field name="sessiontoken" component="select">
                                    {({ input }) => (
                                      <select
                                        {...input}
                                        className="w-[70%] border border-[#747474] bg-[#161A27] rounded-md p-1 manrope-fontCss ">
                                        <option value="" disabled>
                                          Select a session token
                                        </option>
                                        {ALGORITHM_OPTIONS.map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="qop" className="flex items-center gap-1">
                                    <p className="text-base">QOP</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="qop"
                                    type="text"
                                    name="qop"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="For Example: auth-int"
                                          value={values.qop}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {authType === LOAD_TEST_AUTH_TYPE[9].value && (
                          <div className="flex flex-col gap-4">
                            <div className="text-xs text-[#A1A1AA] bg-[#9747FF]/20 rounded-lg p-4 w-full">
                              <p className="flex justify-center">
                                Note: These parameters hold sensitive data. To keep this data secure
                                while working in a collaborative environment, we recommend using
                                variables. Learn more about{' '}
                                <span className="text-[#9747FF] manrope-fontCss">Variables</span>{' '}
                                here.
                              </p>
                            </div>
                            <div className="space-y-3">
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="username" className="flex items-center gap-1">
                                    <p className="text-base">Username</p>
                                  </Label>
                                  <Field
                                    id="username"
                                    type="text"
                                    name="username"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.username}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="password" className="flex items-center gap-1">
                                    <p className="text-base">Password</p>
                                  </Label>
                                  <Field
                                    id="password"
                                    type="text"
                                    name="password"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="password"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder=" "
                                          value={values.password}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="bg-[#252C41] w-full flex flex-col rounded-xl p-2">
                                <p className="text-xl text-white ">Advanced Configrations</p>
                                <p className="text-sm text-[#A1A1AA]">
                                  Scale Secure auto-generates default values for some of these
                                  fields unless a value is specified.
                                </p>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="domain" className="flex items-center gap-1">
                                    <p className="text-base">Domain</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="domain"
                                    type="email"
                                    name="domain"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="testrealm@example.com"
                                          value={values.domain}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="grid gap-2">
                                <div className="flex flex-row gap-4 justify-between items-center">
                                  <Label htmlFor="workstation" className="flex items-center gap-1">
                                    <p className="text-base">Workstation</p>
                                    <div className="flex items-center">
                                      <QuestionMarkCircledIcon
                                        color="#616161"
                                        className=""
                                        width={19}
                                        height={19}
                                      />
                                    </div>
                                  </Label>
                                  <Field
                                    id="workstation"
                                    type="text"
                                    name="workstation"
                                    validate={composeValidators(required)}>
                                    {({ input }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className="block w-[70%] px-4 py-2 text-white bg-[#161A27] rounded-md appearance-none focus:outline-none peer border border-[#747474] h-10"
                                          placeholder="nonce"
                                          value={values.workstation}
                                        />
                                      </>

                                    )}
                                    {console.log("values sekar", values)}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  </div>



                </div>
                <div className="flex flex-row mt-4 gap-4 w-full mb-4">
                  <div className="w-1/2 justify-start items-center">
                    <Button
                      type="submit"
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium gap-1 border-[#444444] border">
                      <Plus />
                      New Test
                    </Button>
                  </div>
                  <div className="flex w-1/2 justify-end gap-4 ">
                    <Button
                      type="button"
                      onClick={() => {
                        setLoader(true)
                        form.restart();
                        form.change("authType","Select authorization type")
                        setAuthType("Select authorization type")
                        setTimeout(() => {
                          setLoader(false)
                          navigate("/load")
                        }, 1000);
                       
                      }}
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium border-[#444444] border">
                      Discard Changes
                    </Button>
                    <Button type="submit" className=" text-white h-10 w-60 text-base font-medium">
                      Validate
                    </Button>
                  </div>
                </div>
              </form>

            </>
          )}
        />
        {/* Modal to display API response */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className=" flex items-center justify-between " >
            <Typography id="modal-modal-title" variant="h6" component="h2" color="black">
              API Response
            </Typography>
            <Typography  id="modal-modal-title" variant="h6" component="h2" color="black"   onClick={()=>handleClose()}>
                 
                 <h1 className='  font-bold text-xl cursor-default text-[#9747FF]'>
                  X
                 </h1>
                  
              </Typography>
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} color="black" style={bodyStyle}>
              <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
            </Typography>
            <Button type="button" className=" text-white h-10 w-60 text-base font-medium mt-6"
              onClick={() => runTest()}>
              Run Test
            </Button>
          </Box>
        </Modal>
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </main>
  );
};


export default withLoader(withUser(LoadGraphql));
