// Asset imports
import DeleteIconSVG from "../assets/icons/delete.svg";
import DownloadIconSVG from "../assets/icons/download.svg";

export const LOCAL_STORAGE_KEYS = {
	ASYNC_TIMER_INFO: "ASYNC_TIMER_INFO",
};

export const DASHBOARD_COLUMNS = [
	{ id: "test_name", header: "API Name" },
	{ id: "status", header: "Status" },
	{ id: "test_case_count", header: "No. of Test Cases" },
	{ id: "created_by", header: "Created By" },
	{ id: "startTime", header: "Date" },
];

export const TEST_DATA = [
	{
		test_name: "Create Record - POST call",
		status: "success",
		test_case_count: 24,
		created_by: "Admin User",
		startTime: "09/21/2024",
		_markForDownloadReport: false,
		showDetails: false,
	},
	{
		test_name: "Get All Records - GET call",
		status: "started",
		test_case_count: 30,
		created_by: "Admin User",
		startTime: "09/21/2024",
		_markForDownloadReport: false,
		showDetails: false,
	},
	{
		test_name: "Delete Single Record - DELETE call",
		status: "pending",
		test_case_count: 55,
		created_by: "Admin User",
		startTime: "09/21/2024",
		_markForDownloadReport: false,
		showDetails: false,
	},
	{
		test_name: "Update Record - PUT call",
		status: "failed",
		test_case_count: 12,
		created_by: "Admin User",
		startTime: "09/21/2024",
		_markForDownloadReport: false,
		showDetails: false,
	},
];

export const SUB_TEST_DATA = [
	{ test_case_name: "With valid data", status: "success", timeTakenInMS: "300" },
	{ test_case_name: "With big data", status: "pending", timeTakenInMS: "450" },
	{ test_case_name: "With invalid data", status: "failed", timeTakenInMS: "1002" },
];

export const SUB_COLUMNS = [
	{ id: "test_case_name", header: "Test Case Name" },
	{ id: "status", header: "Status" },
	{ id: "timeTakenInMS", header: "Time Taken in MS" },
];
export const DASHBOARD_TABLE_BUTTON_OPTIONS = [
	{
		icon: DownloadIconSVG,
		alt: "Send Icon",
		text: <span className="text-base font-semibold">Download Certificate</span>,
		iconHeight: 4,
		iconWidth: 18,
		iconColor: "text-[#FFFFFF]",
		name: "Download",
		onClick: () => {},
	},
	{
		icon: DeleteIconSVG,
		alt: "",
		text: <span className="text-base font-semibold">Delete</span>,
		iconHeight: 17,
		iconWidth: 17,
		iconColor: "text-[#FFFFFF]",
		name: "Delete",
		onClick: () => {},
	},
];

export const HTTP_METHODS = [
	{ method: "get", label: "GET" },
	{ method: "post", label: "POST" },
	{ method: "delete", label: "DELETE" },
	{ method: "put", label: "PUT" },
];

export const AUTH_TYPES = [
	{ value: "noAuth", label: "No Auth" },
	{ value: "basicAuth", label: "Basic Auth" },
	{ value: "bearerToken", label: "Bearer Token" },
];

export const API_TYPES = {
	ASYNC: "ASYNC",
	SYNC: "SYNC",
};
