// Library imports
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const BackButton = (props) => {

  const { routeName } = props

  const navigate = useNavigate();

  return (
    <div>
      <button
        onClick={() => navigate(routeName)}
        className="flex items-center space-x-2 text-muted-foreground transition-colors hover:text-foreground">
        <ArrowLeft width={20} />
        <span>Go Back</span>
      </button>
    </div>
  );
};

export default BackButton;
