import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { EyeOpenIcon, EyeNoneIcon } from '@radix-ui/react-icons';
import { Button } from '../../components/ui/button';
import { Label } from '../../components/ui/label';
import { useToast } from '../../components/ui/use-toast';
import logoImage from '../../assets/images/Scale-secure-logo-white.png';
import google from '../../assets/images/Google.svg';
import microsoft from '../../assets/images/Microsoft.svg';
import {
  composeValidators,
  email,
  password,
  renderField,
  required
} from '../../helpers/form-validations';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { getUserDetailAfterGoogleLogin, getUserGoogleToken, login } from '../../utils/util';
import { isBusinessEmail } from '../../helpers/global';
import { LOGIN_TYPE } from '../../constants/index';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { payment } from '../../helpers/payment';
import right from '../../assets/images/right.png';
// import { loginRequest } from '../../authConfig';

import Backdrop from '../../components/Backdrop/backdrop';
import { generateToken } from '../../notifications/firebase';
const Login = ({ setLoader, setUser }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { instance, inProgress, accounts } = useMsal();
  const [formValid, setFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  useEffect(() => {
    if (accounts.length > 0 && inProgress === InteractionStatus.None) {
      handleMicrosoftLoginSuccess();
    }
  }, [accounts, inProgress]);

  // PLATFORM LOGIN
  const onSubmit = async (formData) => {
    if (!isBusinessEmail(formData.email)) {
      setFormErrors({ email: 'You can only register with business email!' });
      return false;
    }
    setLoader(true);

    const device_id = await generateToken();

    const loginPayload = {
      email: formData.email,
      password: formData.password,
      type: LOGIN_TYPE.PLATFORM,
      device_id: device_id
    };

    try {
      const loginRes = await login(loginPayload);
      setLoader(false);
      // Log the response to debug
      console.log('Login Response:', loginRes.data);

      if (loginRes?.data?.status!=="error") {
        setUser(loginRes.data.data);
        console.log(loginRes.data.data);
        await payment();
        navigate('/');
      } else {
        // Log error message for debugging
        console.error('Login failed:', loginRes?.data?.message);
         toast({
        variant: 'destructive',
        title:"Warning",
        description:loginRes.data.message

       })
        // Set form error to display the message from the server
        // setFormErrors({ general: loginRes?.data?.message || 'Incorrect email or password.' });
      }
    } catch (error) {
      setLoader(false);
       
      // Log error for debugging
      console.error('Error during login:', error);

      setFormErrors({ general: 'An error occurred during login. Please try again.' });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Google Login
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userDetail = await getUserGoogleToken(tokenResponse.access_token);
        console.log(tokenResponse.access_token);

        if (!isBusinessEmail(userDetail.data.email)) {
          setFormErrors({ email: 'You can only register with business email!' });
          return false;
        }
        if (!isBusinessEmail(userDetail.data.email)) {
          setFormErrors({ email: 'The Email Id is incorrect!' });
          return false;
        }
        // if (incorrectEmail(userDetail.data.email)) {
        //   error({
        //     variant: 'destructive',
        //     title: 'Error',
        //     description: 'The Email Id is incorrect!'
        //   });

        const device_id = await generateToken();

        const loginDetail = await getUserDetailAfterGoogleLogin({
          email: userDetail.data.email,
          auth_token: `Bearer ${tokenResponse.id_token}`,
          auth_provider: LOGIN_TYPE.GOOGLE,
          user_name: userDetail.data.name,
          client_name: userDetail.data.email.split('@')[1],
          type: LOGIN_TYPE.OAUTH,
          device_id: device_id
        });
        console.log(`Bearer ${tokenResponse.id_token}`);
        console.log(loginDetail);
        if (loginDetail.data.status) {
          setUser(loginDetail.data.data);
          console.log('here');
          navigate('/');
          toast({
            variant: 'success',
            title: 'Success',
            description: loginDetail.data.message
          });
        } else {
          toast({
            variant: 'destructive',
            title: 'Error',
            description: loginDetail.data.message
          });
        }
      } catch (error) {
        console.error('Error during Google login:', error);
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Failed to login with Google'
        });
      }
    },
    onError: (error) => {
      console.error('Error Google Login: ', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to login with Google'
      });
    }
  });

  // Microsoft Login
  const handleMicrosoftLogin = async () => {
    console.log('Microsoft login initiated');
    try {
      await instance.loginRedirect({});
    } catch (error) {
      console.error(error);
    }
  };

  const getUserMicrosoftDetails = async (accessToken) => {
    try {
      console.log('Fetching Microsoft user details');
      const response = await fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log(accessToken);
      if (!response.ok) {
        throw new Error('Failed to fetch user details from Microsoft Graph API');
      }

      const data = await response.json();

      return {
        email: data.mail || data.userPrincipalName,
        name: data.displayName,
        id: data.id
      };
    } catch (error) {
      console.error('Error fetching Microsoft user details:', error.message);
      // Handle the error as needed, for example by returning a default value or rethrowing the error
      return {
        email: null,
        name: null,
        id: null
      };
    }
  };

  const handleMicrosoftLoginSuccess = async () => {
    console.log('Microsoft login successful');
    const account = instance.getAllAccounts()[0];

    console.log('Acquiring token silently');
    const response = await instance.acquireTokenSilent({
      scopes: ['user.read'],
      account: account
    });

    console.log('Token acquired silently');

    const userDetail = await getUserMicrosoftDetails(response.accessToken);
    navigate('/');
    setUser({
      ...userDetail,
      token: response.accessToken
    });

    if (!isBusinessEmail(userDetail.email)) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'You can only register with business email!'
      });
      return false;
    }

    const device_id = await generateToken();

    const loginDetail = await getUserDetailAfterGoogleLogin({
      email: userDetail.email,
      access_token: response.accessToken,
      user_name: userDetail.name,
      client_name: userDetail.email.split('@')[1],
      type: LOGIN_TYPE.OAUTH,
      device_id: device_id
    });
    console.log('Microsoft login details:', loginDetail);

    if (loginDetail.data.status) {
      setUser(loginDetail.data.data);
      console.log('Navigating to dashboard');
      navigate('/');
      toast({
        variant: 'success',
        title: 'Success',
        description: loginDetail.data.message
      });
    } else {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: loginDetail.data.message
      });
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Required';
    } 
    // else if (values.password.length < 8) {
    //   errors.password = 'Password must be at least 8 characters';
    // }
    setFormValid(Object.keys(errors).length === 0);
    return errors;
  };

  return (
    <div className="w-full grid h-[100vh] grid-cols-2 sm:grid-cols-2 md:h-[100vh] lg:grid-cols-2 lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh]">
      <Backdrop />
      <Form
        onSubmit={onSubmit}
        validate={validateForm}
        render={({ handleSubmit }) => (
          <div
            className="relative flex items-center justify-center w-auto overflow-hidden h-auto"
            style={{
              backgroundImage: `url(${right})`,
              backgroundPosition: 'right',
              backgroundSize: 'cover'
            }}>
            <form
              onSubmit={handleSubmit}
              className="flex items-center justify-center w-[90%] h-[100%] flex-col">
              <div className="flex justify-center items-center">
                <img width={'219px'} height={'60px'} src={logoImage} alt="" />
              </div>
              <div
                className="flex flex-col w-full gap-7 border rounded-xl px-10 py-4 h-fit"
                style={{ backgroundColor: 'rgba(30, 34, 48,0.93)' }}>
                <div className="text-start ">
                  <h1 className="text-3xl sm:text-lg lg:text-xl xl:text-2xl font-semibold manrope-fontCss">
                    Sign In
                  </h1>
                  <p className="font-normal text-base text-muted-foreground w-full manrope-fontCss">
                    Hello there! Welcome back, Sign In to continue where you left!
                  </p>
                </div>
                <div className="grid gap-2 mt-1">
                  <div className="grid gap-1">
                    <Label htmlFor="email" className="text-base font-semibold manrope-fontCss">
                      Email <span className="text-[#EF4444]">*</span>
                    </Label>
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Enter your registered email id like abc1234@gmail.com"
                      validate={composeValidators(required, email)}
                      className="bg-[#11141F] h-9 w-full rounded-base text-base border-[#34416D] manrope-fontCss">
                      {renderField}
                    </Field>
                    {formErrors.email && (
                      <span className="text-red-500 text-xs">{formErrors.email}</span>
                    )}
                  </div>
                  <div className="relative grid gap-1">
                    <div className="flex items-center">
                      <Label htmlFor="password" className="text-base font-semibold manrope-fontCss">
                        Password <span className="text-[#EF4444]">*</span>
                      </Label>
                    </div>
                    <Field
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="********"
                      validate={composeValidators(required)}
                      className="bg-[#11141F] h-9 w-full rounded-base text-base border-[#34416D">
                      {renderField}
                    </Field>
                    {formErrors.password && (
                      <span className="text-red-500 text-xs">{formErrors.password}</span>
                    )}
                    <div
                      className="absolute h-20 inset-y-1 right-0 flex items-center px-4 cursor-pointer "
                      onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <EyeNoneIcon className="h-5 w-5" />
                      ) : (
                        <EyeOpenIcon className="h-5 w-5" />
                      )}
                    </div>
                    {formErrors.general && (
                      <span className="text-red-500 text-sm" style={{ fontSize: '12px' }}>
                        {formErrors.general}
                      </span>
                    )}
                    <div className="flex flex-row justify-between items-center space-y-4">
                      <div className="flex-[0.4]">
                        <Button
                          type="submit"
                          className={`text-white w-[50%] h-9 text-sm manrope-fontCss mt-4 ${
                            formValid ? '' : 'opacity-50'
                          }`}
                          disabled={!formValid}>
                          Sign in
                        </Button>
                      </div>
                      <div>
                        <Link
                          to="/forgot-password"
                          className="ml-auto inline-block text-base  font-medium text-[#9747FF]">
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex  items-center justify-center w-[82%] 2xl:w-[87%] mr-12 ml-12">
                    <div className="border-2 border-[#363636] rounded-xl flex-grow "></div>
                    <span className="mx-4 text-gray-400">OR</span>
                    <div className="border-2 border-[#363636] rounded-xl flex-grow"></div>
                  </div>
                  <div className="flex items-center justify-center">
                    <div className="flex space-x-4">
                      <div className="h-10 w-10 rounded-full bg-[#11141F] flex justify-center items-center">
                        <img
                          onClick={() => googleLogin()}
                          src={google}
                          alt="Google"
                          className="h-7 w-5 cursor-pointer"
                        />
                      </div>
                      <div className="h-10 w-10 rounded-full bg-[#11141F] flex justify-center items-center">
                        <img
                          onClick={handleMicrosoftLogin}
                          src={microsoft}
                          alt="Microsoft"
                          className="h-7 w-5 cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-0 text-center text-base">
                  Don&apos;t have an account?{' '}
                  <Link to="/signup" className=" text-[#9747FF]">
                    Sign Up
                  </Link>
                </div>
              </div>
            </form>
          </div>
        )}
      />
    </div>
  );
};

export default withLoader(withUser(Login));
