import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '../../lib/utils';

const Switch = React.forwardRef(({ className, value,checked, ...props }, ref) => 
 {
  const isChecked = value === true || checked === true;
  return  (
 
    <div className="flex items-center">
      <SwitchPrimitives.Root
        className={cn(
          'peer inline-flex h-6 w-12 shrink-0 cursor-pointer items-center rounded-full border-2 shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50',
          checked===true || value===true ? 'bg-[#9747FF] border-[#9747FF]' : 'bg-[#222938] border-[#444444]',
          className
        )}
        checked={checked}
        value={value}
        ref={ref}
        {...props}>
        <SwitchPrimitives.Thumb
          className={cn(
            'pointer-events-none block h-4 w-4 rounded-full bg-[#A1A1AA] shadow-lg ring-0 transition-transform',
            isChecked ? 'translate-x-6' : 'translate-x-1'
          )}
        />
      </SwitchPrimitives.Root>
    </div>
  )
 }

);
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
