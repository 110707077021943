// Library imports
import * as React from 'react';
import { cva } from 'class-variance-authority';

// Util imports
import { cn } from '../utils/helper';

const badgeVariants = cva(
  'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground shadow ',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80',
        yellow:
          'border-transparent bg-sec-started text-sec-started-foreground shadow hover:bg-sec-started-hovered',
        red: 'border-transparent bg-sec-not-started text-sec-not-started-foreground shadow hover:bg-sec-not-started-hovered',
        green:
          'border-transparent bg-sec-success text-sec-success-foreground shadow hover:bg-sec-success-hovered',
        outline: 'text-foreground'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

function Badge({ className, variant, ...props }) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
