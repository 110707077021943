import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';

import { Label } from '../../components/ui/label';
import { Card, CardFooter } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { loadTestDatabaseRelational, loadTestDatabaseNonRelational, getEnvironmentNames } from '../../utils/util';
import { useToast } from '../../components/ui/use-toast';
import { values } from 'lodash';



const LoadDatabaseForm = ({ user, setLoader }) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const [dbTech, setDbTech] = useState('relational');
  const [envNames, setEnvNames] = useState([]);
  const [formState, setFormState] = useState({
    dbtechnology: "relational",
    environment: ''
  })
  const handleDiscardChanges = (form) => {
    setLoader(true)
    // Reset specific fields
    form.change('dbuser', ''); // Reset dbuser field
    form.change('dbpassword', '');
    form.change("virtualusers","") // Reset dbpassword field
    form.change("dbtype","")
    form.change("test_name","")
    form.change("query","")
    form.change("dbname","")
    form.change("port","")
    form.change("host","")
    form.change("collection_name","")
    setTimeout(() => {
      setLoader(false)
      navigate("/load")
    }, 1000);
    // Add any other fields you want to reset here
  };

  const onSubmit = async (values) => {
    console.log(values);
    try {
      let payload;
      if (values.dbtechnology == "relational") {
        payload = {
          user_id: user?.user_id,
          enterprise_id: user?.enterprise_id,
          object_id: user?.object_id,
          subscription_id: user?.activated_subscription_id,
          environment: values?.environment || "dev",
          db_type: values.dbtype,
          db_username: values.dbuser,
          virtual_user: values.virtualusers,
          db_password: values.dbpassword,
          host: values.host,
          port: values.port,
          database_name: values.dbname,
          query: values.query,
          test_name: values.test_name,
          restart: false
        }
        setLoader(true);
        const loadTestRes = await loadTestDatabaseRelational(payload);
        setLoader(false);
        handleApiResponse(loadTestRes.data);

      } else {
        payload = {
          user_id: user?.user_id,
          enterprise_id: user?.enterprise_id,
          object_id: user?.object_id,
          subscription_id: user?.activated_subscription_id,
          environment: values?.environment || "dev",
          db_username: values.dbuser,
          db_password: values.dbpassword,
          db_host: values.dbhost,
          db_port: values.dbport,
          authSource: values.authsource,
          database_name: values.dbname,
          virtual_user: values.virtualusers,
          collection_name: values.collection_name,
          query: values.query,
          document: JSON.parse(values.document),
          testname: values.test_name,
          clientid: values.clientid,
          restart: true,
          type: "Nonrelationaldb"
        }
        setLoader(true);
        const loadTestRes = await loadTestDatabaseNonRelational(payload);
        setLoader(false);
        handleApiResponse(loadTestRes.data);
      }
    } catch (e) {
      console.log('Error', e);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Some Error Occurred. Try Again'
      });
    }
  };

  const handleApiResponse = (response) => {
    console.log(response);
    switch (response.status) {
      case 'success':
        toast({ description: response.message, variant: 'success' });
        navigate('/load')
        break;
      case 'error':
        switch (response.code) {
          case 404:
            toast({ title:"Warning",description: "You don't have any activated or valid subscription", variant: 'destructive' });
            break;
          case 400:
            toast({ description: response.message, variant: 'destructive' });
            break;
          case 500:
            toast({ description: response.message, variant: 'destructive' });
            break;
          default:
            toast({ description: 'An unknown error occurred.', variant: 'destructive' });
        }
        break;
      default:
        toast({ description: 'Some Error Occurred. Try Again', variant: 'destructive' });
    }
  };

  const handleEnvironmentChange = (newEnv) => {
    setFormState((prevState) => ({
      ...prevState,
      environment: newEnv,
    }));
  };

  useEffect(() => {
    fetchEnvNames();
  }, []);

  const fetchEnvNames = async () => {
    try {
      setLoader(true);
      const response = await getEnvironmentNames({
        user_id: user.user_id,
        enterprise_id: user.enterprise_id
      });
      setEnvNames(response.data.data.Environment_list.filter(i => i))
      setLoader(false);

    } catch (error) {
      setEnvNames([])
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch environment names'
      });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={
        formState
      }
      keepDirtyOnReinitialize
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit,form }) => (
        <form
          onSubmit={handleSubmit}
          className="grid flex-1 items-start gap-4 p-4 sm:py-0 md:gap-8 w-full h-full bg-[#11141F]"
        >
          <div className="grid auto-rows-max items-start gap-4 md:gap-6 lg:col-span-2 manrope-fontCss">

            {envNames.length > 0 && (
              <div className="flex w-full pt-2 gap-2">
                <div className="flex-1 gap-4 font-semibold text-lg">
                  <label htmlFor="httpMethods" className="text-base">
                    Select Environment
                  </label>
                  <Field
                    name="environment"
                    component="select"
                    onChange={(event) => handleEnvironmentChange(event.target.value)}
                    className="ml-4 bg-gray-700 border border-gray-600 text-white p-1 rounded-md flex-1"
                    required
                    >
                    <option value="">Choose Environment</option>
                    {envNames.map((envObj) => (
                      <option key={envObj} value={envObj}>
                        {envObj}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            )}
            <div className="flex flex-row w-full gap-6 text-lg">
              <div className="w-1/2 space-y-2">
                <Label htmlFor="dbtechnology" className="mb-2 text-white text-lg font-semibold">
                  Database Technology
                </Label>
                <Field
                  name="dbtechnology"
                  render={({ input }) => (
                    <select
                      {...input}
                      className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                      onChange={(e) => {
                        input.onChange(e); // Update value in the form
                        setDbTech(e.target.value); // Update the state
                      }}>
                      <option value="relational">Relational</option>
                      <option value="non_relational">Non Relational DB</option>
                    </select>
                  )}
                />
              </div>
              <div className="w-1/2 space-y-2">
                <Label htmlFor="test_name" className="mb-2 text-white text-lg font-semibold">
                  Test Name
                </Label>
                <Field
                  name="test_name"
                  render={({ input }) => (
                    <input
                      {...input}
                      className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                      placeholder="Enter Test Name"
                    />
                  )}
                />
              </div>

            </div>

            {/* Conditional fields for Relational DB */}
            {dbTech === 'relational' && (
              <>
                <div className="flex flex-row w-full gap-6 text-lg mt-2">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbtype" className="mb-4 text-white text-lg font-semibold">
                      Database Type
                    </Label>
                    <Field
                      name="dbtype"
                      render={({ input }) => (
                        <select
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none">
                          <option value="">Select Database Type</option>
                          <option value="mysql">MySQL</option>
                          <option value="postgres">PostgreSQL</option>
                        </select>
                      )}
                    />
                  </div>

                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbuser" className="mb-2 text-white text-lg font-semibold">
                      Database User
                    </Label>
                    <Field
                      name="dbuser"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          type="text"
                          placeholder="Enter Database User here"
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbpassword" className="mb-2 text-white text-lg font-semibold">
                      Database Password
                    </Label>
                    <Field
                      name="dbpassword"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          type="password"
                          placeholder="Enter DB Password here"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="host" className="mb-2 text-white text-lg font-semibold">
                      Host
                    </Label>
                    <Field
                      name="host"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          type="text"
                          placeholder="Enter Host"
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="port" className="mb-2 text-white text-lg font-semibold">
                      Port
                    </Label>
                    <Field
                      name="port"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          type="text"
                          placeholder="Enter Port"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbname" className="mb-2 text-white text-lg font-semibold">
                      Database Name
                    </Label>
                    <Field
                      name="dbname"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Database Name"
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="virtualusers" className="mb-2 text-white text-lg font-semibold">
                      Virtual Users
                    </Label>
                    <Field
                      name="virtualusers"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Virtual Users"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-full space-y-2">
                    <Label htmlFor="query" className="mb-2 text-white text-lg font-semibold">
                      Query
                    </Label>
                    <Field
                      name="query"
                      render={({ input }) => (
                        <textarea
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Query"
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}

            {/* Conditional fields for Non-Relational DB */}
            {dbTech === 'non_relational' && (
              <>
                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbuser" className="mb-2 text-white text-lg font-semibold">
                      Database User
                    </Label>
                    <Field
                      name="dbuser"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Database User"
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbpassword" className="mb-2 text-white text-lg font-semibold">
                      Database Password
                    </Label>
                    <Field
                      name="dbpassword"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter DB Password"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbhost" className="mb-2 text-white text-lg font-semibold">
                      Database Host
                    </Label>
                    <Field
                      name="dbhost"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Host"
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbport" className="mb-2 text-white text-lg font-semibold">
                      Database Port
                    </Label>
                    <Field
                      name="dbport"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Port"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="authsource" className="mb-2 text-white text-lg font-semibold">
                      Auth Source
                    </Label>
                    <Field
                      name="authsource"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Auth Source"
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="dbname" className="mb-2 text-white text-lg font-semibold">
                      Database Name
                    </Label>
                    <Field
                      name="dbname"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Database Name"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="virtualusers" className="mb-2 text-white text-lg font-semibold">
                      Virtual Users
                    </Label>
                    <Field
                      name="virtualusers"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Virtual Users"
                        />
                      )}
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="collection_name" className="mb-2 text-white text-lg font-semibold">
                      Collection Name
                    </Label>
                    <Field
                      name="collection_name"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Collection Name"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="query" className="mb-2 text-white text-lg font-semibold">
                      Query
                    </Label>
                    <Field
                      name="query"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Query"
                        />
                      )}
                    />
                  </div>

                  <div className="w-1/2 space-y-2">
                    <Label htmlFor="clientid" className="mb-2 text-white text-lg font-semibold">
                      Client ID
                    </Label>
                    <Field
                      name="clientid"
                      render={({ input }) => (
                        <input
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Client ID"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex flex-row w-full gap-6 text-lg">
                  <div className="w-full space-y-2">
                    <Label htmlFor="document" className="mb-2 text-white text-lg font-semibold">
                      Document
                    </Label>
                    <Field
                      name="document"
                      render={({ input }) => (
                        <textarea
                          {...input}
                          className="w-full bg-[#11141F] border border-[#747474] text-white px-4 py-2 rounded-lg focus:outline-none"
                          placeholder="Enter Document"
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}

            {/* Submit buttons */}
            <Card className="mt-1 bg-[#11141F] border-[#747474] p-0">
              <CardFooter className="flex justify-end gap-4">
              <Button
        type="button"  // Set to "button" so it doesn't submit the form
        onClick={() =>{handleDiscardChanges(form)}  }// Reset the form to its initial state
        className="bg-[#222938] text-white h-10 w-60 text-base font-medium border-[#444444] border">
        Discard Changes
      </Button>
                <Button type="submit" className=" text-white h-10 w-60 text-base font-medium">
                  Validate
                </Button>
              </CardFooter>
            </Card>
          </div>
        </form>
      )}
    />
  );
};

export default withLoader(withUser(LoadDatabaseForm));
