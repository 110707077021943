import axios from "axios";

const instance = axios.create({
  baseURL: 'https://dev-api.scalesecure.io/tst/api/'
});

axios.interceptors.request.use(function (config) {
  config.headers = {
    ...config.headers,
    "Content-Type": "application/json"
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});


const getTestRecords = (params) => {
  return instance.get("tests", {
    params
  })
}

const runTests = (data) => {
  return instance.post("verify", data)
}

const saveAsyncTest = (apiTestId, data) => {
  return instance.patch(`save-async-workflow/${apiTestId}`, data)
}

const getTestById = (testId) => {
  return instance.get(`test/${testId}`)
}

export {
  getTestRecords,
  runTests,
  saveAsyncTest,
  getTestById
}