import { Rect, Svg } from '@react-pdf/renderer';
import React from 'react';

const RiskImage = ({ risk }) => {
  // Define colors for each risk level
  const colors = {
    1: ['#4683b5'], // Blue
    2: ['#facd15', '#facd15'], // Yellow
    3: ['#fb6961', '#fb6961', '#fb6961'], // Orange
    // 4: ['#d10000', '#d10000', '#d10000', '#d10000'] // Red
  };

  // Default color for bars that are not active
  const defaultColor = '#ebedf0';

  // Get the color array based on the risk level
  const riskColors = colors[risk] || [];

  return (
    <Svg viewBox="0 0 22 10">
      <Rect
        x="0"
        rx="0.1"
        ry="0.1"
        width="0.15"
        height="0.75"
        fill={riskColors[0] || defaultColor}
      />
      <Rect
        x="0.25"
        rx="0.1"
        ry="0.1"
        width="0.15"
        height="0.75"
        fill={riskColors[1] || defaultColor}
      />
      <Rect
        x="0.5"
        rx="0.1"
        ry="0.1"
        width="0.15"
        height="0.75"
        fill={riskColors[2] || defaultColor}
      />
      {/* <Rect
        x="0.75"
        rx="0.1"
        ry="0.1"
        width="0.15"
        height="0.75"
        fill={riskColors[3] || defaultColor}
      /> */}
    </Svg>
  );
};

export default RiskImage;
