// Library imports
import { ChevronDown, ChevronUp, Download, Ellipsis, Filter, Search, SlidersHorizontal, SquareChevronLeft, SquareChevronRight, Zap } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

// Component imports
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/Accordion";
import { Badge } from "../components/Badge";
import { Button } from "../components/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/Card";
import ComponentLoader from "../components/ComponentLoader";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "../components/DropdownMenu";
import { Checkbox } from "../components/form/Checkbox";
import { Input } from "../components/form/Input";
import SettingsDropdown from "../components/Settings";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/Table";
import { useToast } from "../components/UseToast";

// Util imports
import { deepClone, getStatusColor, getStatusColorForDot } from "../utils/helper";

// Constants imports
import { API_TYPES, DASHBOARD_COLUMNS, SUB_COLUMNS } from "../utils/constants";

// API imports
import { getTestRecords } from "../api/app";

const Dashboard = () => {
	// Hooks
	const navigate = useNavigate();
	const { toast } = useToast();

	useEffect(() => {
		init();
	}, []);

	// States
	const [loading, setLoading] = useState(false);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [checkAllRecords, setCheckAllRecords] = useState(false);
	const [listOfRecordsToDownload, setListOfRecordsToDownload] = useState([]);
	const [apiData, setAPIData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [pageParams, setPageParams] = useState({
		page: 1,
		pageSize: 10,
		sortField: "timestamp",
		sortOrder: "desc",
	});

	// Methods
	const init = () => {
		loadAllData(pageParams);
	};

	const loadAllData = async (params) => {
		setLoading(true);
		try {
			const response = await getTestRecords(params);
			if (response && response.data?.documents) {
				const formattedData = response.data.documents.map((record, index) => {

					let testCount = record?.data?.sync?.workflow?.tests ?
					Object.keys(record?.data?.sync?.workflow?.tests).length : 0

					let tests = []
					let syncTestCount = 0
					if (record?.data?.sync?.workflow?.tests) {
						for (const [key, value] of Object.entries(record?.data?.sync?.workflow?.tests)) {
							const result = record?.data?.sync?.result?.tests?.[syncTestCount]
							tests.push({
								test_case_name: value?.steps[0]?.name || value.id,
								status: result?.passed ? "success" : "failed",
								timeTakenInMS: result?.duration,
							})
							syncTestCount = syncTestCount + 1
						}
					}
					let newRecord = {
						_id: record.id,
						_markForDownloadReport: false,
						showDetails: false,
						test_name: record?.data?.sync?.workflow?.name,
						status: record?.data?.sync?.result?.passed ? "success" : "failed",
						test_case_count: testCount,
						created_by: "Admin User",
						startTime: record.timestamp,
						tests: deepClone(tests),
						apiType: API_TYPES.SYNC
					};
					if (record?.data?.async && typeof record.data.async === 'object' && Object.keys(record.data.async).length > 0) {

						newRecord.apiType = API_TYPES.ASYNC

						let asyncTestCount = record?.data?.async?.workflow?.tests ?
						Object.keys(record?.data?.async?.workflow?.tests).length : 0

						newRecord.status = record?.data?.async?.result === null ? "pending" :
						record?.data?.async?.result?.passed === true ? "success" : "failed"

						newRecord.test_case_count = newRecord.test_case_count + asyncTestCount
						if (record?.data?.async?.workflow?.tests) {
							let asyncCount = 0
							for (const [key, value] of Object.entries(record?.data?.async?.workflow?.tests)) {
								const result = record?.data?.async?.result?.tests?.[asyncCount]
								newRecord.tests.push({
									test_case_name: value?.steps[0]?.name || value.id,
									status: result?.passed ? "success" : "failed",
									timeTakenInMS: result?.duration,
								})
								asyncCount = asyncCount + 1
							}
						}
					}
					return newRecord;
				});
				setAPIData(formattedData);
			}
			if (response?.data?.total) {
				setTotalPages(Math.ceil(response.data.total / pageParams.pageSize));
			}
		} catch (error) {
			console.log("Error while fetching the data: ", error);
		} finally {
			setLoading(false);
		}
	};

	const handleSearch = (event) => {
		setSearchKeyword(event.target.value);
	};

	const openTestForm = (type) => {
		navigate(`/test/create/${type}`);
	};

	const handleCheckAllRecordsChange = (value) => {
		setCheckAllRecords(value);
		let arr = apiData.map((d) => {
			return {
				...d,
				_markForDownloadReport: value,
			};
		});
		setAPIData(arr);
		if (value) {
			setListOfRecordsToDownload(arr.map((d) => d.test_name));
		} else {
			setListOfRecordsToDownload([]);
		}
	};

	const handleSingleCheckboxClick = (value, index) => {
		const arr = deepClone(apiData);
		arr[index]._markForDownloadReport = value;
		const records = deepClone(listOfRecordsToDownload);
		let foundIndex = -1;
		if (!value) {
			foundIndex = records.findIndex((r) => r === arr[index].test_name);
			if (foundIndex >= 0) {
				records.splice(foundIndex, 1);
			}
		} else {
			records.push(arr[index].test_name);
		}
		setAPIData(arr);
		setListOfRecordsToDownload(records);
	};

	const toggleRow = (index) => {
		const arr = deepClone(apiData);
		arr[index].showDetails = !arr[index].showDetails;
		setAPIData(arr);
	};

	const downloadReports = () => {
		if (listOfRecordsToDownload.length === 0) {
			toast({
				variant: "destructive",
				title: "Error",
				description: "Please select at least one API to download the report",
			});
		}
	};

	const handleFilterChange = (columnId, value, checked) => {};

	const handlePageClick = (page) => {
		const params = {
			page: page.selected + 1,
			pageSize: 10,
			sortField: "timestamp",
			sortOrder: "desc",
		};
		setPageParams({ ...params });
		loadAllData(params);
	};

	const openAPIDetailPage = (id, type) => {
		navigate(`/test/view/${id}/${type}`);
	}

	// UI Rendering
	return (
		<main className="gap-4 p-4 mt-8 sm:py-0 md:gap-8 w-full">
			<div className="mb-4 flex justify-end items-center">
				<SettingsDropdown />
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button size="lg" variant="default" className="h-8 gap-1 text-sm">
							<Zap className="h-3.5 w-3.5" fill="white" stroke="white" />
							<span className="sr-only sm:not-sr-only text-white">Start Test</span>
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end">
						<div>
							<DropdownMenuItem>
								<Button variant="none" className="flex items-center px-2 gap-4 h-6 text-sm" onClick={() => openTestForm("SYNC")}>
									<span>Sync</span>
								</Button>
							</DropdownMenuItem>
							<DropdownMenuSeparator />
							<DropdownMenuItem>
								<Button variant="none" className="flex items-center px-2 gap-4 h-6 text-sm" onClick={() => openTestForm("ASYNC")}>
									<span>Async</span>
								</Button>
							</DropdownMenuItem>
						</div>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			<Card x-chunk="dashboard-05-chunk-3">
				<CardHeader className="px-7 justify-between items-center">
					<CardTitle>APIs</CardTitle>
					<div className="flex items-center gap-4">
						<div className="relative flex-1">
							<Search className="absolute left-1.5 top-1.5 h-6 w-6 text-muted-foreground" />
							<Input
								type="search"
								placeholder="Search..."
								className="w-full bg-transparent pl-8 md:w-[200px] lg:w-[320px] h-8 border-0 border-b border-muted-foreground focus:ring-0 focus:border-purple-500"
								value={searchKeyword}
								onChange={handleSearch}
							/>
						</div>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="ghost"
									size="sm"
									className="h-10 w-[130px] bg-[#222938] hover:bg-[#222938] hover:text-[#FFFFFF] flex items-center gap-2 border-[#444444] border-2">
									<Filter className="h-4 w-4" />
									<span className="sr-only sm:not-sr-only">More Filter</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end" className="w-50">
								<div className="flex flex-col">
									<div className="flex justify-between items-center">
										<SlidersHorizontal className="h-5 w-5 text-muted-foreground" />
										<span className="font-semibold">Filters</span>
									</div>
									{DASHBOARD_COLUMNS.map((column) => (
										<Accordion key={column.id} type="single" collapsible>
											<AccordionItem value={column.id}>
												<AccordionTrigger>{column.header}</AccordionTrigger>
												<AccordionContent>
													{/* Implement your filter options here */}
													<label className="flex items-center">
														<input
															type="checkbox"
															className="form-checkbox"
															onChange={(e) => handleFilterChange(column.id, "someValue", e.target.checked)}
														/>
														<span className="ml-2 text-muted-foreground">Filter Option</span>
													</label>
												</AccordionContent>
											</AccordionItem>
										</Accordion>
									))}
								</div>
							</DropdownMenuContent>
						</DropdownMenu>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="ghost"
									className="h-10 w-[117px] bg-[#222938] hover:bg-[#222938] hover:text-[#FFFFFF] flex items-center gap-2 border-[#444444] border-2">
									<SlidersHorizontal className="h-4 w-4" strokeWidth={1.5} />
									<span className="sr-only sm:not-sr-only">Sort By</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								{DASHBOARD_COLUMNS.map((option, index) => (
									<div key={index}>
										<DropdownMenuItem>
											<Button variant="none" className="flex items-center px-2 gap-4 h-6 text-sm" onClick={() => {}}>
												{/* <img
                          src={option.icon}
                          alt={option.alt}
                          className={`h-${option.iconHeight} w-${option.iconWidth} ${option.iconColor}`}
                        /> */}
												<span>{option.header}</span>
											</Button>
										</DropdownMenuItem>
										{index < DASHBOARD_COLUMNS.length - 1 && <DropdownMenuSeparator />}
									</div>
								))}
							</DropdownMenuContent>
						</DropdownMenu>
						<Button
							onClick={downloadReports}
							variant="ghost"
							className="h-10 w-[117px] bg-[#222938] hover:bg-[#222938] hover:text-[#FFFFFF] flex items-center gap-2 border-[#444444] border-2">
							<Download className="h-4 w-4" strokeWidth={1.5} />
							<span className="sr-only sm:not-sr-only">Report</span>
						</Button>
					</div>
				</CardHeader>
				<CardContent>
					<Table>
						<TableHeader className="bg-[#11141F] rounded-lg">
							<TableRow className="border-b border-[#333333]">
								<TableCell>
									<Checkbox checked={checkAllRecords} onCheckedChange={handleCheckAllRecordsChange} />
								</TableCell>
								{DASHBOARD_COLUMNS.map((column) => (
									<TableHead
										key={column.id}
										className={`text-white text-muted-foreground text-base font-medium p-4 ${column.id === "test_name" ? "text-left" : "text-center"}`}>
										{column.header}
									</TableHead>
								))}
								<TableHead></TableHead>
							</TableRow>
						</TableHeader>
						<TableBody className="bg-[#11141F]">
							{loading && (
								<TableRow>
									<TableCell colSpan="7">
										<ComponentLoader />
									</TableCell>
								</TableRow>
							)}
							{!loading &&
								apiData.length > 0 &&
								apiData.map((item, index) => (
									<React.Fragment key={index}>
										<TableRow key={index} className={`${item.showDetails ? "" : "border-b"} border-[#333333]`}>
											<TableCell>
												<Checkbox checked={item._markForDownloadReport} onCheckedChange={(value) => handleSingleCheckboxClick(value, index)} />
											</TableCell>
											{DASHBOARD_COLUMNS.map((column) => (
												<TableCell key={column.id} className={` ${column.id === "test_name" ? "text-left" : "text-center"}`}>
													{column.id === "testDetails" ? (
														<div className="cursor-pointer" onClick={() => {}}>
															<div className="text-lg pl-2 font-semibold text-white">{item.test_name}</div>
															<div className="text-[#9747FF] pl-2 text-xs font-medium">{item.url}</div>
														</div>
													) : column.id === "type" ? (
														<div className="flex ">
															<div className="text-md text-white font-medium">{item.type}</div>
														</div>
													) : column.id === "status" ? (
														<Badge className={`${getStatusColor(item.status)} text-base font-semibold w-32 h-8 inline-flex items-start justify-start`}>
															<div className="flex flex-row justify-center items-center">
																<div className={`${getStatusColorForDot(item.status)} text-xs w-2 h-2 rounded-full mr-2 items-start `} />
																{item.status.toLowerCase() === "sTarted".toLowerCase() ? "in progress" : item.status}
															</div>
														</Badge>
													) : column.id === "startTime" ? (
														<div className="">
															<div className="flex flex-col items-center">
																<div className="text-xs text-white font-medium">{moment(item.startTime).format("MM/DD/YYYY")}</div>
																<div className="text-xs font-medium text-muted-foreground">{moment(item.startTime).format("hh:mm:ss A")}</div>
															</div>
														</div>
													) : column.id === "test_name" ?(
														<div className="text-xs font-medium text-white">
															<Button onClick={() => openAPIDetailPage(item._id, item.apiType)} variant="link" className="text-white">{item[column.id]}</Button>
														</div>
													) : <div className="text-xs font-medium text-white">{item[column.id]}</div>
													}
												</TableCell>
											))}
											<TableCell onClick={() => toggleRow(index)}>
												{item.showDetails ? (
													<ChevronUp className="h-4 w-4 inline" strokeWidth={1.5} />
												) : (
													<ChevronDown className="h-4 w-4 inline" strokeWidth={1.5} />
												)}
											</TableCell>
										</TableRow>
										{item.showDetails && (
											<TableRow>
												<TableCell colSpan="7" className="">
													<Table className="w-11/12 ml-auto mr-auto">
														<TableHeader className="bg-[#11141F] rounded-lg text-sm">
															<TableRow className="border-b border-[#333333]">
																{SUB_COLUMNS.map((column) => (
																	<TableHead
																		key={column.id}
																		className={`text-xs text-white text-muted-foreground text-base font-medium p-4 ${
																			column.id === "test_case_name" ? "text-left text-xs" : "text-center text-xs"
																		}`}>
																		{column.header}
																	</TableHead>
																))}
															</TableRow>
														</TableHeader>
														<TableBody>
															{item.tests.length &&
																item.tests.map((subRowData, subIndex) => (
																	<TableRow
																		key={index + "-" + subIndex}
																		className={`${subRowData.showDetails ? "" : "border-b"} border-[#333333]`}>
																		{SUB_COLUMNS.map((column) => (
																			<TableCell
																				key={column.id}
																				className={` ${column.id === "test_case_name" ? "text-left" : "text-center"}`}>
																				{column.id === "testDetails" ? (
																					<div className="cursor-pointer" onClick={() => {}}>
																						<div className="text-lg pl-2 font-semibold text-white">{subRowData.test_case_name}</div>
																						<div className="text-[#9747FF] pl-2 text-xs font-medium">{subRowData.url}</div>
																					</div>
																				) : column.id === "type" ? (
																					<div className="flex ">
																						<div className="text-md text-white font-medium">{subRowData.type}</div>
																					</div>
																				) : column.id === "status" ? (
																					<Badge
																						className={`${getStatusColor(subRowData.status)} text-sm font-semibold w-28 h-7 inline-flex items-start justify-start`}>
																						<div className="flex flex-row justify-center items-center">
																							<div
																								className={`${getStatusColorForDot(subRowData.status)} text-xs w-2 h-2 rounded-full mr-2 items-start `}
																							/>
																							{subRowData.status.toLowerCase() === "started".toLowerCase()
																								? "in progress"
																								: subRowData.status}
																						</div>
																					</Badge>
																				) : column.id === "startTime" ? (
																					<div className="">
																						<div className="flex flex-col items-center">
																							<div className="text-xs text-white font-medium">
																								{moment(subRowData.created_at).format("MM/DD/YYYY")}
																							</div>
																							<div className="text-xs font-medium text-muted-foreground">
																								{moment(subRowData.created_at).format("hh:mm:ss A")}
																							</div>
																						</div>
																					</div>
																				) : (
																					<div className="text-xs font-medium text-white">{subRowData[column.id]}</div>
																				)}
																			</TableCell>
																		))}
																	</TableRow>
																))}
														</TableBody>
													</Table>
												</TableCell>
											</TableRow>
										)}
									</React.Fragment>
								))}
							{!loading && apiData.length === 0 && (
								<TableRow>
									<TableCell colSpan="7">
										<p>No records found.</p>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
					<ReactPaginate
						breakLabel={<Ellipsis />}
						nextLabel={<SquareChevronRight className="h-9 w-10" />}
						onPageChange={handlePageClick}
						pageRangeDisplayed={3}
						pageCount={totalPages}
						previousLabel={<SquareChevronLeft className="h-9 w-10" />}
						renderOnZeroPageCount={null}
						containerClassName="flex items-center justify-center mt-4"
						activeClassName="bg-white text-black"
						previousClassName="mr-3"
						nextClassName="ml-1"
						breakClassName="mr-2"
						pageClassName="mr-2 border-2 border-current p-1 rounded-lg h-8 w-8 flex items-center justify-center"
					/>
				</CardContent>
			</Card>
		</main>
	);
};

export default Dashboard;
