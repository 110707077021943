import React, { useEffect, useState } from 'react';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';
import { Field, Form } from 'react-final-form';
import { DownloadIcon } from '../../assets/icons';
import RecommendationIcon from '../../assets/icons/recommendation-icon';
import { Tabs, TabsList, TabsTrigger } from '../../components/ui/tabs';
import CloseIcon from '../../assets/icons/close';
import withUser from '../../redux/Hoc/withUser';
import { useParams } from 'react-router-dom';
import { getSecurityTestDetails } from '../../utils/util';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { useToast } from '../../components/ui/use-toast';
import SecurityResultPdf from '../../components/PDF/SecurityResultPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import withLoader from '../../redux/Hoc/withLoader';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { CopyIcon, CheckIcon } from '@radix-ui/react-icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

// const alerts = [
//   {
//     id: 1,
//     risk: 'Low',
//     title: 'Server Leaks Version Information via "Server" HTTP Response Header Field'
//   },
//   { id: 2, risk: 'Medium', title: 'Another Medium Risk Alert' },
//   { id: 3, risk: 'High', title: 'High Risk Alert Example' }
//   // ... more alerts
// ];
const risk = [
  { id: '1', value: 'Low' },
  { id: '2', value: 'Medium' },
  { id: '3', value: 'High' }
];

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <button
      onClick={handleCopy}
      className="absolute h-8 w-8 top-2 right-2 flex items-center justify-center text-white bg-gray-600 rounded hover:bg-gray-700 focus:outline-none">
      {copied ? <CheckIcon /> : <CopyIcon />}
    </button>
  );
};

const CodeBlock = ({ children, language = 'javascript' }) => (
  <div className="relative rounded-md my-4">
    <SyntaxHighlighter language={language} style={tomorrow} className="rounded-md overflow-auto">
      {String(children).trim()}
    </SyntaxHighlighter>
    <CopyButton text={children} />
  </div>
);

const renderContent = (aiData) => {
  return (
    <div className="w-full">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
        components={{
          h1: ({ node, ...props }) => (
            <h1 className="text-2xl font-bold my-4 text-white" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 className="text-xl font-semibold my-4 text-white" {...props} />
          ),
          p: ({ node, ...props }) => <p className="text-base my-4 text-white" {...props} />,
          ul: ({ node, ...props }) => <ul className="list-disc pl-5 my-4 text-white" {...props} />,
          ol: ({ node, ...props }) => (
            <ol className="list-decimal pl-5 my-4 text-white" {...props} />
          ),
          li: ({ node, ...props }) => <li className="my-1 text-white" {...props} />,
          table: ({ node, ...props }) => (
            <table
              className="table-auto w-full my-6 border-collapse border border-gray-600"
              {...props}
            />
          ),
          th: ({ node, ...props }) => (
            <th
              className="font-semibold px-4 py-2 border border-gray-600 bg-gray-700 text-left text-white"
              {...props}
            />
          ),
          td: ({ node, ...props }) => (
            <td className="border px-4 py-2 bg-gray-800 text-white" {...props} />
          ),
          tr: ({ node, ...props }) => <tr className="border border-gray-600" {...props} />,
          blockquote: ({ node, ...props }) => (
            <blockquote
              className="italic pl-4 border-l-4 border-gray-600 my-4 text-white"
              {...props}
            />
          ),
          code: ({ node, inline, className, children, ...props }) => {
            return !inline ? (
              <CodeBlock>{children}</CodeBlock>
            ) : (
              <code className="bg-gray-800 text-white rounded px-2 py-1" {...props}>
                {children}
              </code>
            );
          }
        }}>
        {aiData}
      </ReactMarkdown>
    </div>
  );
};

function AlertCard({ alert, expanded, onToggleExpand, user, test_name, test_id, loader, type }) {
  const [showRecommendation, setShowRecommendation] = useState(!!alert.model_solution);
  const [aiData, setAiData] = useState(alert.model_solution || 'Generating solution.....');
  const { toast } = useToast();

  const toggleRecommendation = async () => {
    console.log("type type",type)
    if (!alert.model_solution) {
      setShowRecommendation(!showRecommendation);
      loader(true);
      try {
        const payload = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id ? user.enterprise_id : null,
          test_name: test_name,
          test_id: test_id,
          alert: alert.alert,
          object_id: user.object_id,
          subscription_id: user.activated_subscription_id,
          type: type
        };

        const response = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT}/securityTestSolution`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.access_token}`
            },
            body: JSON.stringify(payload)
          }
        );

        loader(false);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let content = '';
        if (!response.ok) {
          const { value } = await reader.read();
          content += decoder.decode(value, { stream: true });
          console.log(content);

          content = JSON.parse(content);
          setAiData(content.message);
          throw new Error(content.message);
        }

        // Read the streaming response
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          content += decoder.decode(value, { stream: true });
          console.log(content);

          setAiData(content);
        }
      } catch (error) {
        setShowRecommendation(!!showRecommendation);
        loader(false);
        console.log(error);
        toast({
          variant: 'destructive',
          title: 'Error',
          description: error.message
        });
      }
    } else {
      setShowRecommendation(!showRecommendation);
    }
  };

  useEffect(() => {
    console.log('alert card alert of api scan ', alert.riskcode, type);
  });

  const toggleRecommendationClose = () => {
    onToggleExpand(); // Collapse the card when the recommendation is closed
  };

  return (
    <Card className="w-full p-4 overflow-x-hidden">
      <div className="flex flex-row justify-between items-center space-x-4">
        {!expanded && (
          <div className="flex flex-col">
            <p className="font-bold text-lg">Alert: {alert.alert}</p>
            <p className="text-[#868686] text-md">
              <span className="font-bold text-white">Risk: </span>
              {type !== 'security-api-scan'
                ? alert.risk
                : risk.find((r) => r.id === alert.riskcode)?.value || 'Unknown'}
            </p>
          </div>
        )}

        {!expanded && (
          <div>
            <Button
              variant="none"
              className="text-md h-10 text-primary flex items-center justify-between"
              onClick={onToggleExpand}>
              {expanded ? 'Less Details' : 'More Details'}
            </Button>
          </div>
        )}
      </div>
      {expanded && (
        <div className={`grid ${showRecommendation ? 'grid-cols-2' : 'grid-cols-1'} gap-4`}>
          <Card className="bg-card p-4 h-[550px] overflow-auto">
            {!showRecommendation && (
              <>
                <div className=" w-full flex  flex-row justify-end ">
                  <Button variant="ghost" onClick={toggleRecommendationClose}>
                    <CloseIcon />
                  </Button>
                </div>

                <div className=" w-full bg-muted/40 h-0.5 mb-4 "></div>
              </>
            )}
            <div className="flex justify-between space-x-4">
              <div className="flex flex-col">
                <p className="font-black text-2xl">Alert: {alert.alert}</p>
              </div>
              {!showRecommendation && (
                <Button
                  className="bg-[#9747FF] hover:bg-[#9747FF] text-lg h-10 text-white flex space-x-2 items-center justify-between"
                  onClick={toggleRecommendation}>
                  <RecommendationIcon className="w-6 h-6" />
                  <span className="sr-only sm:not-sr-only text-sm font-light">
                    AI Recommendation
                  </span>
                </Button>
              )}
            </div>
            <div className="space-y-3 pt-3">
              <div>
                <p className="text-[#868686] text-md">
                  <span className="font-bold text-white">Description: </span>
                  {type !== 'security-api-scan'
                    ? alert.description
                    : (() => {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(alert.desc, 'text/html');
                        const pTag = doc.querySelector('p');
                        return pTag ? pTag.innerText : '';
                      })()}
                </p>
                <p className="text-[#868686] pt-3 text-md">
                  <span className="font-bold text-white">Risk:</span>{' '}
                  {type !== 'security-api-scan'
                    ? alert.risk
                    : risk.find((r) => r.id === alert.riskcode)?.value || 'Unknown'}
                </p>
              </div>
              {/* <div className="flex flex-col">
                <span className="font-bold text-white">Solution:</span>
                <div className="space-y-5">
                  <ul style={{ listStyleType: 'disc' }} className="ml-6 text-[#868686] text-md">
                    <li>{alert.solution}</li>
                  </ul>
                </div>
              </div> */}

              <div className="space-y-3">
                <div>
                  <p className="text-[#868686] text-md">
                    {type === 'security-api-scan' && alert.otherinfo === '' ? (
                      ' N/A'
                    ) : (
                      <>
                        <span className="font-bold text-white">Findings: </span>
                        {type !== 'security-api-scan'
                          ? alert?.other === ''
                            ? ' N/A'
                            : alert.other
                          : (() => {
                              const parser = new DOMParser();
                              const doc = parser.parseFromString(alert.otherinfo, 'text/html');
                              const pTag = doc.querySelector('p');
                              return pTag ? pTag.innerText : '';
                            })()}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Card>
          {showRecommendation && (
            <Card className="bg-card h-[550px] overflow-auto p-4">
              <div className="flex justify-between">
                <div className="flex gap-4 mt-2">
                  <RecommendationIcon className="w-2 h-2 text-primary" />
                  <span className="sr-only sm:not-sr-only text-sm font-light text-primary">
                    {alert.model_solution ? 'Model Solution' : 'Generating AI Recommendations...'}
                  </span>
                </div>
                <Button variant="ghost" onClick={toggleRecommendationClose}>
                  <CloseIcon />
                </Button>
              </div>
              <h3 className="font-bold text-lg mb-4">AI Recommendation</h3>
              <div className="text-md max-w-full">{aiData && renderContent(aiData)}</div>
            </Card>
          )}
        </div>
      )}
    </Card>
  );
}
const SecurityResult = ({ user, setLoader }) => {
  const { id, name } = useParams();

  const [data, setData] = useState();
  const [alerts, setAlerts] = useState();
  const [scanType, setScanType] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    try {
      const payload = {
        user_id: user.user_id,
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        object_id: user.object_id,
        test_name: name,
        test_id: id,
        subscription_id: user.activated_subscription_id
      };
      const response = await getSecurityTestDetails(payload);
      setData(response.data.data);

      console.log('data summary reports', data);
      setAlerts(
        response.data.data.type !== 'security-api-scan'
          ? response.data.data.security_alert_data
          : response.data.data.security_alert_data
      );
      setLoader(false);
      setScanType(response.data.data.type);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log('data summary reports', data);
  });

  const riskOrder = {
    High: 1,
    Medium: 2,
    Low: 3
  };

  const riskOrderForApiScan = {
    3: 1,
    2: 2,
    1: 3
  };

  const [selectedRisk, setSelectedRisk] = useState('all');
  const [expandedAlertId, setExpandedAlertId] = useState(null);

  // Filter alerts based on type
  const filteredAlerts = alerts
    ?.filter((alert) => alert.risk !== 'Informational')
    .filter((alert) => selectedRisk === 'all' || alert.risk === selectedRisk)
    .sort((a, b) => riskOrder[a.risk] - riskOrder[b.risk]);

  let filteredApiAlerts = [];
  if (scanType === 'security-api-scan') {
    filteredApiAlerts = alerts
      ?.filter((alert) => alert.riskcode !== '0')
      .filter((alert) => selectedRisk === 'all' || alert.riskcode === selectedRisk)
      .sort((a, b) => riskOrderForApiScan[a.riskcode] - riskOrderForApiScan[b.riskcode]);
  }

  const filteredAlertsPDF = alerts
    ?.filter((alert) => alert.risk !== 'Informational')
    .sort((a, b) => riskOrder[a.risk] - riskOrder[b.risk]);

  let filteredApiAlertsPDF = [];
  if (scanType === 'security-api-scan') {
    filteredApiAlertsPDF = alerts
      ?.filter((alert) => alert.riskcode !== '0')
      .sort((a, b) => riskOrderForApiScan[a.riskcode] - riskOrderForApiScan[b.riskcode]);
  }

  useEffect(() => {
    // console.log("selcted risk and scantype",selectedRisk,"    ",scanType,filteredApiAlerts[0].riskcode===selectedRisk)
    console.log('filtered for other scan :', filteredAlerts);
    console.log('Filtered for api scan :', filteredApiAlerts, alerts, data);
  });
  // Ensure unique keys are used for API alerts
  const generateAlertKey = (alert, index) => `${alert.id || alert.riskcode}_${index}`;

  const handleToggleExpand = (alertId) => {
    setExpandedAlertId(expandedAlertId === alertId ? null : alertId);
  };

  return (
    <main className="grid flex-1 items-start p-4 sm:py-0 h-full space-y-4 manrope-fontCss">
      <div className="flex flex-col space-y-3">
        {/* Form */}
        <Form
          onSubmit={() => {}}
          initialValues={{ testName: data?.test_name, targetURL: data?.url }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="flex flex-col space-y-3">
              <div className="flex flex-col space-y-3">
                <div>
                  <Label htmlFor="testName">Test Name</Label>
                  <div className="flex flex-row space-x-5 ">
                    <Field
                      name="testName"
                      render={({ input, meta }) => (
                        <div className="w-1/2">
                          <Input placeholder="Your Test Name" {...input} disabled />
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-3">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
                {scanType !== 'security-api-scan' && (
                  <div>
                    <Label htmlFor="targetURL">Target URL</Label>
                    <Field
                      name="targetURL"
                      render={({ input, meta }) => (
                        <div className="w-1/2">
                          <Input placeholder="Enter your input here..." {...input} disabled />
                          {meta.touched && meta.error && (
                            <div className="text-[#EF4444] text-sm my-3">{meta.error}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
            </form>
          )}
        />

        {/* Alerts */}
        <Card className="p-2 space-y-3 w-full">
          <div className="flex justify-between m-2">
            <Tabs defaultValue="all" value={selectedRisk} onValueChange={setSelectedRisk}>
              <TabsList>
                <TabsTrigger value="all">All Alerts</TabsTrigger>
                <TabsTrigger value={scanType !== 'security-api-scan' ? 'High' : '3'}>
                  High Risk
                </TabsTrigger>
                <TabsTrigger value={scanType !== 'security-api-scan' ? 'Medium' : '2'}>
                  Medium Risk
                </TabsTrigger>
                <TabsTrigger value={scanType !== 'security-api-scan' ? 'Low' : '1'}>
                  Low Risk
                </TabsTrigger>
              </TabsList>
            </Tabs>
            {/* Download PDF */}

            {
              // (scanType !== "security-api-scan" ? filteredAlerts?.length > 0 : filteredApiAlerts?.length > 0)
              //  &&
              <PDFDownloadLink
                document={
                  <SecurityResultPdf
                    data={
                      scanType !== 'security-api-scan' ? filteredAlertsPDF : filteredApiAlertsPDF
                    }
                    type={scanType}
                    fullData={data}
                  />
                }
                fileName="SecurityResult.pdf">
                <Button
                  variant="ghost"
                  className="h-9 w-[170px] text-xs bg-[#222938] hover:bg-[#222938] border-[#444444] border-2 hover:text-[#FFFFFF] flex items-center justify-between">
                  <DownloadIcon className="h-4 w-4" />
                  <span className="sr-only sm:not-sr-only text-sm font-light">Download Report</span>
                </Button>
              </PDFDownloadLink>
            }
          </div>
          <hr />
          {/* Render Alert Cards */}
          {scanType !== 'security-api-scan' ? (
            Array.isArray(filteredAlerts) && filteredAlerts.length > 0 ? (
              filteredAlerts.map((alert, index) => (
                <AlertCard
                  key={alert.id}
                  alert={alert}
                  expanded={expandedAlertId === alert.id}
                  onToggleExpand={() => handleToggleExpand(alert.id)}
                  user={user}
                  test_name={name}
                  test_id={id}
                  loader={setLoader}
                  type={data.type}
                />
              ))
            ) : (
              <div className="h-[50vh] w-full flex justify-center items-center font-bold text-lg">
                No data found in the {selectedRisk === 'all' ? '' : selectedRisk} Risk category for
                the test
              </div>
            )
          ) : Array.isArray(filteredApiAlerts) && filteredApiAlerts.length > 0 ? (
            filteredApiAlerts.map((alert, index) => (
              <AlertCard
                key={generateAlertKey(alert, index)}
                alert={alert}
                expanded={expandedAlertId === generateAlertKey(alert, index)}
                onToggleExpand={() => handleToggleExpand(generateAlertKey(alert, index))}
                user={user}
                test_name={name}
                test_id={id}
                loader={setLoader}
                type={scanType}
              />
            ))
          ) : (
            <div className="h-[50vh] w-full flex justify-center items-center font-bold text-lg">
              No data found in the {risk.find((r) => r.id === selectedRisk)?.value || ''} Risk
              category for the test
            </div>
          )}
        </Card>
      </div>
    </main>
  );
};

export default withLoader(withUser(SecurityResult));
