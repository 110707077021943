import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { isEmpty } from 'lodash';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '../ui/dialog';
import { Form, Field } from 'react-final-form';
import Rest from '../../assets/images/rest.png';
import DataBase from '../../assets/images/db.png';
import WebSocket from '../../assets/images/websocket.png';
import GraphQl from '../../assets/images/graphql.png';
import Soap from '../../assets/images/soap.png';
import WebSiteLoadTesting from '../../assets/images/websiteload.png';
import { RightArrowIcon } from '../../assets/icons';

const options = [
  {
    value: 'Rest API',
    description:
      'Discover open ports on your servers with a complete TCP port scan of ports 0 to 36645',
    icon: Rest,
    disabled: false,
    path: '/load/test'
  },
  {
    value: 'Database',
    description: 'Upload OpenAPI & Swagger templates for API vulnerability scanning',
    icon: DataBase,
    disabled: false,
    path: '/load/database'
  },
  {
    value: 'Web Sockets',
    description: 'Securely connect scanners on private networks',
    icon: WebSocket,
    disabled: false,
    path: '/load/websockets'
  },
  {
    value: 'GraphQL',
    description: 'Securely connect scanners on private networks',
    icon: GraphQl,
    disabled: false,
    path: '/load/graphql'
  },
  {
    value: 'Soap API',
    description: 'Securely connect scanners on private networks',
    icon: Soap,
    disabled: false,
    path: '/load/soap-api'
  },
  {
    value: 'Website Load Testing',
    description: 'Securely connect scanners on private networks',
    icon: WebSiteLoadTesting,
    disabled: false,
    path: '/load/website'
  },
  {
    value: 'Messaging Broker',
    description: 'Test Message Brokers like Kafka',
    icon: WebSiteLoadTesting,
    disabled: false,
    path: '/load/messaging-broker'
  },
];

const LoadButtonCard = ({ className, containerClassName }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (formData) => {
    console.log('form data', formData);
    const selectedOption = options.find((option) => option.value === formData.apiType);
    if (selectedOption) {
      navigate(selectedOption.path);
    } else {
      console.error('Invalid option selected');
    }
    setIsOpen(false);
  };

  return (
    <div className={`md:w-[250px] w-[220px] manrope-fontCss h-full mt-2 ${containerClassName}`}>
      <Card x-chunk="dashboard-05-chunk-2" className={`h-[85%] ${className}`}>
        <CardHeader className="pb-4">
          <CardTitle className="text-xl font-semibold">Load Test</CardTitle>
        </CardHeader>
        <CardContent className="text-center mt-3">
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
              <div>
                <Button className="w-full space-x-1 flex justify-between">
                  <p className="text-white font-bold text-base">Start</p>
                  <div className="bg-white h-7 w-7 flex items-center p-1 justify-center border-none rounded-full">
                    <RightArrowIcon />
                  </div>
                </Button>
              </div>
            </DialogTrigger>
            <DialogContent className="bg-[#222938] text-white">
              <DialogHeader>
                <DialogTitle className="text-lg font-medium">Start Load Testing</DialogTitle>
                <DialogDescription className="text-sm text-[#A9A9A9] pb-2">
                  Choose the type of load testing you want to proceed with from the options below
                </DialogDescription>
              </DialogHeader>
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="w-full">
                    <div>
                      {options.map((option) => (
                        <React.Fragment key={option.value}>
                          <Field name="apiType" type="radio" value={option.value}>
                            {({ input }) => (
                              <div className="flex items-center justify-between p-2 rounded-lg mb-2">
                                <div className="flex items-center">
                                  <div className="relative inline-flex items-center">
                                    <input
                                      {...input}
                                      type="radio"
                                      id={option.value}
                                      value={option.value}
                                      checked={input.checked}
                                      disabled={option.disabled}
                                      className={`appearance-none bg-[#161A27] border border-[#747474] rounded-full w-4 h-4 cursor-pointer relative outline-none
                checked:bg-[#9747FF] checked:border-[#9747FF]
                disabled:bg-[#333E57] disabled:cursor-not-allowed
                peer z-10`}
                                    />
                                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-6 h-6 rounded-full border-2 border-[#9747FF] opacity-0 peer-checked:opacity-100 pointer-events-none"></div>
                                  </div>
                                  <div className="flex items-center">
                                    <img
                                      className="w-8 h-8 mr-4 ml-2"
                                      src={option.icon}
                                      alt={option.value}
                                    />
                                    <div>
                                      <p className="text-sm font-medium">{option.value}</p>
                                      <p className="text-xs text-[#A9A9A9]">{option.description}</p>
                                    </div>
                                  </div>
                                </div>
                                {/* <Button
                                  className="bg-[#333E57] hover:bg-[#9747FF] text-white"
                                  size="sm">
                                  Learn More
                                </Button> */}
                              </div>
                            )}
                          </Field>
                          <hr className="h-px bg-[#6F6F6F] border-0" />
                        </React.Fragment>
                      ))}
                    </div>

                    <div className="mt-4 flex justify-between items-center">
                      <Field
                        name="apiType"
                        subscription={{ value: true }}
                        render={({ input }) => (
                          <span className="text-sm text-gray-400">
                            {isEmpty(input.value) ? 'No Value' : input.value} Selected
                          </span>
                        )}
                      />
                      <Button type="submit" className="text-white">
                        Next
                      </Button>
                    </div>
                  </form>
                )}
              />
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoadButtonCard;
