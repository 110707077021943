import React, { useState,useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Plus, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { LOAD_TEST_METHODS } from '../../constants';
import arrayMutators from 'final-form-arrays';
import { Label } from '../../components/ui/label';
import { renderField } from '../../helpers/form-validations';
import { Input } from '../../components/ui/input';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '../../components/ui/button';
import { loadTestWebsocket, getEnvironmentNames } from '../../utils/util';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';


const LoadWebSocket = ({ user, setLoader }) => {


  const { toast } = useToast();
  const navigate = useNavigate();
  const [envNames, setEnvNames] = useState([]);

  const onSubmit = async (formData) => {
  
    let params=formData?.paramValues && formData.paramValues.length >0 && formData.paramValues.filter(i => i.key)
    console.log("formdata params",formData?.paramValues,params)
    try {
      const payload = {
        user_id: user?.user_id,
        websocket_url: formData?.url,
        virtual_user: formData?.virtualUsers,
        enterprise_id: user?.enterprise_id,
        object_id: user?.object_id,
        environment: formData?.environment || "dev",
        test_name: formData?.testName,
        params:params.length==0? null:params,
        // headers: formData?.headerValues,
        headers: formData?.headerValues
        .filter(({ key, value }) => key && value) // Filter out empty key-value pairs
        .map(({ key, value }) => ({ key, value })), // Map valid headers to payload
        message: formData?.message,
        restart: false,
        subscription_id: user?.activated_subscription_id,
      };
      console.log({ payload });
      setLoader(true);
      const loadTestRes = await loadTestWebsocket(payload);
      console.log(loadTestRes.data);

      setLoader(false);
      handleApiResponse(loadTestRes.data);

    } catch (e) {
      console.log('Error', e);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: e.message
      });
    }
  };

  const handleApiResponse = (response) => {
    switch (response.status) {
      case 'success':
        toast({ description: response.message, variant: 'success' });
        navigate('/load')
        break;
      case 'error':
        switch (response.code) {
          case 404:
            toast({ title:"Warning",description: "You don't have any activated or valid subscription", variant: 'destructive' });
            break;
          case 400:
            toast({ description: response.message, variant: 'destructive' });
            break;
          case 500:
            toast({ description: response.message, variant: 'destructive' });
            break;
          default:
            toast({ description: 'An unknown error occurred.', variant: 'destructive' });
        }
        break;
      default:
        toast({ description: 'Unexpected response from the server.', variant: 'warning' });
    }
  };


  const handleKeyValueBlurOrChange = (fields, index, values) => {
    const currentPair = values.headerValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  const onChangeParamsValue = (fields, index, values) => {
    const currentPair = values.paramValues[index];
    if (currentPair && (currentPair.key || currentPair.value) && index === fields.length - 1) {
      fields.push({ key: '', value: '' });
    }
  };

  const handleEnvironmentChange = (newEnv) => {
    setFormState((prevState) => ({
      ...prevState,
      environment: newEnv,
    }));
  };

  const [formState, setFormState] = useState({
    httpMethod: LOAD_TEST_METHODS[0].method,
    headerValues: [{ key: '', value: '' }],
    paramValues: [{ key: '', value: '' }],
    environment: ''

  })

  useEffect(() => {
    fetchEnvNames();
  }, []);

  const fetchEnvNames = async () => {
    try {
      setLoader(true);
      const response = await getEnvironmentNames({
        user_id: user.user_id,
        enterprise_id: user.enterprise_id
      });
      setEnvNames(response.data.data.Environment_list.filter(i => i))
      setLoader(false);

    } catch (error) {
      setEnvNames([])
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch environment names'
      });
    }
  };

  const required = (value) => (value ? undefined : 'Required');

  const composeValidators =
    (...validators) =>
      (value) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

  return (
    <main className="grid flex-1 items-start gap-4 p-4  sm:py-0 md:gap-8 w-full ">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
        <Form
          onSubmit={onSubmit}
          initialValues={
            formState
          }
          mutators={{
            ...arrayMutators
          }}
          render={({ handleSubmit, values,form }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 w-full">
                  <div className="flex w-full pt-2 gap-2">
                    {envNames.length > 0 && (
                      <div className="flex w-full pt-2 gap-2">
                        <div className="flex-1 gap-4 font-semibold text-lg">
                          <label htmlFor="httpMethods" className="text-base">
                            Select Environment
                          </label>
                          <Field
                            name="environment"
                            component="select"
                            onChange={(event) => handleEnvironmentChange(event.target.value)}
                            className="ml-4 bg-gray-700 border border-gray-600 text-white p-1 rounded-md flex-1"
                            required
                          >
                            <option value="">Choose Environment</option>
                            {envNames.map((envObj) => (
                              <option key={envObj} value={envObj}>
                                {envObj}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                    )}

                    <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="url" className={`mb-4`}>
                        Websocket URL
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="url"
                        type="text"
                        name="url"
                        placeholder="Add URL here"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                    <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="message" className={`mb-4`}>
                        Message
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="message"
                        type="text"
                        name="message"
                        placeholder="Enter Message here"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                  </div>
                  <div className="flex w-full pt-2 gap-2">
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="testName" className={`mb-4`}>
                        Test Name
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="testName"
                        type="text"
                        name="testName"
                        placeholder="Write test name like “Load Test 1”, etc. "
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>

                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <Label htmlFor="virtualUsers" className={`mb-4`}>
                        Virtual Users
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="virtualUsers"
                        type="text"
                        name="virtualUsers"
                        placeholder="Enter number of Virtual Users here "
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div>
                  </div>





                  {/* Headers  */}

                  <div className="flex items-center space-x-4 pt-4">
                    <Label htmlFor="headerValues" className="text-lg font-bold">
                      Headers
                    </Label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>

                  <div className="">
                    <FieldArray name="headerValues">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-2 items-center space-x-4 pb-2">
                              <div className={`flex flex-col col-span-1`}>
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Key</label>
                                )}
                                <Field
                                  name={`${name}.key`}
                                  render={({ input }) => (
                                    <Input
                                      {...input}
                                      placeholder="Key"
                                      className="border p-2 rounded bg-[#161A27]"
                                      onBlur={() =>
                                        handleKeyValueBlurOrChange(fields, index, values)
                                      }
                                      onChange={(e) => {
                                        input.onChange(e);
                                        handleKeyValueBlurOrChange(fields, index, values);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="flex flex-col col-span-1">
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Value</label>
                                )}
                                <div className="flex items-center space-x-4">
                                  <Field
                                    name={`${name}.value`}
                                    render={({ input }) => (
                                      <Input
                                        {...input}
                                        placeholder="Value"
                                        className="border p-2 rounded bg-[#161A27]"
                                        onBlur={() =>
                                          handleKeyValueBlurOrChange(fields, index, values)
                                        }
                                        onChange={(e) => {
                                          input.onChange(e);
                                          handleKeyValueBlurOrChange(fields, index, values);
                                        }}
                                      />
                                    )}
                                  />
                                  {fields.length > 1 && (
                                    <Trash2
                                      type="button"
                                      onClick={() => fields.remove(index)}
                                      className="focus:outline-none cursor-pointer"
                                      size={18}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>

                  <div className="flex items-center space-x-4 pt-2">
                    <Label htmlFor="paramValues" className="text-lg font-bold">
                      Parameters
                    </Label>
                    <hr className="flex-grow border-t border-dashed border-[#1E4E9D]" />
                  </div>

                  <div className="">
                    <FieldArray name="paramValues">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-2 items-center space-x-4 pb-2">
                              <div className={`flex flex-col col-span-1`}>
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Key</label>
                                )}
                                <Field
                                  name={`${name}.key`}
                                  render={({ input }) => (
                                    <Input
                                      {...input}
                                      placeholder="Key"
                                      className="border p-2 rounded bg-[#161A27]"
                                      onBlur={() =>
                                        handleKeyValueBlurOrChange(fields, index, values)
                                      }
                                      onChange={(e) => {
                                        input.onChange(e);
                                        handleKeyValueBlurOrChange(fields, index, values);
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="flex flex-col col-span-1">
                                {index === 0 && (
                                  <label className="text-lg font-semibold mb-2">Value</label>
                                )}
                                <div className="flex items-center space-x-4">
                                  <Field
                                    name={`${name}.value`}
                                    render={({ input }) => (
                                      <Input
                                        {...input}
                                        placeholder="Value"
                                        className="border p-2 rounded bg-[#161A27]"
                                        onBlur={() => onChangeParamsValue(fields, index, values)}
                                        onChange={(e) => {
                                          input.onChange(e);
                                          onChangeParamsValue(fields, index, values);
                                        }}
                                      />
                                    )}
                                  />
                                  {fields.length > 1 && (
                                    <Trash2
                                      type="button"
                                      onClick={() => fields.remove(index)}
                                      className="focus:outline-none cursor-pointer"
                                      size={18}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </div>




                </div>
                <div className="flex flex-row mt-4 gap-4 w-full mb-4">
                  <div className="w-1/2 justify-start items-center">
                    <Button
                      type="submit"
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium gap-1 border-[#444444] border">
                      <Plus />
                      New Test
                    </Button>
                  </div>
                  <div className="flex w-1/2 justify-end gap-4 ">
                    <Button
                      type="button"
                      onClick={()=>{
                        setLoader(true)
                        form.restart()
                        setTimeout(() => {
                          setLoader(false)
                          navigate("/load")
                        }, 1000);
                      }}
                      className="bg-[#222938] text-white h-10 w-60 text-base font-medium border-[#444444] border"
                      >
                      Discard Changes
                    </Button>
                    <Button type="submit" className=" text-white h-10 w-60 text-base font-medium">
                      Validate
                    </Button>
                  </div>
                </div>
              </form>

            </>
          )}
        />
      </div>
    </main>
  )
};

export default withLoader(withUser(LoadWebSocket));
