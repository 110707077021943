// New Shorten Code

import React, { useEffect, useState } from 'react';
import Cards from '../../components/Dashboard/Cards';
import DashTable from '../../components/Admin_UserManagement/DashTable';
import { deleteIcon, download } from '../../assets/icons2';
import { generateToken } from '../../notifications/firebase';
import { getLoadTestResults, getSecurityTestResults } from '../../utils/util';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import { toast } from '../../components/ui/use-toast';

const Dashboard = ({ user, setLoader }) => {
  useEffect(() => {
    generateToken();
    console.log(generateToken());
  }, [location.hash]);

  const [totalPage,setTotalPage]=useState({
    security:1,
    load:1
  })
  const [data, setData] = useState({
    security: [],
    load: []
  });

  const [noOfTest, setNoOfTest] = useState(0);

  const tabsConfig = [
    { value: 'load', label: 'Load' },
    { value: 'security', label: 'Security' }
  ];

  const column1 = [
    { id: 'testDetails', header: 'Test Details' },
    {id:"type",header:"Type"},
    { id: 'status', header: 'Status' },
    { id: 'duration', header: 'Duration' },
    { id: 'startTime', header: 'Start Time' }
  ];
  const column2 = [
    { id: 'testDetails', header: 'Test Details' },
    {id:"type",header:"Type"},
    { id: 'status', header: 'Status' },
    
    { id: 'startTime', header: 'Start Time' }
  ];

  const buttonOptions = [
    {
      icon: download,
      alt: 'Send Icon',
      text: <span className="text-base font-semibold">Download Certificate</span>,
      iconHeight: 4,
      iconWidth: 18,
      iconColor: 'text-[#FFFFFF]',
      name: 'Download'
    },
    {
      icon: deleteIcon,
      alt: '',
      text: <span className="text-base font-semibold">Delete</span>,
      iconHeight: 17,
      iconWidth: 17,
      iconColor: 'text-[#FFFFFF]',
      name: 'Delete'
    }
  ];

  const accordionTab = [
    {
      title: 'Title 1',
      subtitles: ['Subtitle 1.1', 'Subtitle 1.2']
    },
    // Add more tabs as required
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      try {
        const payload = {
          user_id: user.user_id,
          enterprise_id: user.enterprise_id || null,
          page_number: 1,
          per_page: 10,
          object_id: user.object_id,
          subscription_id: user.activated_subscription_id
        };

        const securityResponse = await getSecurityTestResults(payload);
        const loadResponse = await getLoadTestResults(payload);

        const securityCount = securityResponse?.data?.count || 0;
        const loadCount = loadResponse?.data?.count || 0;

        setNoOfTest(securityCount + loadCount); // Set the total count

        setData({
          load: loadResponse?.data?.data || [],
          security: securityResponse?.data?.data || [],
        });
        setTotalPage(
          {
            security:securityResponse.data.total_pages,
            load:loadResponse.data.total_pages
          }
        )

        setLoader(false);
      } catch (err) {
        console.error('Error fetching test results:', err);
        setLoader(false);
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Failed to fetch test results'
        });
      }
    };

    fetchData();
  }, [user,setNoOfTest]);

  return (
    <main className="grid flex-1 p-4 items-start gap-2 py-4 sm:py-0 md:gap-8 w-full h-auto">
     
      <div className="grid auto-rows-max items-start gap-2 md:gap-3 lg:col-span-2 h-auto">
        <Cards noOfTest={noOfTest} />
        <DashTable
          tabsConfig={tabsConfig}
          // securityColumns={column2}
          // loadColumns={column1}
          data={data}
          columns={column2}
          buttonType={'Download'}
          buttonOptions={buttonOptions}
          accordionTab={accordionTab}
          totalPage1={totalPage}
          home={true}
        />
      </div>
    </main>
  );
};

export default withLoader(withUser(Dashboard));





// import React, { useEffect, useState } from 'react';
// import Cards from '../../components/Dashboard/Cards';
// import DashTable from '../../components/Admin_UserManagement/DashTable';
// import { deleteIcon, download } from '../../assets/icons2';
// import { generateToken } from '../../notifications/firebase';
// import { getLoadTestResults, getSecurityTestResults } from '../../utils/util';
// import withUser from '../../redux/Hoc/withUser';
// import withLoader from '../../redux/Hoc/withLoader';
// import { toast } from '../../components/ui/use-toast';

// const Dashboard = ({ user, setLoader }) => {
//   useEffect(() => {
//     generateToken();
//     console.log(generateToken());
//   }, [location.hash]);

//   const [data, setData] = useState({
//     security: [],
//     load: [
//       // {
//       //   testDetails: 'Load Test 1',
//       //   url: 'https://load-test1.com',
//       //   status: 'Started',
//       //   duration: '2h 0m 0s',
//       //   startTime: '04/20/2024 09:00:00 AM'
//       // },
//       // {
//       //   testDetails: 'Load Test 2',
//       //   url: 'https://load-test2.com',
//       //   status: 'Not Started',
//       //   duration: '3h 0m 0s',
//       //   startTime: '05/01/2024 11:00:00 AM'
//       // },
//       // {
//       //   testDetails: 'Load Test 3',
//       //   url: 'https://load-test3.com',
//       //   status: 'Success',
//       //   duration: '1h 30m 0s',
//       //   startTime: '05/15/2024 02:00:00 PM'
//       // },
//       // {
//       //   testDetails: 'Load Test 4',
//       //   url: 'https://load-test4.com',
//       //   status: 'Failed',
//       //   duration: '4h 0m 0s',
//       //   startTime: '06/01/2024 10:00:00 AM'
//       // },
//       // {
//       //   testDetails: 'Load Test 5',
//       //   url: 'https://load-test5.com',
//       //   status: 'Completed',
//       //   duration: '2h 0m 0s',
//       //   startTime: '06/10/2024 05:00:00 PM'
//       // },
//       // {
//       //   testDetails: 'Load Test 6',
//       //   url: 'https://load-test6.com',
//       //   status: 'Pending',
//       //   duration: '3h 0m 0s',
//       //   startTime: '07/01/2024 12:00:00 PM'
//       // },
//       // {
//       //   testDetails: 'Load Test 7',
//       //   url: 'https://load-test7.com',
//       //   status: 'Completed',
//       //   duration: '2h 30m 0s',
//       //   startTime: '07/15/2024 04:00:00 PM'
//       // },
//       // {
//       //   testDetails: 'Load Test 8',
//       //   url: 'https://load-test8.com',
//       //   status: 'In Progress',
//       //   duration: '3h 0m 0s',
//       //   startTime: '08/01/2024 09:00:00 AM'
//       // },
//       // {
//       //   testDetails: 'Load Test 9',
//       //   url: 'https://load-test9.com',
//       //   status: 'Failed',
//       //   duration: '1h 30m 0s',
//       //   startTime: '08/10/2024 03:00:00 PM'
//       // },
//       // {
//       //   testDetails: 'Load Test 10',
//       //   url: 'https://load-test10.com',
//       //   status: 'Completed',
//       //   duration: '2h 0m 0s',
//       //   startTime: '09/01/2024 11:00:00 AM'
//       // }
//     ]
//   });

//   const tabsConfig = [
//     { value: 'load', label: 'Load' },
//     { value: 'security', label: 'Security' }
//   ];

//   const columns = [
//     { id: 'testDetails', header: 'Test Details' },
//     { id: 'status', header: 'Status' },
//     { id: 'duration', header: 'Duration' },
//     { id: 'startTime', header: 'Start Time' }
//   ];

//   const [noOfTest,setNoOfTest]=useState(0)

//   // const data = {
//   //   security: [
//   //     {
//   //       testDetails: 'Security Team 1',
//   //       url: 'https://security-team1.com',
//   //       status: 'Started',
//   //       duration: '1h 30m 0s',
//   //       startTime: '04/19/2024 08:43:26 PM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 2',
//   //       url: 'https://security-team2.com',
//   //       status: 'Not Started',
//   //       duration: '2h 15m 0s',
//   //       startTime: '05/01/2024 10:00:00 AM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 3',
//   //       url: 'https://security-team3.com',
//   //       status: 'Success',
//   //       duration: '45m 0s',
//   //       startTime: '05/15/2024 01:30:45 PM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 4',
//   //       url: 'https://security-team4.com',
//   //       status: 'Failed',
//   //       duration: '3h 0m 0s',
//   //       startTime: '06/01/2024 09:00:00 AM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 5',
//   //       url: 'https://security-team5.com',
//   //       status: 'Completed',
//   //       duration: '1h 20m 0s',
//   //       startTime: '06/10/2024 04:45:00 PM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 6',
//   //       url: 'https://security-team6.com',
//   //       status: 'Pending',
//   //       duration: '2h 0m 0s',
//   //       startTime: '07/01/2024 11:00:00 AM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 7',
//   //       url: 'https://security-team7.com',
//   //       status: 'Completed',
//   //       duration: '1h 50m 0s',
//   //       startTime: '07/15/2024 03:30:00 PM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 8',
//   //       url: 'https://security-team8.com',
//   //       status: 'In Progress',
//   //       duration: '2h 30m 0s',
//   //       startTime: '08/01/2024 08:00:00 AM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 9',
//   //       url: 'https://security-team9.com',
//   //       status: 'Failed',
//   //       duration: '1h 0m 0s',
//   //       startTime: '08/10/2024 02:15:00 PM'
//   //     },
//   //     {
//   //       testDetails: 'Security Team 10',
//   //       url: 'https://security-team10.com',
//   //       status: 'Completed',
//   //       duration: '1h 40m 0s',
//   //       startTime: '09/01/2024 10:30:00 AM'
//   //     }
//   //   ],
//   //   load: [
//   //     {
//   //       testDetails: 'Load Test 1',
//   //       url: 'https://load-test1.com',
//   //       status: 'Started',
//   //       duration: '2h 0m 0s',
//   //       startTime: '04/20/2024 09:00:00 AM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 2',
//   //       url: 'https://load-test2.com',
//   //       status: 'Not Started',
//   //       duration: '3h 0m 0s',
//   //       startTime: '05/01/2024 11:00:00 AM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 3',
//   //       url: 'https://load-test3.com',
//   //       status: 'Success',
//   //       duration: '1h 30m 0s',
//   //       startTime: '05/15/2024 02:00:00 PM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 4',
//   //       url: 'https://load-test4.com',
//   //       status: 'Failed',
//   //       duration: '4h 0m 0s',
//   //       startTime: '06/01/2024 10:00:00 AM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 5',
//   //       url: 'https://load-test5.com',
//   //       status: 'Completed',
//   //       duration: '2h 0m 0s',
//   //       startTime: '06/10/2024 05:00:00 PM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 6',
//   //       url: 'https://load-test6.com',
//   //       status: 'Pending',
//   //       duration: '3h 0m 0s',
//   //       startTime: '07/01/2024 12:00:00 PM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 7',
//   //       url: 'https://load-test7.com',
//   //       status: 'Completed',
//   //       duration: '2h 30m 0s',
//   //       startTime: '07/15/2024 04:00:00 PM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 8',
//   //       url: 'https://load-test8.com',
//   //       status: 'In Progress',
//   //       duration: '3h 0m 0s',
//   //       startTime: '08/01/2024 09:00:00 AM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 9',
//   //       url: 'https://load-test9.com',
//   //       status: 'Failed',
//   //       duration: '1h 30m 0s',
//   //       startTime: '08/10/2024 03:00:00 PM'
//   //     },
//   //     {
//   //       testDetails: 'Load Test 10',
//   //       url: 'https://load-test10.com',
//   //       status: 'Completed',
//   //       duration: '2h 0m 0s',
//   //       startTime: '09/01/2024 11:00:00 AM'
//   //     }
//   //   ]
//   // };
//   const buttonOptions = [
//     {
//       icon: download,
//       alt: 'Send Icon',
//       text: <span className="text-base font-semibold">Download Certificate</span>,
//       iconHeight: 4,
//       iconWidth: 18,
//       iconColor: 'text-[#FFFFFF]',
//       name: 'Download'
//     },
//     {
//       icon: deleteIcon,
//       alt: '',
//       text: <span className="text-base font-semibold">Delete</span>,
//       iconHeight: 17,
//       iconWidth: 17,
//       iconColor: 'text-[#FFFFFF]',
//       name: 'Delete'
//     }
//   ];

//   const accordionTab = [
//     {
//       title: 'Title 1',
//       subtitles: ['Subtitle 1.1', 'Subtitle 1.2']
//     },
//     {
//       title: 'Title 2',
//       subtitles: ['Subtitle 2.1', 'Subtitle 2.2']
//     },
//     {
//       title: 'Title 3',
//       subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
//     },
//     {
//       title: 'Title 4',
//       subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
//     },
//     {
//       title: 'Title 5',
//       subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
//     },
//     {
//       title: 'Title 6',
//       subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
//     },
//     {
//       title: 'Title 7',
//       subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
//     }
//   ];

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoader(true);
  
//       try {
//         const payload = {
//           user_id: user.user_id,
//           enterprise_id: user.enterprise_id ? user.enterprise_id : null,
//           page_number: 1,
//           per_page: 10,
//           object_id: user.object_id,
//           subscription_id: user.activated_subscription_id
//         };
  
//         const response = await getSecurityTestResults(payload);
//         console.log('Security test response:', response);
  
//         console.log("security count",response?.data.count)

//         setNoOfTest(prevState=>(prevState+response.data.count))
//         if (response.data && response.data.data) {
//           setData(prevState => ({
//             ...prevState,
//             security: response.data.data
//           }));
//         } else {
//           console.error('No security data returned from API');
//         }
  
//         setLoader(false);
//       } catch (err) {
//         console.error('Error fetching security test results:', err);
//         setLoader(false);
//       }
//     };
  
//     fetchData();
//   }, [user]);
  

//   useEffect(()=>{
//     const fetchLoadTestResults = async (isLoader) => {
//       try {
//         if (isLoader) { setLoader(true) };
//         const response = await getLoadTestResults({
//           user_id: user.user_id,
//           enterprise_id: user.enterprise_id,
//           object_id: user.object_id,
//           Page_number: 1,
//           Per_page: 20,
//         });
//         console.log('response', response);
//         setLoader(false);
        
//         // if (response.data?.data.length == 0) {
//         //   setLoadData({
//         //     load: []
//         //   });
//         // }

//         console.log("load count :",response?.data.count)
  
//         setNoOfTest(prevState=>(prevState+response.data.count))

//         if (response.data && response.data.data) {
//           setData(prevState => ({
//             ...prevState,
//             load: response.data.data
//           }));
//         }

//         console.log("Load data ",data.load)
//         console.log("security data",data.security)

//       } catch (error) {
//         setLoader(false);
//         toast({
//           variant: 'destructive',
//           title: 'Error',
//           description: 'Failed to fetch load test results'
//         });
//       }
    
//     };

//     fetchLoadTestResults()

//   },[user])

//   return (
//     <main className="grid flex-1 p-4 items-start gap-2 py-4 sm:py-0 md:gap-8 w-full h-auto">
//       <div>{noOfTest}</div>
//       <div className="grid auto-rows-max items-start gap-2 md:gap-3 lg:col-span-2 h-auto">
//         <Cards noOfTest={noOfTest} />
//         <DashTable
//           tabsConfig={tabsConfig}
//           columns={columns}
//           data={data}
//           buttonType={'Download'}
//           buttonOptions={buttonOptions}
//           accordionTab={accordionTab}
//         />
//       </div>
//     </main>
//   );
// };

// export default withLoader(withUser(Dashboard));
