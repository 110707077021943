// import Cards from '../../components/Dashboard/Cards';

import React, { useEffect, useState } from 'react';
// import ArcTable from '../../components/Architecture/ArcTable';
// import DashTable from '../../components/Admin_UserManagement/DashTable';
import DashTable from '../../components/Architecture/ArchitectureDashTable';

import { deleteIcon, download } from '../../assets/icons2/index';
import ButtonCard from '../../components/Cards/button-card';
import NormalCard from '../../components/Cards/normal-card';
import { deleteArchitectureTest, getarchitectureSuggestions } from '../../utils/util';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';

const Test = ({ user, setLoader }) => {
  const tabsConfig = [{ value: 'architecture', label: 'Architecture' }];

  const columns = [
    { id: 'testDetails', header: 'Tests' },
    { id: 'status', header: 'Status' },
    { id: 'cloudType', header: 'Cloud Type' },
    { id: 'startTime', header: 'Start Time' }
  ];
  const [totalPage,setTotalPage]=useState(1)
  const [data, setData] = useState({ architecture: [] }); // Initialize with null

  // const data = {
  //   architecture: [
  //     {
  //       testDetails: 'Test 1',
  //       url: 'https://security-test1.com',
  //       status: 'Started',
  //       cloudType: 'GCP',
  //       duration: '1h 30m 0s',
  //       numberConcurrentUsers: 50,
  //       startTime: '04/19/2024 08:43:26 PM',
  //       architecture_id: '12345'
  //     },
  //     {
  //       testDetails: 'Test 2',
  //       url: 'https://security-test2.com',
  //       status: 'Not Started',
  //       cloudType: 'AWS',
  //       duration: '2h 15m 0s',
  //       numberConcurrentUsers: 75,
  //       startTime: '05/01/2024 10:00:00 AM',
  //       architecture_id: '98765'
  //     },
  //     {
  //       testDetails: 'Test 3',
  //       url: 'https://security-test3.com',
  //       status: 'Success',
  //       cloudType: 'Azure',
  //       duration: '45m 0s',
  //       numberConcurrentUsers: 30,
  //       startTime: '05/15/2024 01:30:45 PM',
  //       architecture_id: '3438684'
  //     },
  //     {
  //       testDetails: 'Test 4',
  //       url: 'https://security-test4.com',
  //       status: 'Failed',
  //       cloudType: 'GCP',
  //       duration: '3h 0m 0s',
  //       numberConcurrentUsers: 60,
  //       startTime: '06/01/2024 09:00:00 AM',
  //       architecture_id: '36852'
  //     },
  //     {
  //       testDetails: 'Test 5',
  //       url: 'https://security-test5.com',
  //       status: 'Completed',
  //       cloudType: 'AWS',
  //       duration: '1h 20m 0s',
  //       numberConcurrentUsers: 90,
  //       startTime: '06/10/2024 04:45:00 PM',
  //       architecture_id: '8340247'
  //     },
  //     {
  //       testDetails: 'Test 6',
  //       url: 'https://security-test6.com',
  //       status: 'Pending',
  //       cloudType: 'Azure',
  //       duration: '2h 0m 0s',
  //       numberConcurrentUsers: 40,
  //       startTime: '07/01/2024 11:00:00 AM',
  //       architecture_id: '8382adsf90'
  //     },
  //     {
  //       testDetails: 'Test 7',
  //       url: 'https://security-test7.com',
  //       status: 'Completed',
  //       cloudType: 'GCP',
  //       duration: '1h 50m 0s',
  //       numberConcurrentUsers: 70,
  //       startTime: '07/15/2024 03:30:00 PM',
  //       architecture_id: '1234erea5'
  //     },
  //     {
  //       testDetails: 'Test 8',
  //       url: 'https://security-test8.com',
  //       status: 'In Progress',
  //       cloudType: 'AWS',
  //       duration: '2h 30m 0s',
  //       numberConcurrentUsers: 80,
  //       startTime: '08/01/2024 08:00:00 AM',
  //       architecture_id: '123ewa45'
  //     },
  //     {
  //       testDetails: 'Test 9',
  //       url: 'https://security-test9.com',
  //       status: 'Failed',
  //       cloudType: 'Azure',
  //       duration: '1h 0m 0s',
  //       numberConcurrentUsers: 20,
  //       startTime: '08/10/2024 02:15:00 PM',
  //       architecture_id: '25fdg21432'
  //     },
  //     {
  //       testDetails: 'Test 10',
  //       url: 'https://security-test10.com',
  //       status: 'Completed',
  //       cloudType: 'GCP',
  //       duration: '1h 40m 0s',
  //       numberConcurrentUsers: 55,
  //       startTime: '09/01/2024 10:30:00 AM',
  //       architecture_id: '46gfx73278'
  //     }
  //   ]
  // };

  const fetchData = async () => {
    setLoader(true);

    try {
      const payload = {
        enterprise_id: user.enterprise_id ? user.enterprise_id : null,
        user_id: user.user_id,
        object_id: user.object_id,
        page_no: 1,
        per_page_limit: 10
      };
      const response = await getarchitectureSuggestions(payload);
      console.log('List Architecture-suggestions: ', response);
      setLoader(false);
      if(response){
        setTotalPage(response?.data.total_pages)
        setData({ architecture: response.data.data[0]?.response });
       
      } 
   
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    setLoader(true)
    console.log("id delete",id)
    const data = {
      user_id: user.user_id,
      enterprise_id: user.enterprise_id ? user.enterprise_id : null,
      architecture_id: id,
      object_id: user.object_id
    };
  
      try {
        console.log("payload:",id)
        console.log("payload:",data)
        const response = await deleteArchitectureTest(data);
        console.log(response);
        fetchData()
        setInterval(() => {
           setLoader(false)
        }, 1000);
       
      } catch (error) {
        console.log(error);
      }
    } 


  const buttonOptions = [
    // {
    //   icon: download,
    //   alt: 'Send Icon',
    //   text: <span className="text-base font-semibold">Download Certificate</span>,
    //   iconHeight: 4,
    //   iconWidth: 18,
    //   iconColor: 'text-[#FFFFFF]',
    //   name: 'Download'
    // },
    {
      icon: deleteIcon,
      alt: '',
      text: <span className="text-base font-semibold">Delete</span>,
      iconHeight: 17,
      iconWidth: 17,
      iconColor: 'text-[#FFFFFF]',
      name: 'Delete',
      actionDeleteArchitecture:handleDelete
    }
  ];

  const accordionTab = [
    {
      title: 'Title 1',
      subtitles: ['Subtitle 1.1', 'Subtitle 1.2']
    },
    {
      title: 'Title 2',
      subtitles: ['Subtitle 2.1', 'Subtitle 2.2']
    },
    {
      title: 'Title 3',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    },
    {
      title: 'Title 4',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    },
    {
      title: 'Title 5',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    },
    {
      title: 'Title 6',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    },
    {
      title: 'Title 7',
      subtitles: ['Subtitle 3.1', 'Subtitle 3.2']
    }
  ];
  const url = '/architecture/form';
  const buttonName = 'Start';
  const buttonTitle = 'Architecture Test';
  const cardData = [
    { title: 'No of Architecture', numberOfTest:  data.architecture.length }
    // Add more card data as needed
  ];

  useEffect(() => {
    
    fetchData();
  }, [user]);

  useEffect(() => {
    fetchData()
    const interval = setInterval(() => {
        fetchData();
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, []);

  console.log('Data:', data);

  return (
    <main className="grid flex-1 items-start gap-1 p-4 sm:py-0 md:gap-2 w-full h-auto">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
        <div className={`flex h-full w-full gap-4`}>
          {cardData.map((card, index) => (
            <NormalCard
              key={index}
              title={card.title}
              numberOfTest={card.numberOfTest}
              denominator={card.denominator}
            />
          ))}
          <ButtonCard title={buttonTitle} buttonName={buttonName} url={url} />
        </div>
        <DashTable
          url={url}
          page={`architecture`}
          tabsConfig={tabsConfig}
          columns={columns}
          data={data ? (data ? data : {}) : { architecture: [] }}
          buttonType={'Download1'}
          buttonOptions={buttonOptions}
          accordionTab={accordionTab}
          totalPage={totalPage}
        />
      </div>
    </main>
  );
};

export default withLoader(withUser(Test));