import React from 'react';
import { Field, Form } from 'react-final-form';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '../../components/ui/card';
// import { Zap } from 'lucide-react';
import { Button } from '../../components/ui/button';
import { CircleArrowRight } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '../../components/ui/dialog';
// import { RadioGroup, RadioGroupItem } from '../../components/ui/radio-group';
import Rest from '../../assets/images/rest.png';
import DataBase from '../../assets/images/db.png';
import WebSocket from '../../assets/images/websocket.png';
import GraphQl from '../../assets/images/graphql.png';
import Soap from '../../assets/images/soap.png';
import WebSiteLoadTesting from '../../assets/images/websiteload.png';
// import NormalCard from '../Cards/normal-card';
// import ButtonCard from '../Cards/button-card';
import NormalCardDash from '../Cards/normal-card-dash';

const options = [
  {
    value: 'Rest API',
    description:
      'Discover open ports on your servers with a complete TCP port scan of ports 0 to 36645',
    icon: Rest,
    disabled: false
  },
  {
    value: 'Database',
    description: 'Upload OpenAPI & Swagger templates for API vulnerability scanning',
    icon: DataBase,
    disabled: true
  },
  {
    value: 'Web Sockets',
    description: 'Securely connect scanners on private networks',
    icon: WebSocket,
    disabled: true
  },
  {
    value: 'GraphQL',
    description: 'Securely connect scanners on private networks',
    icon: GraphQl,
    disabled: true
  },
  {
    value: 'Soap API',
    description: 'Securely connect scanners on private networks',
    icon: Soap,
    disabled: true
  },
  {
    value: 'Website Load Testing',
    description: 'Securely connect scanners on private networks',
    icon: WebSiteLoadTesting,
    disabled: true
  }
];



const Cards = ({ isStartTest = false ,noOfTest}) => {

  const cardsObject = [
    {
      title: 'Test',
      numberOfTest:noOfTest,
      // metrics: '+25% from last week'
    },
    // { title: 'Virtual users', numberOfTest: 32, denominator: 50 },
    // { title: 'Minutes used', numberOfTest: 32, denominator: 50 },
    // { title: 'Start Test', buttonName: 'Quick Start', url: '/load' }
  ];

  const navigate = useNavigate();
  const onSubmit = (formData) => {
    console.log('form data', formData);
    navigate('/load/test');
  };
  // const url = '/load/test';
  // const buttonName = 'Quick start';
  // const buttonTitle = 'Start a test';
  return (
    <>
      <div className="flex h-full gap-2 justify-start items-center manrope-fontCss pb-4 pt-2">
        {cardsObject.map((item, index) => (
          <NormalCardDash
            key={index}
            title={item?.title}
            numberOfTest={item?.numberOfTest}
            denominator={item?.denominator}
            metrics={item?.metrics}
            url={item?.url}
            buttonName={item?.buttonName}
          />
        ))}

        {isStartTest && (
          <Card x-chunk="dashboard-05-chunk-2">
            <CardHeader className="pb-4 flex">
              <CardDescription></CardDescription>
              <CardTitle className="text-3xl">Start Testing</CardTitle>
            </CardHeader>
            <CardContent className="text-center">
              <Dialog>
                <DialogTrigger>
                  <Button className="flex items-center space-x-4 text-white">
                    <span>Quick Start</span>
                    <CircleArrowRight className="ml-4" color="#9747FF" fill={'white'} />
                  </Button>
                </DialogTrigger>
                <DialogContent className="w-full">
                  <DialogHeader>
                    <DialogTitle className="text-lg font-medium">Start Load Testing</DialogTitle>
                    <DialogDescription className="text-sm text-[#A9A9A9] pb-2">
                      Choose the type of load testing you want to go ahead first among the options
                      mentioned below
                    </DialogDescription>
                  </DialogHeader>
                  <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} className="w-full">
                        <div>
                          {options.map((option, index) => (
                            <>
                              <Field
                                name="apiType"
                                type="radio"
                                value={option.value}
                                key={option.value}>
                                {({ input }) => (
                                  <div
                                    key={index}
                                    className="flex items-center justify-between p-2 rounded-lg mb-2">
                                    <div className="flex items-center">
                                      <div className="relative inline-flex items-center">
                                        <input
                                          {...input}
                                          type="radio"
                                          id={option.value}
                                          value={option.value}
                                          checked={input.checked}
                                          disabled={option.disabled}
                                          className={`appearance-none bg-[#161A27] border border-[#747474] rounded-full w-4 h-4 cursor-pointer relative outline-none
                checked:bg-[#9747FF] checked:border-[#9747FF]
                disabled:bg-[#333E57] disabled:cursor-not-allowed
                peer z-10`}
                                        />
                                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-6 h-6 rounded-full border-2 border-[#9747FF] opacity-0 peer-checked:opacity-100 pointer-events-none"></div>
                                      </div>
                                      <div className="flex items-center">
                                        <img
                                          className="w-8 h-8 mr-4 ml-2"
                                          src={option.icon}
                                          alt={option.value}
                                        />
                                        <div>
                                          <p className="text-sm font-medium">{option.value}</p>
                                          <p className="text-xs text-[#A9A9A9]">
                                            {option.description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <Button
                                      className="bg-[#333E57] hover:bg-[#9747FF] text-white"
                                      size="sm">
                                      Learn More
                                    </Button>
                                  </div>
                                )}
                              </Field>
                              <hr className="h-px bg-[#6F6F6F] border-0" />
                            </>
                          ))}
                        </div>

                        <div className="mt-4 flex justify-between items-center">
                          <Field
                            name="apiType"
                            subscription={{ value: true }}
                            render={({ input }) => (
                              <span className="text-sm text-gray-400">
                                {isEmpty(input.value) ? 'No Value' : input.value} Selected
                              </span>
                            )}
                          />
                          <Button type="submit" className="text-white">
                            Next
                          </Button>
                        </div>
                      </form>
                    )}
                  />
                </DialogContent>
              </Dialog>
            </CardContent>
            <CardFooter></CardFooter>
          </Card>
        )}
      </div>
    </>
  );
};

export default Cards;
