import { parseJSON } from "./helper";

export class JSONBuilder {
  constructor(formData) {
    this.formData = formData;
  }

  createPayload() {
    const payload = {
      version: "1.0",
      name: this.formData.name,
      env: {
        host: this.formData.url,
        method: this.formData.httpMethod
      },
      config: {
        continueOnFail: "true",
        concurrency: 1
      },
      tests: {}
    };
    this.formData.tests.forEach((test, index) => {
      const testName = `Test${index + 1}`
      const parsedResponse = parseJSON(test.responseBody)
      let headers = {}
      if (this.formData.headerValues && Array.isArray(this.formData.headerValues)){
        headers = this.formData.headerValues.reduce((acc, item) => {
          if (item.key) acc[item.key] = item.value;
          return acc;
        }, {});
      }
      if (this.formData.authType === "bearerToken") {
        headers["Authorization"] = `Bearer ${this.formData.bearerToken}`
      }
      if (this.formData.authType === "basicAuth") {
        headers["Authorization"] = `Basic ` + btoa(`${this.formData.username}:${this.formData.password}`)
      }
      payload.tests[testName] = {
        steps: [
          {
            name: test.name,
            http: {
              url: test.showParamsField ? this.formData.url + test.paramsString : this.formData.url,
              method: this.formData.httpMethod,
              ...(this.formData.httpMethod !== "get" && {
                json: this.getRequestBody(test.requestBody)
              }),
              check: {
                // status: "/^20/",
                ...({
                  jsonpath: this.getJsonPathsWithValues(parsedResponse)
                })
              }
            }
          }
        ]
      }
      if (Object.keys(headers).length > 0) {
        payload.tests[testName].steps[0].http["headers"] = headers
      }
    })
    return payload;
  }

  getRequestBody(requestBody) {
    if (!requestBody) {
      return {};
    }
    try {
      return parseJSON(requestBody)
    } catch (error) {
      console.error("Error: ", error)
    }
  }

  getJsonPathsWithValues(obj, currentPath = "$") {
    let pathsWithValues = {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newPath = `${currentPath}.${key}`;
  
        if (typeof obj[key] === "object" && obj[key] !== null) {
          // Recursively traverse if the property is an object
          Object.assign(pathsWithValues, this.getJsonPathsWithValues(obj[key], newPath));
        } else {
          // Store the path as key and the value as value
          pathsWithValues[newPath] = obj[key];
        }
      }
    }
  
    return pathsWithValues;
  }

  createJsonPath(responseBody) {
    if (!responseBody) {
      return {};
    }
    try {
      const parsedResponse = parseJSON(responseBody);
      const jsonpath = {};
      for (const key in parsedResponse) {
        jsonpath[`$.${key}`] = parsedResponse[key]
      }
      return jsonpath;
    } catch (error) {
      console.error(error);
      return {};
    }

    // const parsedBody = this.getRequestBody();

    // const jsonpath = {};
    // for (const key in parsedBody) {
    //     jsonpath[`$.${key}`] = parsedBody[key]
    // }
    // return jsonpath;
  }

}
