import axios from 'axios';
import { store } from '../redux/store';
import { isEmpty } from 'lodash';
import { userSet } from '../redux/actions'; // Assuming you have an action to update user state

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  // baseURL:"https://8pfhaw-ip-59-97-34-151.tunnelmole.net/v1",
  headers: {
    'Content-Type': 'application/json'
  }
});

export const middlewareApiClient = axios.create({
  baseURL: process.env.REACT_APP_MIDDLEWARE_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function to refresh token
export const refreshToken = async () => {
  const { user } = store.getState();
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/user/refresh-token`,
      { refresh_token: user.refresh_token },
      { headers: { Authorization: `Bearer ${user.access_token}` } }
    );
    const newAccessToken = response.data.access_token;
    console.log('Access Token: ', newAccessToken);
    store.dispatch(userSet({ ...user, access_token: newAccessToken }));
    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

// Request interceptor
const requestInterceptor = async (config) => {
  const { user } = store.getState();
  if (!isEmpty(user) && !isEmpty(user.access_token)) {
    config.headers['Authorization'] = `Bearer ${user.access_token}`;
  }
  return config;
};

// Response interceptor
const responseInterceptor = async (error) => {
  const originalRequest = error.config;
  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const newAccessToken = await refreshToken();
      originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      return axios(originalRequest);
    } catch (refreshError) {
      console.log('Failed to refresh token. Logging out...');
      localStorage.clear();
      window.location.reload();
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
};

// Apply interceptors to apiClient
apiClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
apiClient.interceptors.response.use((response) => response, responseInterceptor);

// Apply interceptors to middlewareApiClient
middlewareApiClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
middlewareApiClient.interceptors.response.use((response) => response, responseInterceptor);

export default apiClient;
